import React from 'react'
import '../../Styles/Index.css'
import '../../Styles/Blog.css'

function Type4(props) {
    const block = props.block
    return (
        <>
        { block.highlighted === "yes" ?
        <div className='type4-highlighted'>
            <img className='type4-image' src={block.photoUrl} alt=""/>
            <p className='alg-800 font-para-700 type4-title'>{block.title}</p>
        </div>
        :
        <div className='type4'>
            <img className='type4-image' src={block.photoUrl} alt=""/>
            <p className='alg-800 font-para-700 type4-title'>{block.title}</p>
        </div>
        }
        </>
    )
}

Type4.defaultProps = {
    title:"",
}
export default Type4
