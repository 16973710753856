import React from 'react'
import {useState, useEffect} from 'react';
import MuiColumn from '../Tools/MuiColumn';
//import {useState} from 'react';
import '../../Styles/Index.css'
import '../../Styles/UploadBlogStyles.css'
import { TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

function Type1Block(props) {

    const [wordCount, setWordCount] = useState(() => props.text ? props.text.split(" ").length : 0);
    const [highlighted, setHighlighted] = React.useState(() => props.highlighted ? props.highlighted : "no");
    const handleHighlightChange = (event) => {
        setHighlighted(event.target.value);
        props.onHighlightChange(event.target.value);
    };
    return (
        <div className='Type1-upload-container'>
            <div className="Type1-upload-top-container">
                <input className="titleInput" placeholder="Title (optional)" defaultValue={props.title} onChange={(event) => props.onTitleChange(event.target.value)}/>
                <MuiColumn onColChange={(event) => props.onColChange(event)} col={props.col}/>
            </div>

            <div className="Type1-upload-body-container">
                <div className="textContainer1 m-0">
                    <TextField
                        id="standard-textarea"
                        label=""
                        placeholder="type here"
                        fullWidth
                        minRows={13}
                        InputProps={{ disableUnderline: true, style:{marginLeft:'0px',padding:"10px", border:"solid", borderWidth:'1px', borderColor:'#eee', borderRadius:'10px'} }}
                        multiline
                        defaultValue={props.text}
                        onChange={(event) => { setWordCount(event.target.value.split(" ").length); props.onChange(event.target.value)}}
                        variant="standard"
                    />
                </div>
                <p className='Type1-upload-word-counter mav-800'>Word Count: {wordCount} / Recommended max 400 words</p>
                <div className="m-t-5">
                            <p className='Type1-upload-word-counter mav-800'>Highlighed?</p>
                            <Radio
                                checked={highlighted === "no"}
                                onChange={handleHighlightChange}
                                value="no"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'No' }}
                                sx={{
                                    paddingTop:'0px',
                                    paddingBottom: '3px'
                                }}
                            />
                            <label className='font-weight-700 uppercase m-0 p-0'>No</label>
                            <Radio
                                checked={highlighted === "yes"}
                                onChange={handleHighlightChange}
                                value="yes"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Yes' }}
                                sx={{
                                    color: '#990000',
                                    paddingTop:'0px',
                                    paddingBottom: '3px',
                                    marginLeft:'10px',
                                    '&.Mui-checked': {
                                    color: '#990000',
                                    },
                                }}
                            />
                            <label className='font-weight-700 uppercase m-l-5 p-0'>Yes</label>
                </div>
            </div>

            <div className="Type1-upload-tools-container">
                <button  onClick={() => props.onClick(props.myIndex)} type="submit" className='Type1-upload-tools-button delete'><DeleteOutlineIcon style={{fontWeight:"800",fontSize:"1.6rem"}}/></button>
                <button onClick={() => props.onIncrement(props.myIndex)} className='Type1-upload-tools-button'><AddIcon style={{fontWeight:"800",fontSize:"2.0rem"}}/></button>
            </div>
        </div>
    )
}
Type1Block.defaultProps = {
    myIndex:0,
    text: "",
    col:"col1",
    title:"",
    onClick: console.log('upload blocks onclick failed'),
    //onClick2: console.log('upload blocks onclick failed'),
    onChange: console.log('upload blocks onChangeText failed'),
    onColChange: console.log('upload blocks onColChange failed'),
    onTitleChange: console.log('upload blocks onTitleChange failed'),
    onIncrement: console.log('onIncrement failed'),
    onHighlightChange: console.log('onHighlight Failed')
}
export default Type1Block

