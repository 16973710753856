const AllCountries = {
    "Afghanistan":"af",
    "Albania":"al",
    "Algeria":"dz",
    "Andorra":"ad",
    "Angola":"ao",
    "Antigua and Barbuda":"ag",
    "Argentina":"ar",
    "Armenia":"am",
    "Aruba":"aw",
    "Australia":"au",
    "Austria":"at",
    "Azerbaijan":"az",
    "Bahamas":"bs",
    "Bahrain":"bh",
    "Bangladesh":"bd",
    "Barbados":"bb",
    "Belarus":"by",
    "Belgium":"be",
    "Belize":"bz",
    "Benin":"bj",
    "Bhutan":"bt",
    "Bolivia":"bo",
    "Bosnia and Herzegovina":"ba",
    "Botswana":"bw",
    "Brazil":"br",
    "Brunei":"bn",
    "Bulgaria":"bg",
    "Burkina Faso":"bf",
    "Burundi":"bi",
    "Cambodia":"kh",
    "Cameroon":"cm",
    "Canada":"ca",
    "Cape Verde":"cv",
    "Central African Republic":"cf",
    "Chad":"td",
    "Chile":"cl",
    "China":"cn",
    "Colombia":"co",
    "Comoros":"km",
    "Democratic Republic of Congo":"cd",
    "Republic of the Congo":"cg",
    "Costa Rica":"cr",
    "Côte d'Ivoire":"ci",
    "Croatia":"hr",
    "Cuba":"cu",
    "Cyprus":"cy",
    "Czech Republic":"cz",
    "Denmark":"dk",
    "Djibouti":"dj",
    "Dominica":"dm",
    "Dominican Republic":"do",
    "East Timor":"tl",
    "Ecuador":"ec",
    "Egypt":"eg",
    "El Salvador":"sv",
    "Equatorial Guinea":"gq",
    "Eritrea":"er",
    "Estonia":"ee",
    "Ethiopia":"et",
    "Fiji":"fj",
    "Finland":"fi",
    "France":"fr",
    "Gabon":"ga",
    "Gambia":"gm",
    "Georgia":"ge",
    "Germany":"de",
    "Ghana":"gh",
    "Greece":"gr",
    "Grenada":"gd",
    "Guatemala":"gt",
    "Guinea":"gn",
    "Guinea-Bissau":"gw",
    "Guyana":"gy",
    "Haiti":"ht",
    "Honduras":"hn",
    "Hungary":"hu",
    "Iceland":"is",
    "India":"in",
    "Indonesia":"id",
    "Iran":"ir",
    "Iraq":"iq",
    "Ireland":"ie",
    "Israel":"il",
    "Italy":"it",
    "Jamaica":"jm",
    "Japan":"jp",
    "Jordan":"jo",
    "Kazakhstan":"kz",
    "Kenya":"ke",
    "Kiribati":"ki",
    "Kuwait":"kw",
    "Kyrgyzstan":"kg",
    "Laos":"la",
    "Latvia":"lv",
    "Lebanon":"lb",
    "Lesotho":"ls",
    "Liberia":"lr",
    "Libya":"ly",
    "Liechtenstein":"li",
    "Lithuania":"lt",
    "Luxembourg":"lu",
    "Macedonia":"mk",
    "Madagascar":"mg",
    "Malawi":"mw",
    "Malaysia":"my",
    "Maldives":"mv",
    "Mali":"ml",
    "Malta":"mt",
    "Marshall Islands":"mh",
    "Mauritania":"mr",
    "Mauritius":"mu",
    "Mexico":"mx",
    "Micronesia":"fm",
    "Moldova":"md",
    "Monaco":"mc",
    "Mongolia":"mn",
    "Montenegro":"me",
    "Morocco":"ma",
    "Mozambique":"mz",
    "Myanmar":"mb",
    "Namibia":"na",
    "Nauru":"nr",
    "Nepal":"np",
    "Netherlands":"nl",
    "New Zealand":"nz",
    "Nicaragua":"ni",
    "Niger":"ne",
    "Nigeria":"ng",
    "Niue":"nu",
    "North Korea":"kp",
    "Norway":"no",
    "Oman":"om",
    "Other":"other",
    "Pakistan":"pk",
    "Palau":"pw",
    "Palestine":"pn",
    "Panama":"pa",
    "Papua New Guinea":"pg",
    "Paraguay":"py",
    "Peru":"pe",
    "Philippines":"ph",
    "Poland":"pl",
    "Portugal":"pt",
    "Qatar":"qa",
    "Romania":"ro",
    "Russia":"ru",
    "Rwanda":"rw",
    "Saint Kitts and Nevis":"kn",
    "Saint Lucia":"lc",
    "Saint Vincent and Grenadines":"vc",
    "Samoa":"ws",
    "San Marino":"sm",
    "Sao Tome and Principe":"st",
    "Saudi Arabia":"sa",
    "Senegal":"sn",
    "Serbia":"rs",
    "Seychelles":"sc",
    "Sierra Leone":"sl",
    "Singapore":"sg",
    "Slovakia":"sk",
    "Slovenia":"si",
    "Solomon Islands":"sb",
    "Somalia":"so",
    "South Africa":"za",
    "South Korea":"kr",
    "South Sudan":"ss",
    "Spain":"es",
    "Sri Lanka":"lk",
    "Sudan":"sd",
    "Suriname":"sr",
    "Swaziland":"sz",
    "Sweden":"se",
    "Switzerland":"ch",
    "Syria":"sy",
    "Taiwan":"tw",
    "Tajikistan":"tj",
    "Tanzania":"tz",
    "Thailand":"th",
    "Togo":"tg",
    "Tonga":"to",
    "Trinidad and Tobago":"tt",
    "Tunisia":"tn",
    "Turkey":"tr",
    "Turkmenistan":"tm",
    "Tuvalu":"tv",
    "Uganda":"ug",
    "Ukraine":"ua",
    "United Arab Emirates":"ae",
    "United Kingdom":"gb",
    "United States of America":"us",
    "Uruguay":"uy",
    "Uzbekistan":"uz",
    "Vanuatu":"vu",
    "Vatican City":"va",
    "Venezuela":"ve",
    "Vietnam":"vn",
    "Yemen":"ye",
    "Zambia":"zm",
    "Zimbabwe":"zw",
    "Wales":"Wales",
    "Northern ireland":"Northern ireland",
    "Hong Kong":"Hong Kong",  
    "Greenland":"Greenland",
    "Scotland":"Scotland",
    "Northern Cyprus":"Northern Cyprus",
    "England":"England",
    "Hawaii":"Hawaii",
    "Puerto Rico":"Puerto Rico",
    "Bermuda":"Bermuda",
    "Isle of Man":"Isle of Man",
    "South Ossetia":"South Ossetia",
    "Transnistria":"Transnistria",
    "Abkhazia":"Abkhazia",
    
}

export default AllCountries
