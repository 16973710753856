import React, { forwardRef } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useState} from 'react';
import '../../Styles/Index.css'
import '../../Styles/CustomDatePicker.css'
//import { format } from 'date-fns';
function CustomDatePicker(props) {

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className="example-custom-input dateButton" onClick={onClick} ref={ref}>
        {value}
        </button>
      ))

    if (props.selectsStart == true) {
        return (
            <DatePicker   
                selected={props.startDate} 
                customInput={<ExampleCustomInput/>} 
                onChange={(event) => props.onChange(event)} 
                selectsStart
                startDate={props.startDate} 
                endDate={props.endDate} 
                dateFormat="dd MMM yyyy"
                popperPlacement="bottom"
                closeOnScroll
            />
        )
    } else {
        return (
            <DatePicker   
                selected={props.endDate} 
                customInput={<ExampleCustomInput/>} 
                onChange={(event) => props.onChange(event)} 
                selectsEnd
                startDate={props.startDate} 
                endDate={props.endDate} 
                minDate={props.startDate} 
                dateFormat="dd MMM yyyy"
                popperPlacement="bottom"
                closeOnScroll
            />
        )
    }
    return(
        <div>
        
            <DatePicker   
            selected={props.startDate} 
            customInput={<ExampleCustomInput/>} 
            onChange={(event) => props.onChange(event)} 
            selectsStart = {props.selectsStart}
            selectsEnd = {!props.selectsStart}
            startDate={props.startDate} 
            endDate={props.endDate} 
            dateFormat="dd MMM yyyy"
            popperPlacement="bottom"
            
            />

           

        </div>
    )
}
CustomDatePicker.defaultProps = {
    startDate: new Date(),
    selectsStart: true,
    endDate: new Date(),
    onChange: console.log('adding Date onChange failed')
}
export default CustomDatePicker


/*

 <DatePicker   
            selected={endDate} 
            customInput={<ExampleCustomInput/>} 
            onChange={handleChange2}   
            selectsEnd 
            startDate={startDate} 
            endDate={endDate} 
            minDate={startDate} 
            dateFormat="dd MMM yyyy"
            popperPlacement="auto"
            popperModifiers={{
                flip: {
                    behavior: ["auto"] // don't allow it to flip to be above
                },
                preventOverflow: {
                    enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                    enabled: false // turn off since needs preventOverflow to be enabled
                }
            }}
            />








*/

//customInput={<ExampleCustomInput />}

//{format(startDate, "dd-MM-yyyy")}
//REFERENCE
//Button to show Inline version
//Date Range using input with clear button isClearable={true}
//Date range for one datepicker selectsRange inline
//Date Range
//dateFormat="yyyy/MM/dd"
//Custom input
//closeOnScroll={(e) => e.target === document} or closeOnScroll={true}
//isClearable
//Children ---- add adventure chase logo




/*handle open close

  'auto', 'auto-left', 'auto-right', 'bottom', 'bottom-end', 'bottom-start', 'left', 'left-end', 'left-start', 'right', 'right-end', 'right-start', 'top', 'top-end', 'top-start'
 
 customInput={<ExampleCustomInput />}
 
 const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className="example-custom-input" onClick={onClick} ref={ref}>
          {value}
        </button>
      ));
 
  */


      /*
    return (
        <div>
            <DatePicker 
            selected={startDate} 
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate} 
            popperPlacement="auto"
            popperModifiers={{
                flip: {
                    behavior: ["auto"] // don't allow it to flip to be above
                },
                preventOverflow: {
                    enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                },
                hide: {
                    enabled: false // turn off since needs preventOverflow to be enabled
                }
            }}
            dateFormat="dd MMM yyyy"
            closeOnScroll={true}
            isClearable={true}
            />

            <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                popperPlacement="auto"
                popperModifiers={{
                    flip: {
                        behavior: ["auto"] // don't allow it to flip to be above
                    },
                    preventOverflow: {
                        enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                    },
                    hide: {
                        enabled: false // turn off since needs preventOverflow to be enabled
                    }
                }}
                dateFormat="dd MMM yyyy"
                closeOnScroll={true}
                isClearable={true}
            />
        </div>
    )*/