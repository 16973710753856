import React from 'react';
import {useState, useEffect, useContext} from 'react';
import '../../Styles/NavBarStyles.css'
import '../../Styles/Index.css'
import {Link} from 'react-router-dom';
import { AuthContext } from '../Daxil/Auth';

function NavBar() {
    const currentUser = useContext(AuthContext)
    const [accountUID, setAccountUID] = useState(currentUser.currentChannelData ? currentUser.currentChannelData.channelUID : "");
    const [hide, setHide] = useState("hide")

    useEffect(() => {
        if (currentUser.currentChannelData) {
        //console.log("Account UID",accountUID)
        setAccountUID(currentUser.currentChannelData.channelUID)
        }
    }, [currentUser]);
    
    function handleHamburgerClick () {
        if (hide === "hide") {
            setHide("seekFlex")
            //console.log("opened")
        } else {
            setHide("hide")
            //console.log("closed")
        }
    }
    return (
        <div>
            <nav className="navbar">
                <div className="navbar-container alg-800">
                <Link className="logo" to='/'><h1>adventure chase</h1></Link>
                <ul className="nav-Links">
                    <Link className="nav-Link " to='/'><li >Home</li></Link>
                    {accountUID ? <Link className="nav-Link " to='/createBlog'><li>Upload</li></Link> : <></>}
                    {accountUID ? <Link className="nav-Link " to={`/account/${accountUID}`}><li>Channel</li></Link> : <></>}
                    {accountUID ? <Link className="nav-Link " to='/channelSettings'><li>Settings</li></Link> : <></>}
                    {accountUID ? <Link className="nav-Link " to='/signIn'><li>Sign Out</li></Link>:<Link className="nav-Link " to='/signIn'><li>Sign In</li></Link> }
                </ul>
                <div className='NavBar-hamburger'>
                    <button className='NavBar-hamburger-button alg-800' onClick={handleHamburgerClick}>|||</button>
                    <ul className={`NavBar-hamburger-nav-Links ${hide}`} onClick={handleHamburgerClick}>
                        <Link className={`nav-Link ${hide}`} to='/'><li >Home</li></Link>
                        {accountUID ? <Link className="nav-Link" to='/createBlog'><li>Upload</li></Link> : <></>}
                        {accountUID ? <Link className="nav-Link" to={`/account/${accountUID}`}><li>Channel</li></Link> : <></>}
                        {accountUID ? <Link className="nav-Link" to='/channelSettings'><li>Settings</li></Link> : <></>}
                        {accountUID ? <Link className="nav-Link" to='/signIn'><li>Sign Out</li></Link>: <Link className="nav-Link" to='/contactus'><li>Sign Up</li></Link>}
                    </ul>
                </div>
                </div>
            </nav>
        </div>
    )
}

export default NavBar
//contactus
//<a className="nav-Link " href="https://www.instagram.com/adventure__chase/" target="_blank">Sign Up</a>

//<a className="nav-Link " href="https://www.instagram.com/adventure__chase/" target="_blank">Sign Up</a>