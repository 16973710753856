import React from 'react'
import '../../Styles/Index.css'
import '../../Styles/Blog.css'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

function Type2(props) {

    const block = props.block
    const para = ReactHtmlParser(block.text)


    function bigVsSmallImage() {
        //<img className='type2-small-image' src={block.photoUrl} alt=""/>
        if (block.size == "big") {
            if (block.col == "col3") {
                block.col ="col2"
            }
            return (
                <div className='type2-big'>
                    {(block.position === "right") ?
                    <>
                    <div className='type2-big-content type2-right-content'>
                        <p className='alg-800 font-para-700 type2-title'>{block.title}</p>
                        <p className={`${block.col} font-para-300 para`}>{para}</p>
                    </div>
                    <img className='type2-big-image' src={block.photoUrl} alt=""/>
                    </> : <>
                    <img className='type2-big-image' src={block.photoUrl} alt=""/>
                    <div className='type2-big-content type2-left-content'>
                        <p className='alg-800 font-para-700 type2-title'>{block.title}</p>
                        <p className={`${block.col} font-para-300 para`}>{para}</p>
                    </div>
                    </>
                    }
                </div>
            )

        } else {
            var alightCenter = ""
            if (!block.title) {
                alightCenter ="center"
            }
            return (
                <div className='type2-small' style={{alignItems:alightCenter}}>
                    {(block.position === "right") ?
                    <>
                        <div className='type2-small-content type2-right-content'>
                            <p className='alg-800 font-para-700 type2-title'>{block.title}</p>
                            <p className={`${block.col} font-para-300 para`}>{para}</p>
                        </div>
                        <img className='type2-small-image' src={block.photoUrl} alt=""/>
                    </>:
                    <>
                    <img className='type2-small-image' src={block.photoUrl} alt=""/>
                    <div className='type2-small-content type2-left-content'>
                        <p className='alg-800 font-para-700 type2-title'>{block.title}</p>
                        <p className={`${block.col} font-para-300 para`}>{para}</p>
                    </div>
                    </>
                    }
                    
                </div>
            )
        }
    }

    return (
        <>
        { block.highlighted === "yes" ?
            <div className='type2-highlighted'>
                {bigVsSmallImage()}
                
            </div>
            :
            <div className='type2'>
                {bigVsSmallImage()}
                
            </div>
        }
        </>
    )
}

Type2.defaultProps = {
    title:"",
    text:"",
}

export default Type2
