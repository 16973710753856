import React from 'react'
import UploadBlocks from './UploadBlocks';
import {Link} from 'react-router-dom';
import {useState, useEffect} from 'react';
import firebase from '../../firebase';
import '../../Styles/Index.css'
import '../../Styles/UploadBlogStyles.css'
import { ListItem } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
//import AddIcon from '@mui/icons-material/Add';
import { getHTMLTextFromNormalText } from '../Tools/getHtmlTextFromNormalText';
import imageBtn from './Images/imageBtn.png';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { v4 as uuidv4 } from 'uuid';
import imageCompression from 'browser-image-compression';
function UploadChapter(props) {
    
      const [text,setText] = useState([]);
      const [image,setImage] = useState([]);
      const [finalBlocks,setFinalBlocks] = useState();
      const ref = firebase.firestore().collection("Blogs");
      const ref2 = firebase.firestore().collection("Channels");
      const storageRef = firebase.storage();
      const channelUID = "Channel-AdventureChase-1629209677488";
      
      //let firstTimeStamp = Date.now().toString()
      let newBlockUID = `Block--${uuidv4().split("-")[0]}`;
      const [blocks,setBlocks] = useState(props.blocks)//useState([{blockUID: newBlockUID}]);
      const [downloaded,setDownloaded] = useState(false);
      const [spotify,setSpotify] = useState(props.spotify);
      const [imageToDisplay, setImageToDisplay] = useState(props.featureImageURL);
      //console.log("-----Feature Image-------",props.featureImageURL);
      const [displaySaveBlockOrderButton, setDisplaySaveBlockOrderButton] = useState("displayOff");
      //const [displayPreviewButton, setDisplayPreviewButton] = useState("displayOff");

      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        maxIteration: 1,  
        useWebWorker: true
      }

      useEffect(() => {
        //getChapter();
        window.scrollTo(0, 0)
        let values = []
        if (blocks.length == 0) {
          //let firstTimeStamp = Date.now().toString()
          let newBlockUID = `Block--${uuidv4().split("-")[0]}`;
          values.push({blockUID: newBlockUID, blockType: ""});
          setBlocks([...values]);
          //console.log("blocks in use effect 😫", props.blocks);
        } else {//console.log("else blocks in use effect 😫", props.blocks);
      }
        
      }, []);

      const getChapter = async () => {
        const values = []
        if (downloaded == false) {
        ref.doc(props.finalBlogUID).collection("chapters").doc(props.chapterUID).get().then((doc) => {
          if (doc.exists) {
              //console.log("Chapter to edit", doc.data().blocks);
              values.push(doc.data().blocks)
              setDownloaded(true);
          } else {
              //console.log("No such document!");
          }
         }).then(()=> {
           if (downloaded == true) {
            setBlocks([...values]);
            setFinalBlocks([...values]);
           }
         }).catch((error) => {
          //console.log("Error getting document:", error);
         });
         
        }
      }

      //---------------------------------
      // MARK - ALERT
      //---------------------------------
      const [open, setOpen] = useState(false);
      const [openError, setOpenError] = useState(false);
      const [problem, setProblem] = useState("");
      const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
        //props.onClick(false);
      };
      const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenError(false);
      };
      //--------------------------------
    // MARK:- UPLOAD
  //--------------------------------

  const submitChapter = (e) => {
    e.preventDefault();
    var shaitan = blocks;
    const newDate = Date.now().toString()
    const lastEditTimeStamp = newDate
    //setDisplayPreviewButton("displayFlex")
    //let timeStamp = Date.now().toString()
    ref.doc(props.finalBlogUID).update({
      lastEditTimeStamp: lastEditTimeStamp
    }).catch(error => {
      setOpenError(true)
      setProblem(`${error.message}`)
    });
    ref.doc(props.finalBlogUID).collection("chapters").doc(props.chapterUID).update({
      chapter: props.chapter,
      chapterUID: props.chapterUID,
      //dateCreated: newDate,
      dateModified: newDate,
      lastEditTimeStamp: lastEditTimeStamp,
      spotify: spotify
    }).then(() => {

      if (imageFile) {

        const fileName = `${imageFile.name}-${uuidv4().split("-")[0]}`
        const uploadTask = storageRef.ref(`${props.currentChannel.channelUID}/${props.finalBlogUID}/${props.chapterUID}/${fileName}`).put(imageFile);
        
        uploadTask.on(
          "state_changed",
          snapshot =>  {
            const progress = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            setProgress(progress);
          },
          error => {
            alert(error.message)
          },
          () => {
            storageRef.ref(`${props.currentChannel.channelUID}/${props.finalBlogUID}/${props.chapterUID}`).child(fileName).getDownloadURL().then(url => {
              setImageToDisplay(url)
              ref.doc(props.finalBlogUID).collection("chapters").doc(props.chapterUID).update({featureImageURL:url})
            });
          }
        )

      }
      //console.log("hogaya 1")
    })
    .then(() => {
      //console.log("Blocks: ", blocks)
      Object.values(blocks).map((thisBlock, index) => {
        //console.log("this block: ", thisBlock)
        //if (typeof thisBlock.photo !== 'undefined') {
        //if (thisBlock.photo || thisBlock.photo2 || thisBlock.photo3 || thisBlock.photo4 || thisBlock.photo5) {
          //console.log("this block's Photo: ", thisBlock.photo)
          const fileName = `1-${Date.now().toString()}-${Math.random()}`
          const fileName2 = `2-${Date.now().toString()}-${Math.random()}`
          const fileName3 = `3-${Date.now().toString()}-${Math.random()}`
          const fileName4 = `4-${Date.now().toString()}-${Math.random()}`
          const fileName5 = `5-${Date.now().toString()}-${Math.random()}`
          if (thisBlock.photo) {
            const uploadTask = storageRef.ref(`${props.currentChannel.channelUID}/${props.finalBlogUID}/${props.chapterUID}/${fileName}`).put(thisBlock.photo);
            delete thisBlock.photo
          uploadTask.on(
            "state_changed",
            snapshot =>  {},
            error => {
              alert(error.message)
            },
            () => {
              storageRef.ref(`${props.currentChannel.channelUID}/${props.finalBlogUID}/${props.chapterUID}`).child(fileName).getDownloadURL().then(url => {
                //console.log(blogUID," -:- " ,url)
                //console.log("blocks old: ",blocks)
                thisBlock.photoUrl = url
                //console.log("blocks new: ",blocks)
                //ref.doc(blogUID).update({blocks: blocks})
                //setFinalBlocks(blocks)
                
                //console.log("blog uid: ", blogUID, "blocks in then: ", thisBlock.photoUrl)

                var tomato = []
                blocks.forEach(element => tomato.push(element))
                setFinalBlocks(tomato)
                shaitan = tomato
                ref.doc(props.finalBlogUID).collection("chapters").doc(props.chapterUID).update({blocks: {...tomato}})
              })
            }
          )
          }
          if (thisBlock.photo2) {
            const uploadTask2 = storageRef.ref(`${props.currentChannel.channelUID}/${props.finalBlogUID}/${props.chapterUID}/${fileName2}`).put(thisBlock.photo2);
            delete thisBlock.photo2
          uploadTask2.on(
              "state_changed",
              snapshot =>  {},
              error => {
                alert(error.message)
              },
              () => {
                storageRef.ref(`${props.currentChannel.channelUID}/${props.finalBlogUID}/${props.chapterUID}`).child(fileName2).getDownloadURL().then(url => {
                  thisBlock.photoUrl2 = url
                  var tomato = []
                  blocks.forEach(element => tomato.push(element))
                  setFinalBlocks(tomato)
                  shaitan = tomato
                  ref.doc(props.finalBlogUID).collection("chapters").doc(props.chapterUID).update({blocks: {...tomato}})
                })
              }
          )
          }
          if (thisBlock.photo3) {
            const uploadTask3 = storageRef.ref(`${props.currentChannel.channelUID}/${props.finalBlogUID}/${props.chapterUID}/${fileName3}`).put(thisBlock.photo3);
            delete thisBlock.photo3
          uploadTask3.on(
              "state_changed",
              snapshot =>  {},
              error => {
                alert(error.message)
              },
              () => {
                storageRef.ref(`${props.currentChannel.channelUID}/${props.finalBlogUID}/${props.chapterUID}`).child(fileName3).getDownloadURL().then(url => {
                  thisBlock.photoUrl3 = url
                  var tomato = []
                  blocks.forEach(element => tomato.push(element))
                  setFinalBlocks(tomato)
                  shaitan = tomato
                  ref.doc(props.finalBlogUID).collection("chapters").doc(props.chapterUID).update({blocks: {...tomato}})
                })
              }
            )
          }
          if (thisBlock.photo4) {
            const uploadTask4 = storageRef.ref(`${props.currentChannel.channelUID}/${props.finalBlogUID}/${props.chapterUID}/${fileName4}`).put(thisBlock.photo4);
            delete thisBlock.photo4
          uploadTask4.on(
              "state_changed",
              snapshot =>  {},
              error => {
                alert(error.message)
              },
              () => {
                storageRef.ref(`${props.currentChannel.channelUID}/${props.finalBlogUID}/${props.chapterUID}`).child(fileName4).getDownloadURL().then(url => {
                  thisBlock.photoUrl4 = url
                  var tomato = []
                  blocks.forEach(element => tomato.push(element))
                  setFinalBlocks(tomato)
                  shaitan = tomato
                  ref.doc(props.finalBlogUID).collection("chapters").doc(props.chapterUID).update({blocks: {...tomato}})
                })
              }
            )
          }
          if (thisBlock.photo5) {
            const uploadTask5 = storageRef.ref(`${props.currentChannel.channelUID}/${props.finalBlogUID}/${props.chapterUID}/${fileName5}`).put(thisBlock.photo5);
            delete thisBlock.photo5
          uploadTask5.on(
                "state_changed",
                snapshot =>  {},
                error => {
                  alert(error.message)
                },
                () => {
                  storageRef.ref(`${props.currentChannel.channelUID}/${props.finalBlogUID}/${props.chapterUID}`).child(fileName5).getDownloadURL().then(url => {
                    thisBlock.photoUrl5 = url
                    var tomato = []
                    blocks.forEach(element => tomato.push(element))
                    setFinalBlocks(tomato)
                    shaitan = tomato
                    ref.doc(props.finalBlogUID).collection("chapters").doc(props.chapterUID).update({blocks: {...tomato}})
                  })
                }
              )
          } 
          //ref.doc(blogUID).update({blocks: blocks})
          //} else {
            //if (!thisBlock.photo) {
            
              //ref.doc(props.finalBlogUID).collection("chapters").doc("1428915639299").update({blocks: firebase.firestore.FieldValue.arrayUnion(thisBlock)})
            //}
           
            //ref.doc(props.finalBlogUID).collection("chapters").doc("1428915639299").update({blocks: blocks})
         // }
        
        //}
      
      })
      
      //console.log("hogaya")


    })
    .then(() => {
      
      ref.doc(props.finalBlogUID).collection("chapters").doc(props.chapterUID).update({blocks: {...shaitan}}).then(() => {
        setOpen(true)
        //window.location.reload(false);
      }).catch(error => {
        setOpenError(true)
        setProblem(`${error.message}`)
      });
      //ref.doc(props.finalBlogUID).collection("chapters").doc("1428915639299").update({blocks: firebase.firestore.FieldValue.arrayUnion(thisBlock)})
      //})
      
      //console.log("hogaya", finalBlocks)
      
    })
    .catch(error => {
      setOpenError(true)
      setProblem(`${error.message}`)
    });

    
  }
  
  
     //state = {
     //   taskList: [{index: Math.random(), projectName: "", task:""}],
     //   date: "",
     //   descrip: ""

     //${this.props.chapterName.replaceAll(" ","")}
     // }
    
    
      const incrementBlock = (index) => {
        //numberOfBlocks++
        //console.log("+Block is Pressed")
        //const values = [...blocks];
        const values = [blocks];
        //console.log(values)
        let newTimeStamp = Date.now().toString()
        let newBlockUID = `Block--${newTimeStamp}`;
        const newBlock = {}
        newBlock.blockUID = newBlockUID
        newBlock.blockType = ""
        newBlock.index = values.length

        const tomato = Object.values(values[0])
        
        tomato.push(newBlock)
        //values[0] = tomato

        //console.log("Vlaues in increment", tomato)
        setBlocks(tomato)
      } 
      const incrementBlockAt = (index) => {
        //numberOfBlocks++
        //console.log("+Block is Pressed")
        //const values = [...blocks];
        const values = [blocks];
        //console.log(values)
        let newTimeStamp = Date.now().toString()
        let newBlockUID = `Block--${newTimeStamp}`;
        const newBlock = {}
        newBlock.blockUID = newBlockUID
        newBlock.blockType = ""
        newBlock.index = index + 1

        const tomato = Object.values(values[0])
        
        tomato.splice(index+1, 0, newBlock)
        //values[0] = tomato

        //console.log("Vlaues in increment", tomato)
        setBlocks(tomato)
      } 
    
    
    
      function handleParaChange(index, block, value) {
        const values = blocks
        //console.log("Before", blocks)
        if (values[index].blockUID == block.blockUID) {
          //console.log("BLOCK ALREADY THERE")
          block.text = getHTMLTextFromNormalText(value)
          values[index].text = block.text
          setBlocks(values)
          //console.log("After same", blocks)
        } else {
          //console.log("NEW BLOCK DETECTED")
          block.text = getHTMLTextFromNormalText(value)
          values[index] = block
          setBlocks(values)
          //console.log("After different", blocks.length)
        }
      }
    
      function handleImageChange(index, block, value) {
        const values = blocks
        block.photo = value
        block.index = index
        block.photoUrl = ""
        values[index] = block
        setBlocks(values)
        //console.log('newBlock', blocks)
    
        //const imageValues = imagesToUpload
        //imageValues.splice(index, 1, value)
        //setImagesToUpload(imageValues)
      }

      function handleImageDelete(index, block) {
        const values = blocks
        
        if (block.photo){
          delete block.photo 
          block.index = index
          values[index] = block
          setBlocks(values)
        }
        //console.log("Upload Chapter delete triggered")
        //console.log('newBlock', blocks)
    
        //const imageValues = imagesToUpload
        //imageValues.splice(index, 1, value)
        //setImagesToUpload(imageValues)
      }

      function handleImageChange2(index, block, value) {
        const values = blocks
        block.photo2 = value
        block.index = index
        block.photoUrl2 = ""
        values[index] = block
        setBlocks(values)
        //console.log('newBlock', blocks)
    
      }

      function handleImageDelete2(index, block) {
        const values = blocks
        
        if (block.photo2){
          delete block.photo2 
          block.index = index
          values[index] = block
          //values.splice(index, 1, block)
          setBlocks(values)
        }
      }

      function handleImageChange3(index, block, value) {
        const values = blocks
        block.photo3 = value
        block.index = index
        block.photoUrl3 = ""
        values[index] = block
        setBlocks(values)
        //console.log('newBlock', blocks)
    
      }

      function handleImageDelete3(index, block) {
        const values = blocks
        
        if (block.photo3){
          delete block.photo3 
          block.index = index
          values[index] = block
          setBlocks(values)
        }
      }

      function handleImageChange4(index, block, value) {
        const values = blocks
        block.photo4 = value
        block.index = index
        block.photoUrl4 = ""
        values[index] = block
        setBlocks(values)
        //console.log('newBlock', blocks)
    
      }

      function handleImageDelete4(index, block) {
        const values = blocks
        
        if (block.photo4){
          delete block.photo4 
          block.index = index
          values[index] = block
          setBlocks(values)
        }
      }

      function handleImageChange5(index, block, value) {
        const values = blocks
        block.photo5 = value
        block.index = index
        block.photoUrl5 = ""
        values[index] = block
        setBlocks(values)
        //console.log('newBlock', blocks)
    
      }

      function handleImageDelete5(index, block) {
        const values = blocks
        
        if (block.photo5){
          delete block.photo5 
          block.index = index
          values[index] = block
          setBlocks(values)
        }
      }

    
      function handleSizeChange(index, block, value) {
        const values = blocks
        block.size = value
        values[index] = block
        setBlocks(values)
        //console.log('newBlock', blocks)
      }
      function handleHighlightChange(index, block, value) {
        const values = blocks
        block.highlighted = value
        values[index] = block
        setBlocks(values)
        //console.log('newBlock', blocks)
      }
      function handlePositionChange(index, block, value) {
        const values = blocks
        block.position = value
        values[index] = block
        setBlocks(values)
        //console.log('newBlock', blocks)
      }
      function handleColChange(index, block, value) {
        const values = blocks
        
        block.col = value
        values[index] = block
        //values[index] = block
        setBlocks(values)
        //console.log('newBlock', values)
      }
      function handleTitleChange(index, block, value) {
        const values = blocks
        block.title = value
        values[index] = block
        setBlocks(values)
        //console.log('newBlock', values)
      }

      function handleTitleChange2(index, block, value) {
        const values = blocks
        block.title2 = value
        values[index] = block
        setBlocks(values)
        //console.log('newBlock', blocks)
      }

      function handleSpotifyChange(value) {
        const values = blocks
        //normal link  =  https://open.spotify.com/track/6wbDn8NI3ygkOeDJiS0ASi?si=385b3bd40c6e453a
        //playlist link = https://open.spotify.com/playlist/2dLGfiFl5iC4HQbRttygnH?si=9dd8e6e7493444b0
        //embeded pl    = https://open.spotify.com/embed/playlist/2dLGfiFl5iC4HQbRttygnH
        //embeded link =  https://open.spotify.com/embed/track/2k62KlwlLa2o7gdXvopqW6
        const original = value.trim();
        let split = original.split("https://open.spotify.com/")


        let embeded = original.split("https://open.spotify.com/embed/")
        if (embeded[1]) {
               value = embeded[1]
               //console.log('spotify embeded', embeded)
        } else {
              value=""
              //console.log('spotify fail1')
              
              if (split[1]) {
                value = split[1]
                //console.log('spotify split', value)
              } else {
                //try removing http
                let httpless = original.split("http://open.spotify.com/")
                //console.log('spotify fail2')
                if (httpless[1]) {
                  value = httpless[1]
                  //console.log('spotify httpless', httpless)
                } else {
                  //console.log('spotify fail3')
                  value=""
                  //alert("Make Sure your link starts like this: https://open.spotify.com/")
                }
              }
        }
        setSpotify(value)
      }


      function handleTypeBlock(index, block, value) {
        //let newTimeStamp = Date.now().toString()
        //let newBlockUID = `BlogBlock-${name.replaceAll(" ","")}-${newTimeStamp}`;
        const values = blocks
        block.blockType = value
        //block.blogUID = newBlockUID
        values[index] = block
        setBlocks(values)
        //console.log('newBlock', blocks)
      }

      //--------------------------------------------------------------------------
  const [imageFile, setImageFile] = useState(null);
  const [progress, setProgress] = useState(0);
  async function handleFeatureImageChange(e) { 
  //const handleFeatureImageChange = e => {
    if (e.target.files[0]) {
      try {
      const compressedFile = await imageCompression(e.target.files[0], options);
      setImageFile(compressedFile)
      setImageToDisplay(URL.createObjectURL(compressedFile));
      } catch (error) {
        console.log(error);
      }
    }
  }
      
//----DELETE--------------------------------------------

      function handleDeleteBlock(value) {
        //console.log('Deleting Blocks', value)
        const values = Object.values(blocks)
        values.splice(value,1)
        //delete values[value]
        //setBlocks(values)
        setBlocks([...values])
        //console.log('newBlock', values)
      }
//----TEST--------------------------------------------

    
      //---------Drag and Drop -----------------------------------------------------
  
  const saveBlockOrder = () => {
    
    //var blogUID = finalBlogUID
    //if (downloadedBlogUID) {
     // blogUID = downloadedBlogUID
    //  console.log('using downloadedBlogUID')
   // } else {console.log('using finalBlogUID:')}
    
    //blocks.forEach( (block, index) => {
      ref.doc(props.finalBlogUID).collection("chapters").doc(props.chapterUID).update({blocks:blocks}).then(() => {
        setDisplaySaveBlockOrderButton("displayOff")
      }).then(() => {
        setOpen(true)
      })
      .catch(error => {
        setOpenError(true)
        setProblem(`${error.message}`)
      });
    //})
  }
    return (
        <div className="blocksContainer">
            <div className='UploadChapter-title-container'>
              <div className='UploadChapter-title'>
                <p className="UploadChapter-title mav-800 uppercase">{props.chapter}</p>
              </div>
              <div className='UploadChapter-title-buttons'>
                <button onClick={(event) => props.onClick(false)} className='UploadChapter-title-back'>Back</button>
                <button className='UploadChapter-title-save' onClick={submitChapter}>Save</button>
              </div>
            </div>

            <div className='UploadChapter-feature-image-container'>
                  <label className="UploadChapter-feature-image-label alg-800 uppercase m-t-20">Feature Image*</label>
                  
                      <div className="UploadChapter-feature-image-preview">
                          <input type="file" id={`chapterFeatureImage-${props.selectedChapter.chapterUID}`} onChange={handleFeatureImageChange} accept="image/*"/>
                          <label for={`chapterFeatureImage-${props.selectedChapter.chapterUID}`} className="uploadImageLabel"><img src={imageToDisplay ? imageToDisplay : imageBtn} alt="Chapter Feature Image" className="img"/></label>
                      </div>
            </div>

            <div className="UploadChapter-spotify-container m-t-20">
                    <label className="UploadChapter-spotify-label alg-800 uppercase">Add Spotify Link</label>
                    <div className="UploadChapter-spotify-textfield-container">
                      <input className="UploadChapter-spotify-textfield" placeholder="https://open.spotify.com/embed/track/2k62KlwlLa2o7gdXvopqW6" defaultValue={props.selectedChapter.spotify} onChange={(event) => handleSpotifyChange(event.target.value)}/>
                      {spotify ? <iframe className="UploadChapter-spotify-iframe " src={`https://open.spotify.com/embed/${spotify}`}  width="300" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe> : <p></p>}
                    </div>
                    
            </div>


            
            <div className="flexsb UploadChapter-blocks-label-container">
                    <p className="alg-800 UploadChapter-blocks-label-p">Add Blog Paragraphs {blocks ? <p className='UploadChapter-blocks-label-p'>Blocks: {blocks.length}</p> : <p></p>}</p> 
            </div>
            <div className="m-t-20">




            {blocks ?



                  <DragDropContext onDragEnd={(event) => {
                    const srcIndex = event.source.index ;
                    const destIndex = event.destination?.index;
                    if(destIndex != null){
                    blocks.splice(destIndex,0,blocks.splice(srcIndex,1)[0]);
                    setDisplaySaveBlockOrderButton("displayOn")
                    }
                  }}>
                <Droppable droppableId='droppable-2'>





                {(provided, snapshot) => (
                <div 
                      ref={provided.innerRef}
                      style={{backgroundColor: snapshot.isDraggingOver ? 'f1f1f1':'white'}}
                      {...provided.droppableProps}
                      >
                    
                    {/*blocks.map((block, index) => (*/}
                      







                                  {
                                    
                      
                      Object.values(blocks).map((block, index) => (

                        <Draggable key={block.blockUID} 
                            draggableId={'draggable-'+ block.blockUID} 
                            index={index}>


                          {(provided, snapshot) => (
                                <ListItem className='trip-chapters-container  p-v-20' ref={provided.innerRef} {...provided.draggableProps} 
                                        style={{...provided.draggableProps.style,boxShadow: snapshot.isDragging ? "0 0 0.4rem #f1f1f1" : "#005533"}}>
                        



                                          <div className="UploadChapter-individual-block-container" key={index}>
                                              

                                              <div className="m-v-auto p-r-20 flexc "{...provided.dragHandleProps}>
                                                <DragIndicatorIcon/>
                                              </div>
                                              <UploadBlocks 
                                              block={block}
                                              index={index}

                                              onChange={(value) => handleParaChange(index, block, value)} 
                                              onChangeImage={(value) => handleImageChange(index, block, value)}
                                              onImageDelete={() => handleImageDelete(index, block)} 
                                              onTitleChange={(value) => handleTitleChange(index, block, value)}
                                              onSizeChange={(value) => handleSizeChange(index, block, value)}
                                              onHighlightChange={(value) => handleHighlightChange(index, block, value)}
                                              onPositionChange={(value) => handlePositionChange(index, block, value)}
                                              onColChange={(value) => handleColChange(index, block, value)}
                                              onClick={(value) => handleDeleteBlock(value)} 
                                              onClick2={(value) => handleTypeBlock(index,block,value)} myIndex={index}
                                              
                                              onChangeImage2={(value) => handleImageChange2(index, block, value)}
                                              onImageDelete2={() => handleImageDelete2(index, block)}
                                              
                                              onChangeImage3={(value) => handleImageChange3(index, block, value)}
                                              onImageDelete3={() => handleImageDelete3(index, block)}

                                              onChangeImage4={(value) => handleImageChange4(index, block, value)}
                                              onImageDelete4={() => handleImageDelete4(index, block)}

                                              onChangeImage5={(value) => handleImageChange5(index, block, value)}
                                              onImageDelete5={() => handleImageDelete5(index, block)}

                                              onTitleChange2={(value) => handleTitleChange2(index, block, value)}

                                              onIncrement={(value) => incrementBlockAt(value)}
                                              />
                                          </div>




                                </ListItem>
                      )}

     

                      </Draggable>
                    
                      ))
                      
                      }

                      {provided.placeholder}










                  </div>

                  )}

                  </Droppable>


                  </DragDropContext>


            :<></>} 



            </div>
            <button onClick={incrementBlock} className='UploadChapter-add-button'> + </button>
           

            <div className='UploadChapter-floating-container'>
              <button className={`UploadChapter-save-block-order-button mav-800 ${displaySaveBlockOrderButton}`} onClick={saveBlockOrder}>Save Order</button>
              <button className='UploadChapter-floating-save mav-800' onClick={submitChapter}>Save Chapter</button>
              
              <a href={`/blog/${props.finalBlogUID}/${props.chapterUID}`} target="_blank" className="UploadChapter-floating-preview mav-800" >Preview</a>

            </div>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} >
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' ,backgroundColor:"#164734", color:'#ffb71b'}}>
                Saved Successfully!
              </Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
              <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                Error! {problem}
              </Alert>
            </Snackbar>
            
        </div>
    )
}

UploadChapter.defaultProps = {
  selectedChapter:"",
  spotify:"",
  chapter: "New Chapter", 
  chapterUID: "Chapter-UID",
  blocks:[{blockUID: "newBlockUID"}],
  finalBlogUID: "Blog-Plaw-1632924301518"
}
export default UploadChapter


//REFERENCE
  
/* 
const submitChapter = (e) => {
    e.preventDefault();
    var shaitan = blocks;
    const newDate = Date.now().toString()
    //let timeStamp = Date.now().toString()
    ref.doc(props.finalBlogUID).collection("chapters").doc("1428915639299").update({
      chapter: props.chapterName,
      chapterUID: props.chapterUID,
      //dateCreated: newDate,
      dateModified: newDate
    })
    .then(() => {
      console.log("Blocks: ", blocks)
      blocks.map((thisBlock, index) => {
        console.log("this block: ", thisBlock)
        //if (typeof thisBlock.photo !== 'undefined') {
        if (thisBlock.photo) {
          //console.log("this block's Photo: ", thisBlock.photo)
          const fileName = `${thisBlock.photo.name}-${Date.now().toString()}-${Math.random()}`
          const uploadTask = storageRef.ref(`adventureChase/${fileName}`).put(thisBlock.photo);
          
          //const values = {...thisBlock}
          //delete values.photo
          //thisBlock = values
          delete thisBlock.photo
          //console.log("FileName",fileName )
          //console.log("photo:", thisBlock.photo)
          //console.log("uploadTask:", uploadTask)
          uploadTask.on(
            "state_changed",
            snapshot =>  {},
            error => {
              alert(error.message)
            },
            () => {
              storageRef.ref("adventureChase").child(fileName).getDownloadURL().then(url => {
                //console.log(blogUID," -:- " ,url)
                //console.log("blocks old: ",blocks)
                thisBlock.photoUrl = url
                //console.log("blocks new: ",blocks)
                //ref.doc(blogUID).update({blocks: blocks})
                //setFinalBlocks(blocks)
                
                //console.log("blog uid: ", blogUID, "blocks in then: ", thisBlock.photoUrl)

                var tomato = []
                blocks.forEach(element => tomato.push(element))
                setFinalBlocks(tomato)
                shaitan = tomato
                ref.doc(props.finalBlogUID).collection("chapters").doc("1428915639299").update({blocks: {...tomato}})
              })
            }
          )
              
          //ref.doc(blogUID).update({blocks: blocks})
          } else {
            //if (!thisBlock.photo) {
            
              //ref.doc(props.finalBlogUID).collection("chapters").doc("1428915639299").update({blocks: firebase.firestore.FieldValue.arrayUnion(thisBlock)})
            //}
           
            //ref.doc(props.finalBlogUID).collection("chapters").doc("1428915639299").update({blocks: blocks})
          }
        

      
      })
      
      //console.log("hogaya")


    })
    .then(() => {
      
      ref.doc(props.finalBlogUID).collection("chapters").doc("1428915639299").update({blocks: {...shaitan}})
      //ref.doc(props.finalBlogUID).collection("chapters").doc("1428915639299").update({blocks: firebase.firestore.FieldValue.arrayUnion(thisBlock)})
      //})
      
      console.log("hogaya", finalBlocks)
    })
    
    .then(() => {
      //setName('');
      //setDes('');
      //setBlocks([{blockUID: newBlockUID}])
      alert('Submitted')
    })
    .catch(error => {
      alert(error.message)
    });

    
  }
                  
*/