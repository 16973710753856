import React from 'react'
import {useState, useEffect} from 'react';
import firebase from '../../firebase';
import {Link} from 'react-router-dom';


function Channel({match}) {

    const [channel, setChannel] = useState([]);

    const ref = firebase.firestore().collection('Channels');
    //function getChannel() {
    const getChannel = async () => {
        ref.doc(match.params.id).get().then((doc) => {
            if (doc.exists) {
                setChannel(doc.data())
            } else {
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    useEffect(() => {
        getChannel();
        addView();
    }, []);

    let blogsToRender;


    // TRANSACTIONS -----
    function addView() {
        var viewRef = ref.doc(match.params.id);
        return firebase.firestore().runTransaction((transaction) => {
            return transaction.get(viewRef).then((viewsDoc) => {
                if (!viewsDoc.exists) {
                    throw "Document does not exist!";
                }
                var oldViews = viewsDoc.data().views
                if (typeof oldViews === 'undefined')  {
                    oldViews = 0
                }
                var newViews = oldViews + 1;
                transaction.update(viewRef, { views: newViews });
            });
        }).then(() => {
            console.log("Transaction successfully committed!");
        }).catch((error) => {
            console.log("Transaction failed: ", error);
        });
        }
    //-----------------




    // BLOGS -----------
    if(channel.blogList) {
        blogsToRender =  channel.blogList.map((blogUID) => {
            console.log(blogUID)
                return <div key={blogUID}>
                <Link to={`/blog/${blogUID}`}><h2>{blogUID}</h2></Link>
                </div>
        })
    } 
    //-----------------


    return (
        <div>
            <h1>Channel</h1>
            <h3>{channel.channelName}</h3>
            <h3>{channel.channelDescription}</h3>
            <h2>Blogs</h2>
            <div>{blogsToRender}</div>
        </div>
    )
}

export default Channel
