import React from 'react'
import '../../Styles/Index.css'
import '../../Styles/Blog.css'
import errorImg from '../../Assets/error.png'
function ChapterThumbnail(props) {

    const chapter = props.chapter;
    return (
        <div className='ChapterThumbnail-container'>
            
                <img className='ChapterThumbnail-image' src={chapter.featureImageURL?chapter.featureImageURL : errorImg } alt={`${chapter.chapter} - chapter thumbnail image`}/>
                <p className='ChapterThumbnail-title'>{chapter.chapter}</p>
          
        </div>
    )
}

ChapterThumbnail.defaultProps = {

}

export default ChapterThumbnail
