import React from 'react'
import {Link} from 'react-router-dom';
import firebase from '../../firebase';
import '../../Styles/Index.css'
import '../../Styles/Home.css'
import '../../Styles/BlogAsSmall.css'

import {useState, useEffect} from 'react';
import ReactHtmlParser from 'react-html-parser';
import errorImg from '../../Assets/error.png'

function BlogAsSmall(props) {
    const [channel, setChannel] = useState({});
    const ref2 = firebase.firestore().collection('Channels');
    const [hoverOn, setHoverOn] = useState("hoverOff");

    const getChannel= async ()=> {
        ref2.doc(props.blog.channelUID).get().then((doc) => {
            if (doc.exists) {
                const values = doc.data();
                 setChannel({...values})
            } else {
                //console.log("No such document!");
            }
        }).catch((error) => {
            //console.log("Error getting document:", error);
        });
    }

    useEffect(() => {
        getChannel();
        return () => {
            //to clean up anything you did before
        }
    }, []);

    const summary = ReactHtmlParser(props.blog.summary)
    return (
        <div className="BlogAsSmall-container">

            <Link className="BlogAsSmall-blog-container r-dec" to={`/blog/${props.blog.blogUID}`}>

                <div className='BlogAsSmall-image-box' onMouseEnter={()=>setHoverOn("hoverOn")} onMouseLeave={()=>setHoverOn("hoverOff")}>
                    <img className='BlogShowCase-image' src={props.blog.featureImageURL} onError={e => { e.currentTarget.src = errorImg; }} alt={`${props.blog.title} - blog feature image`}/>
                    <div className={`BlogAsSmall-image-hover-container ${hoverOn}`}>
                        {props.blog.finalBudget ?
                        <div className='BlogAsSmall-image-hover-top-div'>
                            <p className='BlogAsSmall-image-hover-label alg-800'>Budget</p>
                            <p className='BlogAsSmall-image-hover-text mav-800'>{`₹ ${props.blog.finalBudget}`}</p>
                        </div> : <></>
                        }
                        <button className='BlogAsSmall-image-hover-button'>View</button>
                        {props.blog.cities ?
                        <div className='BlogAsSmall-image-hover-bottom-div'>
                            <p className='BlogAsSmall-image-hover-label alg-800'>Cities</p>
                            <p className='BlogAsSmall-image-hover-text'>{props.blog.cities}</p>
                        </div> : <></>
                        }
                    </div>
                </div>
                <div className='BlogAsSmall-info-box'>
                    <div className='BlogAsSmall-profile-image-container'>
                    <img className='BlogAsSmall-profile-image' src={channel.photoURL}  alt={`${channel.channelName} - thumbnail image`}/>
                    </div>
                    <div className='BlogAsSmall-info-container'>
                        <p className="BlogAsSmall-trip-title mav-800 uppercase">{props.blog.title}</p>
                        <p className="BlogAsSmall-channel-name mav-800 uppercase">{channel.channelName}</p>
                        <div className="BlogAsSmall-flag-container">
                        {
                            Object.entries(props.blog.countries).slice(0, 7).map(([key,value], index) => (
                                <div key={index} className="BlogAsSmall-flag-each-container">
                                    <img src={`/Flags/${value}.png`} className='BlogProfile-flag' key={index} alt="flg"/>
                                </div>
                            ))
                        }
                        </div>
                    </div>

                </div>
                
            
            </Link>
            
        </div>
    )
}

export default BlogAsSmall


