import React from 'react';
import {useState, useEffect} from 'react';
import firebase from '../../firebase';
import '../../Styles/Index.css'
import '../../Styles/UploadBudgetStyles.css'
//import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
//import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
function UnloadBudget(props) {
    const [budget, setBudget] = useState(props.budget)

    const [expected,setExpected] = useState(() => budget.expected ? budget.expected : parseInt(0));
    const [flightXpt,setFlightXpt] = useState(() => budget.flightXpt ? budget.flightXpt : parseInt(0));
    const [visaXpt,setVisaXpt] = useState(() => budget.visaXpt ? budget.visaXpt : parseInt(0));
    const [insuranceXpt,setInsuranceXpt] = useState(() => budget.insuranceXpt ? budget.insuranceXpt : parseInt(0));
    const [accommodationXpt,setAccommodationXpt] = useState(() => budget.accommodationXpt ? budget.accommodationXpt : parseInt(0));
    const [localTransportationXpt,setLocalTransportationXpt] = useState(() => budget.localTransportationXpt ? budget.localTransportationXpt : parseInt(0));
    const [intercityTransportationXpt,setIntercityTransportationXpt] = useState(() => budget.intercityTransportationXpt ? budget.intercityTransportationXpt : parseInt(0));
    const [foodXpt,setFoodXpt] = useState(() => budget.foodXpt ? budget.foodXpt : parseInt(0));
    const [feesXpt,setFeesXpt] = useState(() => budget.feesXpt ? budget.feesXpt : parseInt(0));
    const [toursXpt,setToursXpt] = useState(() => budget.toursXpt ? budget.toursXpt : parseInt(0));
    const [souveniersXpt,setSouveniersXpt] = useState(() => budget.souveniersXpt ? budget.souveniersXpt : parseInt(0));
    const [shoppingXpt,setShoppingXpt] = useState(() => budget.shoppingXpt ? budget.shoppingXpt : parseInt(0));
    const [sportsXpt,setSportsXpt] = useState(() => budget.sportsXpt ? budget.sportsXpt : parseInt(0));
    const [emergencyXpt,setEmergencyXpt] = useState(() => budget.emergencyXpt ? budget.emergencyXpt : parseInt(0));
    const [miscellaneousXpt,setMiscellaneousXpt] = useState(() => budget.miscellaneousXpt ? budget.miscellaneousXpt : parseInt(0));

    const [final,setFinal] = useState(() => budget.final ? budget.final : parseInt(0));
    const [flightFinal,setFlightFinal] = useState(() => budget.flightFinal ? budget.flightFinal : parseInt(0));
    const [visaFinal,setVisaFinal] = useState(() => budget.visaFinal ? budget.visaFinal : parseInt(0));
    const [insuranceFinal,setInsuranceFinal] = useState(() => budget.insuranceFinal ? budget.insuranceFinal : parseInt(0));
    const [accommodationFinal,setAccommodationFinal] = useState(() => budget.accommodationFinal ? budget.accommodationFinal : parseInt(0));
    const [localTransportationFinal,setLocalTransportationFinal] = useState(() => budget.localTransportationFinal ? budget.localTransportationFinal : parseInt(0));
    const [intercityTransportationFinal,setIntercityTransportationFinal] = useState(() => budget.intercityTransportationFinal ? budget.intercityTransportationFinal : parseInt(0));
    const [foodFinal,setFoodFinal] = useState(() => budget.foodFinal ? budget.foodFinal : parseInt(0));
    const [feesFinal,setFeesFinal] = useState(() => budget.feesFinal ? budget.feesFinal : parseInt(0));
    const [toursFinal,setToursFinal] = useState(() => budget.toursFinal ? budget.toursFinal : parseInt(0));
    const [souveniersFinal,setSouveniersFinal] = useState(() => budget.souveniersFinal ? budget.souveniersFinal : parseInt(0));
    const [shoppingFinal,setShoppingFinal] = useState(() => budget.shoppingFinal ? budget.shoppingFinal : parseInt(0));
    const [sportsFinal,setSportsFinal] = useState(() => budget.sportsFinal ? budget.sportsFinal : parseInt(0));
    const [emergencyFinal,setEmergencyFinal] = useState(() => budget.emergencyFinal ? budget.emergencyFinal : parseInt(0));
    const [miscellaneousFinal,setMiscellaneousFinal] = useState(() => budget.miscellaneousFinal ? budget.miscellaneousFinal : parseInt(0));

    const [airline, setAirline] = useState(() => budget.airline ? budget.airline : "");
    const [flightFrom, setFlightFrom] = useState(() => budget.flightFrom ? budget.flightFrom : "");
    const [flightTo, setFlightTo] = useState(() => budget.flightTo ? budget.flightTo : "");
    const [visaRequired, setVisaRequired] = useState(() => budget.visaRequired ? budget.visaRequired : "");
    const [vegNonVeg, setVegNonVeg] = useState(() => budget.vegNonVeg ? budget.vegNonVeg : "");
    const [alcoholIncluded, setAlcoholIncluded] = useState(() => budget.alcoholIncluded ? budget.alcoholIncluded : "");
    const [noOfSites, setNoOfSites] = useState(() => budget.noOfSites ? budget.noOfSites : "");
    
    const [editingExpected, setEditingExpected] = useState(false)
    const [editingFinal, setEditingFinal] = useState(false)
    

    const ref = firebase.firestore().collection("Blogs");

    useEffect(() => {
        if (editingExpected) {updateExpected();};
        
        if (editingFinal) {updateFinal();};

       //updateBudget();
      }, [flightXpt,visaXpt,insuranceXpt,accommodationXpt,localTransportationXpt,intercityTransportationXpt,foodXpt,feesXpt,toursXpt,souveniersXpt,shoppingXpt,sportsXpt,emergencyXpt,miscellaneousXpt, flightFinal,visaFinal,insuranceFinal,accommodationFinal,localTransportationFinal,intercityTransportationFinal,foodFinal,feesFinal,toursFinal,souveniersFinal,shoppingFinal,sportsFinal,emergencyFinal,miscellaneousFinal]);

    function updateExpected() {
        setExpected(flightXpt + visaXpt + insuranceXpt + accommodationXpt + localTransportationXpt + intercityTransportationXpt + foodXpt + feesXpt + toursXpt + souveniersXpt + shoppingXpt + sportsXpt + emergencyXpt + miscellaneousXpt)
    }

    function updateFinal() {
        setFinal(flightFinal + visaFinal + insuranceFinal + accommodationFinal + localTransportationFinal + intercityTransportationFinal + foodFinal + feesFinal + toursFinal + souveniersFinal + shoppingFinal + sportsFinal + emergencyFinal + miscellaneousFinal)
    }

    function updateDifference() {
        let difference = ((final-expected) / expected)*100
        return Math.round(difference)
    }

    function startEditingExpected() {
        setEditingExpected(true);
    }
    function startEditingFinal() {
        setEditingFinal(true);
    }

    //---------------------------------
      // MARK - ALERT
      //---------------------------------
      const [open, setOpen] = useState(false);
      const [openError, setOpenError] = useState(false);
      const [problem, setProblem] = useState("");
      const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
        props.onClick(false);
      };
      const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenError(false);
      };
    function updateBudget() {
        setBudget({
        "final":final,
        "expected": expected,
        "flightXpt": flightXpt,
        "visaXpt": visaXpt,
        "insuranceXpt": insuranceXpt,
        "accommodationXpt":accommodationXpt,
        "localTransportationXpt":localTransportationXpt,
        "intercityTransportationXpt":intercityTransportationXpt,
        "foodXpt":foodXpt,
        "feesXpt": feesXpt,
        "toursXpt":toursXpt,
        "souveniersXpt":souveniersXpt,
        "shoppingXpt":shoppingXpt,
        "sportsXpt": sportsXpt,
        "emergencyXpt":emergencyXpt,
        "miscellaneousXpt":miscellaneousXpt,

        "flightFinal": flightFinal,
        "visaFinal": visaFinal,
        "insuranceFinal": insuranceFinal,
        "accommodationFinal":accommodationFinal,
        "localTransportationFinal":localTransportationFinal,
        "intercityTransportationFinal":intercityTransportationFinal,
        "foodFinal":foodFinal,
        "feesFinal": feesFinal,
        "toursFinal":toursFinal,
        "souveniersFinal":souveniersFinal,
        "shoppingFinal":shoppingFinal,
        "sportsFinal": sportsFinal,
        "emergencyFinal":emergencyFinal,
        "miscellaneousFinal":miscellaneousFinal,
        
        "airline":airline,
        "flightFrom":flightFrom,
        "flightTo":flightTo,
        "visaRequired":visaRequired,
        "vegNonVeg":vegNonVeg,
        "alcoholIncluded":alcoholIncluded,
        "noOfSites":noOfSites
        })
    }



    function uploadBudget () {
        //alert("Upload Budget clicked")
        if (props.finalBlogUID.split("-")[1] === props.currentUser.split("-")[1]){
        let budgetArr = {
            "final":final,
            "expected": expected,
            "flightXpt": flightXpt,
            "visaXpt": visaXpt,
            "insuranceXpt": insuranceXpt,
            "accommodationXpt":accommodationXpt,
            "localTransportationXpt":localTransportationXpt,
            "intercityTransportationXpt":intercityTransportationXpt,
            "foodXpt":foodXpt,
            "feesXpt": feesXpt,
            "toursXpt":toursXpt,
            "souveniersXpt":souveniersXpt,
            "shoppingXpt":shoppingXpt,
            "sportsXpt": sportsXpt,
            "emergencyXpt":emergencyXpt,
            "miscellaneousXpt":miscellaneousXpt,
    
            "flightFinal": flightFinal,
            "visaFinal": visaFinal,
            "insuranceFinal": insuranceFinal,
            "accommodationFinal":accommodationFinal,
            "localTransportationFinal":localTransportationFinal,
            "intercityTransportationFinal":intercityTransportationFinal,
            "foodFinal":foodFinal,
            "feesFinal": feesFinal,
            "toursFinal":toursFinal,
            "souveniersFinal":souveniersFinal,
            "shoppingFinal":shoppingFinal,
            "sportsFinal": sportsFinal,
            "emergencyFinal":emergencyFinal,
            "miscellaneousFinal":miscellaneousFinal,
            
            "airline":airline,
            "flightFrom":flightFrom,
            "flightTo":flightTo,
            "visaRequired":visaRequired,
            "vegNonVeg":vegNonVeg,
            "alcoholIncluded":alcoholIncluded,
            "noOfSites":noOfSites
        }
        //console.log("ref link",props.finalBlogUID)
        ref.doc(props.finalBlogUID).update({
            budget:budgetArr,
            finalBudget: final,
            expectedBudget: expected,
        }).then(() => {
            setOpen(true)
          }).catch(error => {
            setOpenError(true)
            setProblem(`${error.message}`)
        });
    }
    }

  return (
    <div className='UploadBudget-container'>
        <div className="UploadBudget-title-container">
            <div className='UploadBudget-title'>
                <p>ADD BUDGET</p>
            </div>
            <div className='UploadBudget-title-buttons'>
                <button onClick={(event) => props.onClick(false)} className='UploadBudget-title-back'>Back</button>
                <button className='UploadBudget-title-save' onClick={(event) => uploadBudget()}>Save</button>
            </div>
        </div>
        <div className='UploadBudget-info-labels-container mav-800 m-t-20'>
            <div className='UploadBudget-info-labels'>
                <p className='UploadBudget-info-labels-text alg-800'>Difference:</p>
                {updateDifference()> 0 ? <p className='UploadBudget-info-labels-arrows' style={{color:`${updateDifference()> 0 ? "#E04E4E" : "#17AA4A"}`}}>▴</p>:<p p className='UploadBudget-info-labels-arrows' style={{color:`${updateDifference()> 0 ? "#E04E4E" : "#17AA4A"}`}}>▾</p>}
                <p className='UploadBudget-info-labels-difference' style={{color:`${updateDifference()> 0 ? "#E04E4E" : "#17AA4A"}`}}> {Math.abs(updateDifference())}% {updateDifference()> 0 ? "Over" : "Under"} budget</p>
            </div>
            <div className='UploadBudget-meta-labels'>
                <p className='UploadBudget-meta-expected-label'>Expected</p>
                <p className='UploadBudget-meta-final-label'>Final</p>
            </div>
        </div>
        <div className='UploadBudget-budget-box'>

            <div className='UploadBudget-buffer-box'>
                <div className='UploadBudget-box-left'>
                    <p className='UploadBudget-total-label'>Total</p>

                    <div className='UploadBudget-breakdown-container'>
                        <div className='UploadBudget-categories'>
                            <div className='UploadBudget-cell'>
                                <p>Flights</p>
                            </div>
                            <div className='UploadBudget-cell'>
                                <p>Visa</p>
                            </div>
                            <div className='UploadBudget-cell'>
                                <p>Travel Insurance</p>
                            </div>
                            <div className='UploadBudget-cell'>
                            <p>Accomodation</p>
                            </div>
                            <div className='UploadBudget-cell'>
                            <p>Local Transportation</p>
                            </div>
                            <div className='UploadBudget-cell'>
                            <p>Inter-city Transportation</p>
                            </div>
                            <div className='UploadBudget-cell'>
                            <p>Food and Drinks</p>
                            </div>
                            <div className='UploadBudget-cell'>
                            <p>Entry Fees at sites/events</p>
                            </div>
                            <div className='UploadBudget-cell'>
                            <p>Tours</p>
                            </div>
                            <div className='UploadBudget-cell'>
                            <p>Souveniers</p>
                            </div>
                            <div className='UploadBudget-cell'>
                            <p>Shopping</p>
                            </div>
                            <div className='UploadBudget-cell'>
                            <p>Sports</p>
                            </div>
                            <div className='UploadBudget-cell'>
                            <p>Health &amp; Emergency Budget</p>
                            </div>
                            <div className='UploadBudget-cell'>
                            <p>Miscellaneous</p>
                            </div>
                            
                        </div>
                        <div className='UploadBudget-optional1'>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Airline" value={airline} onChange={(event) => setAirline(event.target.value)}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Required?" value={visaRequired} onChange={(event) => setVisaRequired(event.target.value)}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                 <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Veg/Non Veg?" value={vegNonVeg} onChange={(event) => setVegNonVeg(event.target.value)}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="No. of sites" value={noOfSites} onChange={(event) => setNoOfSites(event.target.value)}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                        </div>
                        <div className='UploadBudget-optional2'>
                        <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="From" value={flightFrom} onChange={(event) => setFlightFrom(event.target.value)}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Alcohol Included?" value={alcoholIncluded} onChange={(event) => setAlcoholIncluded(event.target.value)}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                        </div>
                        <div className='UploadBudget-optional3'>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="To" value={flightTo} onChange={(event) => setFlightTo(event.target.value)}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="" disabled />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='UploadBudget-box-right'>
                    <div className='UploadBudget-box-right-column'>
                        <div className='UploadBudget-value-container'>
                            <input className="UploadBudget-total-label" placeholder="Total" type="number" min="0" max="100000000" value={expected} onChange={(event) => setExpected(parseInt(event.target.value))}/>
                            <p className="UploadBudget-xe">₹</p>
                        </div>
                        <div className='UploadBudget-breakdown-container'>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="expected" type="number" min="0" max="100000000" defaultValue={flightXpt} onChange={(event) => {setFlightXpt(parseInt(event.target.value)); startEditingExpected()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="expected" type="number" min="0" max="100000000" defaultValue={visaXpt} onChange={(event) => {setVisaXpt(parseInt(event.target.value)); startEditingExpected()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="expected" type="number" min="0" max="100000000" defaultValue={insuranceXpt}  onChange={(event) => {setInsuranceXpt(parseInt(event.target.value)); startEditingExpected()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="expected" type="number" min="0" max="100000000" defaultValue={accommodationXpt} onChange={(event) => {setAccommodationXpt(parseInt(event.target.value)); startEditingExpected()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="expected" type="number" min="0" max="100000000" defaultValue={localTransportationXpt} onChange={(event) => {setLocalTransportationXpt(parseInt(event.target.value)); startEditingExpected()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="expected" type="number" min="0" max="100000000" defaultValue={intercityTransportationXpt} onChange={(event) => {setIntercityTransportationXpt(parseInt(event.target.value)); startEditingExpected()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="expected" type="number" min="0" max="100000000" defaultValue={foodXpt} onChange={(event) => {setFoodXpt(parseInt(event.target.value)); startEditingExpected()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="expected" type="number" min="0" max="100000000" defaultValue={feesXpt} onChange={(event) => {setFeesXpt(parseInt(event.target.value)); startEditingExpected()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="expected" type="number" min="0" max="100000000" defaultValue={toursXpt} onChange={(event) => {setToursXpt(parseInt(event.target.value)); startEditingExpected()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="expected" type="number" min="0" max="100000000" defaultValue={souveniersXpt} onChange={(event) => {setSouveniersXpt(parseInt(event.target.value)); startEditingExpected()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="expected" type="number" min="0" max="100000000" defaultValue={shoppingXpt} onChange={(event) => {setShoppingXpt(parseInt(event.target.value)); startEditingExpected()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="expected" type="number" min="0" max="100000000" defaultValue={sportsXpt} onChange={(event) => {setSportsXpt(parseInt(event.target.value)); startEditingExpected()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="expected" type="number" min="0" max="100000000" defaultValue={emergencyXpt} onChange={(event) => {setEmergencyXpt(parseInt(event.target.value)); startEditingExpected()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="expected" type="number" min="0" max="100000000" defaultValue={miscellaneousXpt} onChange={(event) => {setMiscellaneousXpt(parseInt(event.target.value)); startEditingExpected()}}/>
                            </div>
                        </div>
                    </div>
                    <div className='UploadBudget-box-right-column'>

                        <div className='UploadBudget-value-container'>
                            <input className="UploadBudget-total-label" placeholder="Total" type="number" min="0" max="100000000" value={final} onChange={(event) => setFinal(parseInt(event.target.value))}/>
                            <p className="UploadBudget-xe">₹</p>
                        </div>
                        <div className='UploadBudget-breakdown-container'>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Final" type="number" min="0" max="100000000" defaultValue={flightFinal} onChange={(event) => {setFlightFinal(parseInt(event.target.value)); startEditingFinal()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Final" type="number" min="0" max="100000000" defaultValue={visaFinal} onChange={(event) => {setVisaFinal(parseInt(event.target.value)); startEditingFinal()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Final" type="number" min="0" max="100000000" defaultValue={insuranceFinal} onChange={(event) => {setInsuranceFinal(parseInt(event.target.value)); startEditingFinal()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Final" type="number" min="0" max="100000000" defaultValue={accommodationFinal} onChange={(event) => {setAccommodationFinal(parseInt(event.target.value)); startEditingFinal()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Final" type="number" min="0" max="100000000" defaultValue={localTransportationFinal} onChange={(event) => {setLocalTransportationFinal(parseInt(event.target.value)); startEditingFinal()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Final" type="number" min="0" max="100000000" defaultValue={intercityTransportationFinal} onChange={(event) => {setIntercityTransportationFinal(parseInt(event.target.value)); startEditingFinal()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Final" type="number" min="0" max="100000000" defaultValue={foodFinal} onChange={(event) => {setFoodFinal(parseInt(event.target.value)); startEditingFinal()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Final" type="number" min="0" max="100000000" defaultValue={feesFinal} onChange={(event) => {setFeesFinal(parseInt(event.target.value)); startEditingFinal()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Final" type="number" min="0" max="100000000" defaultValue={toursFinal} onChange={(event) => {setToursFinal(parseInt(event.target.value)); startEditingFinal()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Final" type="number" min="0" max="100000000" defaultValue={souveniersFinal} onChange={(event) => {setSouveniersFinal(parseInt(event.target.value)); startEditingFinal()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Final" type="number" min="0" max="100000000" defaultValue={shoppingFinal} onChange={(event) => {setShoppingFinal(parseInt(event.target.value)); startEditingFinal()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Final" type="number" min="0" max="100000000" defaultValue={sportsFinal} onChange={(event) => {setSportsFinal(parseInt(event.target.value)); startEditingFinal()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Final" type="number" min="0" max="100000000" defaultValue={emergencyFinal} onChange={(event) => {setEmergencyFinal(parseInt(event.target.value)); startEditingFinal()}}/>
                            </div>
                            <div className='UploadBudget-cell'>
                                <input className="UploadBudget-cell-textfield" placeholder="Final" type="number" min="0" max="100000000" defaultValue={miscellaneousFinal} onChange={(event) => {setMiscellaneousFinal(parseInt(event.target.value)); startEditingFinal()}}/>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            

        </div>
        <Snackbar open={open} autoHideDuration={500} onClose={handleClose} >
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' ,backgroundColor:"#164734", color:'#ffb71b'}}>
                Saved Successfully!
            </Alert>
            </Snackbar>
        <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
            <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                Error! {problem}
            </Alert>
        </Snackbar>

    </div>
  )
}

UnloadBudget.defaultProps = {
    onclick:"failed to go back",
    currentUser: "user-user"
  }

export default UnloadBudget;



//value={cities} onChange={(event) => setCities(event.target.value)}