import React from 'react'
import {useState, useEffect} from 'react';
import '../../Styles/Index.css'
import '../../Styles/UploadBlogStyles.css'
import imageBtn from './Images/imageBtn.png'
//import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Radio from '@mui/material/Radio';
import imageCompression from 'browser-image-compression';
function Type3Block(props) {
    const [imageToDisplay, setImageToDisplay] = useState(props.photoUrl)
    const [imageToDisplay2, setImageToDisplay2] = useState(props.photoUrl2)
    const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        maxIteration: 2,  
        useWebWorker: true
    }
    async function handleImageChange(e) { 
       // console.log("HandleImage Change")
        if (e.target.files[0]) {
        

        try {
            const compressedFile = await imageCompression(e.target.files[0], options);
            setImageToDisplay(URL.createObjectURL(compressedFile));
            props.onChangeImage(compressedFile)

        } catch (error) {console.log(error)}


        } else {
            //console.log("HandleImage Change fail")
        }
    }
    const handleDeleteImage = e => {
        setImageToDisplay(null)
        props.onImageDelete();
        //console.log("type 3 delete triggered")
    }

    async function handleImageChange2(e) { 
        //console.log("HandleImage Change")
        if (e.target.files[0]) {
            try {
                const compressedFile = await imageCompression(e.target.files[0], options);
                setImageToDisplay2(URL.createObjectURL(compressedFile));
                props.onChangeImage2(compressedFile)
            } catch (error) {console.log(error)}
        
        } else {
            //console.log("HandleImage2 Change fail")
        }
    }
    const handleDeleteImage2 = e => {
        setImageToDisplay2(null)
        props.onImageDelete2();
        //console.log("type 3 delete2 triggered")
    }

    const [highlighted, setHighlighted] = React.useState(() => props.highlighted ? props.highlighted : "no");
    const handleHighlightChange = (event) => {
        setHighlighted(event.target.value);
        props.onHighlightChange(event.target.value);
    };

    return (

        <div className='Type3-upload-container'>

            <div className="Type3-title-container">
                <div className="Type3-title-titleInput-container">
                    <input className="Type3-titleInput" placeholder="Title (optional)" defaultValue={props.title} onChange={(event) => props.onTitleChange(event.target.value)}/>
                </div>
                <div className="Type3-title-titleInput-container">
                    <input className="Type3-titleInput" placeholder="Title (optional)" defaultValue={props.title2} onChange={(event) => props.onTitleChange2(event.target.value)}/>
                </div>
            </div>

            <div className="Type3-body-container">
                <div className="Type3-body-imageContainer">
                    <div className="Type3-body-imagePreview">
                        <input type="file" id={props.myIndex} onChange={handleImageChange} accept="image/*"/>
                        <label for={props.myIndex} className="Type3-body-uploadImageLabel"><img src={imageToDisplay ? imageToDisplay : imageBtn} alt="type31" className="Type3-body-img"/></label>
                        
                    </div>
                    <div className="Type3-imageTools">
                        {imageToDisplay ? <button className="Type3-deleteImage-button mav-800" onClick={(event) => handleDeleteImage(event)} >x</button> : <div/>}
                    </div>
                </div>

                <div className="Type3-body-imageContainer">
                    <div className="Type3-body-imagePreview">
                        <input type="file" id={`${props.myIndex}2`} onChange={handleImageChange2} accept="image/*"/>
                        <label for={`${props.myIndex}2`} className="Type3-body-uploadImageLabel"><img src={imageToDisplay2 ? imageToDisplay2 : imageBtn} alt="type32" className="Type3-body-img"/></label>
                        
                    </div>
                    <div className="Type3-imageTools">
                        {imageToDisplay2 ? <button className="Type3-deleteImage-button mav-800" onClick={(event) => handleDeleteImage2(event)} >x</button> : <div/>}
                    </div>
                </div>
            </div>

            <div className="Type3-highlighted-container m-t-5">
                            <p className='Type3-highlighted-text mav-800'>Highlighed?</p>
                            <Radio
                                checked={highlighted === "no"}
                                onChange={handleHighlightChange}
                                value="no"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'No' }}
                                sx={{
                                    paddingTop:'0px',
                                    paddingBottom: '3px'
                                }}
                            />
                            <label className='font-weight-700 uppercase m-0 p-0'>No</label>
                            <Radio
                                checked={highlighted === "yes"}
                                onChange={handleHighlightChange}
                                value="yes"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Yes' }}
                                sx={{
                                    color: '#990000',
                                    paddingTop:'0px',
                                    paddingBottom: '3px',
                                    marginLeft:'10px',
                                    '&.Mui-checked': {
                                    color: '#990000',
                                    },
                                }}
                            />
                            <label className='font-weight-700 uppercase m-l-5 p-0'>Yes</label>
                </div>            

            <div className="Type3-upload-tools-container">
                <button  onClick={() => props.onClick(props.myIndex)} type="submit" className='Type3-upload-tools-button delete'><DeleteOutlineIcon style={{fontWeight:"800",fontSize:"1.6rem"}}/></button>
                <button onClick={() => props.onIncrement(props.myIndex)} className='Type3-upload-tools-button'><AddIcon style={{fontWeight:"800",fontSize:"2.0rem"}}/></button>
            </div>
        </div>
        
    )
}

Type3Block.defaultProps = {
    myIndex:0,
    size:"small",
    //text: "",
    title:"",
    title2:"",
    photoUrl:null,
    photoUrl2:null,
    onClick: console.log('upload blocks onclick failed'),
    onChange: console.log('upload blocks onChangeText failed'),
    onChangeImage: console.log('upload blocks onChangeImage failed'),
    onChangeImage2: console.log('upload blocks onChangeImage failed'),
    onTitleChange: console.log('upload blocks onTitleChange failed'),
    onImageDelete: console.log('Image Delete onImageDelete failed'),
    onTitleChange2: console.log('upload blocks onTitleChange failed'),
    onImageDelete2: console.log('Image Delete onImageDelete failed'),
    onIncrement: console.log('onIncrement failed'),
}

export default Type3Block


/*




*/
