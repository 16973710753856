import React from 'react'
import {useState, useEffect, useContext} from 'react';
import firebase from '../../firebase';
import { AuthContext } from '../Daxil/Auth';
import Type1 from './Type1';
import '../../Styles/Index.css'
import '../../Styles/Blog.css'
import Type2 from './Type2';
import Type3 from './Type3';
import Type4 from './Type4';
import Type5 from './Type5';
import {BsArrowRightSquareFill, BsArrowLeftSquareFill} from 'react-icons/bs'
import  Tooltip, { tooltipClasses }  from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Link , useHistory} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import BlogProfile from './BlogProfile';
function Chapter({match}) {
    const currentUser = useContext(AuthContext)
    const [currentChannelData, setCurrentChannelData] = useState({});
    const [currentUserData, setCurrentUserData] = useState({});
    const blogID = match.url.split("/")[match.url.split("/").length - 2]
    const chapterUID = match.params.id
    const [blocks,setBlocks] = useState([])
    const ref = firebase.firestore().collection('Blogs');
    const [chapter, setChapter] = useState({});
    const [prevChapter, setPrevChapter] = useState({});
    const [nextChapter, setNextChapter] = useState({});
    const [currentChapterIndex, setCurrentChapterIndex] = useState();
    const [totalChapterLength, setTotalChapterLength] = useState();
    const [blog, setBlog] = useState({});
    const [loading, setLoading] = useState(false);
    const [hideSpotify, setHideSpotify] = useState("seek");
    const firebaseAnalytics = firebase.analytics();
    const [channelUID, setChannelUID] = useState(`Channel-${window.location.pathname.split("/")[2].split("-")[1]}`);
    const [channel, setChannel] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    useEffect(() => {
        getChapter()
        getChannel()
        setCurrentChannelData(currentUser.currentChannelData)
        setCurrentUserData(currentUser.currentUserData)
        window.scrollTo(0, 0)
        return () => {
            //to clean up anything you did before
        }
    }, [currentUser,match]);

    function handleHideSpotify() {
        if (hideSpotify === "seek") {
            setHideSpotify("hide")
        } else {
            setHideSpotify("seek")
        }
    }

    const ref2 = firebase.firestore().collection('Channels');
    //function getChannel() {
    const getChannel = async () => {
        ref2.doc(channelUID).get().then((doc) => {
            if (doc.exists) {
                setChannel(doc.data())
            } else {
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }


    const getChapter = async () => {
        setLoading(true);
        const values = []
        const chap = []
        //console.log("...downloading Chapter");
        firebaseAnalytics.logEvent('Chapters_Visited');
        ref.doc(blogID).collection('chapters').doc(`${chapterUID}`).get().then((doc) => {
            if (doc.exists) {
                
                values.push(doc.data().blocks)
                chap.push(doc.data())
                
            } else {
                //console.log("No such document!");
            }
        }).then(()=> {
            //console.log("Chapter after downloading - - - ", chap)
            firebaseAnalytics.logEvent(`Chapter_${[...chap][0].chapter}_Visited`);
            setChapter([...chap][0])
             setBlocks(values[0]);
             setLoading(false);
        }).then(()=> {
            //console.log("Blocks after downloading - - - ", chapter);
            ref.doc(blogID).get().then((doc) => {
                if (doc.exists) {
                    setBlog(doc.data())
                    setStartDate(doc.data().startDate.toDate())
                    setEndDate(doc.data().endDate.toDate())
                    getNextAndPrevChapter()
                } else {
                    //console.log("No such document!");
                }
            })
        }).catch((error) => {
            //console.log("Error getting document:", error);
        });
    }
    function getNextAndPrevChapter() {
          
        
        const values = []
        ref.doc(blogID).collection("chapters").get().then(querySnapshot => {
          
          querySnapshot.forEach(doc => {
            values.push(doc.data())
  
          })
        }).then(() => {
  
          values.sort((element1, element2) => {
            if (element1.index > element2.index) {
              return 1
            } else {
              return -1
            }
          })
          let currentIndex = values.findIndex(chapterr => chapterr.chapterUID === chapterUID)
          var prevChapterValue = {}
          var nextChapterValue = {}
          if (currentIndex > 0) {
            prevChapterValue = values[currentIndex - 1]
          }
          
          if (currentIndex < (values.length - 1)) {
            nextChapterValue = values[currentIndex + 1]
          }

          setPrevChapter(prevChapterValue)
          setNextChapter(nextChapterValue)
          setCurrentChapterIndex(currentIndex)
          setTotalChapterLength(values.length)
          //setChapters([...values])
        }).catch((error) => {
          //console.log("Error getting document:", error);
        });

          let currentIndex = values.indexOf(chapter)
          //console.log("Vlaues 🤓",values.length)
          //console.log(chapter)
          //console.log("Current 🤓",values.findIndex(chapterr => chapterr.chapterUID === chapterUID))
          
          /*
          
          */
          
      }
      
    function loadBlock(value) {
        //console.log("blocks at return", blocks)
        //console.log("Block to load",value);
        switch (value.blockType) {
            case "type1":
                //console.log("type 1 block")
                return (            
                    <Type1 block={value}/>
                )
                break;
            case "type2":
                return (            
                    <Type2 block={value}/>
                )
                break;
            case "type3":
                return (            
                    <Type3 block={value}/>
                )
                break;
            case "type4":
                return (            
                    <Type4 block={value}/>
                )
                break;
            case "type5":
                return (            
                    <Type5 block={value}/>
                )
                break;
            default:
            console.log(`Sorry, something broke. Refresh the page`);
            }

    }


    //console.log("DId it add it to chapter------",chapter.spotify)

    const BlackTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
          color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.black,
          color: '#ffb71b',
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
      }));

      const GreenTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
          color: "#164734",
        },
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#164734',
          color: '#ffb71b',
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
      }));

    const history = useHistory();

    function handlePreviousChapter() {
        if (Object.keys(prevChapter).length !== 0) {
            //alert(prevChapter.chapter)
            history.push(`/blog/${blogID}/${prevChapter.chapterUID}`)
        } else {}
    }

    function handleNextChapter() {
        if (Object.keys(nextChapter).length !== 0) {
            //alert(nextChapter.chapter)
            history.push(`/blog/${blogID}/${nextChapter.chapterUID}`)
        } else {}
    }

    if (loading) {
        return <h1 key="loader">Loading your next adventure...</h1>
    }
      
    return (
        <div className='Chapter-container'>
            <Helmet>
                <title>{chapter ? `${chapter.chapter} -` : ""} Adventure Chase </title>
                <meta name='description' content={blog.summary ? `${ReactHtmlParser(blog.summary.slice(0,170))}`: "Adventure Chase Travel Blog" }/>
                <link rel='canonical' href={`/blog/${blogID}/${match.params.id}`}/>
            </Helmet>
            { blogID ?<>
            <div className='Chapter-back-container'>
                <div className='alg-800 font-para-700 Chapter-back-crumbs'>
                    <Link to={`/blog/${blogID}`} className='Chapter-back-crumb'>{blog ? blog.title : ""} </Link> 
                    <p className='Chapter-back-crumb'>&gt; </p> 
                    <p className='Chapter-back-crumb'>{chapter.chapter}</p> 
                </div>
                <Link to={`/blog/${blogID}`} className='Chapter-back-button'><p className='Chapter-back-button-text'>Back</p></Link>
            </div>
            
            
            <div className='Chapter-blog-profile-container'>
                    {blog && channel ?
                    <BlogProfile channelUID={channelUID} linkTitleTo={`/blog/${blogID}`} cities={blog.cities} finalBudget={blog.finalBudget} expectedBudget={blog.expectedBudget} photo={channel.photoURL} credits={blog.credits} name={chapter.chapter} startDate={startDate} endDate={endDate}  countries={blog.countries}/>
                    : <></>}
            </div>
            </>: <></>
            }
            {/*<h1 className='alg-800 font-para-700 Chapter-title'>{chapter.chapter}</h1>*/}
        
            {
                
                Object.values(blocks).map((block, index) => (
                    <div key={index}>
                    {loadBlock(block)}
                    </div>

                ))
            }
            
            { chapter.spotify ?
                    (hideSpotify === "seek") ?
                    <div className="Chapter-spotify-container">
                        <p className="font-para-700 Chapter-spotify-theme-song"  onClick={handleHideSpotify}>Hide Song &darr;</p>
                        
                        <iframe className="Chapter-spotify" src={`https://open.spotify.com/embed/${chapter.spotify}`}  width="300" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                      
                     </div> : <div className="Chapter-spotify-container-hidden" onClick={handleHideSpotify}>
                        <p className="font-para-700 Chapter-spotify-theme-song"  >&lt;</p>
                     </div>
            : <></>
            }
            <div className='Chapter-switch-container'>
                <div className='Chapter-switch-back' >  
                    { (Object.keys(prevChapter).length !== 0) ? 
                    <BlackTooltip title="Previous Log" >
                        <Link className="Chapter-switch-link" style={{height: '29px'}} to={`/blog/${blogID}/${prevChapter.chapterUID}`}>
                            <BsArrowLeftSquareFill style={{height: '29px',width:'29px', color:"#333330"}}/> <p className='Chapter-switch-text'>Previous</p>
                        </Link>
                    </BlackTooltip> : <></>
                    }
                </div>
                <p className='Chapter-chapterCounter'>{currentChapterIndex+1} - {totalChapterLength}</p>
                <div className='Chapter-switch-next' > 
                    { (Object.keys(nextChapter).length !== 0) ?   
                    <BlackTooltip title="Next Log" >
                        <Link className="Chapter-switch-link" style={{height: '29px'}} to={`/blog/${blogID}/${nextChapter.chapterUID}`}>
                        <p className='Chapter-switch-text'>Next</p> <BsArrowRightSquareFill style={{height: '29px',width:'29px', color:"#333330"}}/> 
                        </Link>
                    </BlackTooltip> : <></>
                    }
                </div>
            </div>
        </div>
    )
}

export default Chapter
//AiOutlineArrowLeft