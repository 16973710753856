import firebase from "firebase";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCYiMQTfw_0SxdfKOxz_-pGg7iADDtF4_I",
    authDomain: "travelite-7ce27.firebaseapp.com",
    projectId: "travelite-7ce27",
    storageBucket: "travelite-7ce27.appspot.com",
    messagingSenderId: "364682126814",
    appId: "1:364682126814:web:aef92ae8e982201a55a94f",
    measurementId: "G-G554JN6H64"
  };

  firebase.initializeApp(firebaseConfig);

  export default firebase;

  //to secure this put this data in .env file (environment)//to secure this put this data in .env file (environment file)   https: // youtu.be/3ZEz-iposj8?t=684
  //authDomain: "travelite-7ce27.firebaseapp.com",