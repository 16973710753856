import React from 'react'
import {useState} from 'react';
import '../../Styles/Index.css'
//MUI
import {styled} from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
//import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';


const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
      borderRadius: 8,
      position: 'relative',
      backgroundColor: '#164734',
      border: '2px solid #000000',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      color: '#FFB71B',
      fontWeight:'600',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }));

function MuiColumn(props) {

    const [columns, setColumns] = useState(props.col)
    const handleChange = (event) => {
        setColumns(event.target.value);
        props.onColChange(event.target.value)
     };
    return (
        <div>
        <FormControl sx={{ m: 1 }} variant="standard">
        
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={columns}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          <MenuItem value="Columns">
            
          </MenuItem>
          <MenuItem value={"col1"}>1 Column</MenuItem>
          <MenuItem value={"col2"}>2 Columns</MenuItem>
          <MenuItem value={"col3"}>3 Columns</MenuItem>
        </Select>
      </FormControl>
        </div>
    )
}

MuiColumn.defaultProps = {
  col:"col1",
  onColChange: console.log('upload blocks onColChange failed'),
}

export default MuiColumn
//<InputLabel id="demo-customized-select-label">Columns</InputLabel>
//backgroundColor: '#164734'theme.palette.background.paper,
//<em>C</em>