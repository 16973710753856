import React from 'react'
import '../../Styles/Index.css'
import '../../Styles/CountryTag.css'
import CloseIcon from '@mui/icons-material/Close';

function CountryTag(props) {
   // console.log(props.flag)
    //console.log(props.country)
    let flag = `/Flags/${props.flag}.png`
    return (
        <div className="flagContainer">
            <div className="xContainer">
                <button className="xButton" onClick={() => props.onClick(props.country)} ><CloseIcon style={{padding:"0px",fontWeight:"800", fontSize:"1.86rem"}}/></button>
            </div>
            <div className="flagBox">
                <img className="flag" src={flag} alt={""}/>
            </div>
        </div>
    )
}

CountryTag.defaultProps = {
    flag: "",
    country:"",
    onClick: console.log('upload blocks onclick failed'),
}

export default CountryTag
