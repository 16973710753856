import React from 'react'
import '../../Styles/Index.css'
import '../../Styles/Blog.css'
import errorImg from '../../Assets/error.png'
import { Link } from 'react-router-dom'
import {useState, useEffect} from 'react';
import firebase from '../../firebase';
function BlogProfile(props) {

    const firebaseAnalytics = firebase.analytics();
    function fixDate(date) {
        let dateArray = date.split(" ")
        let day = dateArray[2];
        let month = dateArray[1];
        let year = dateArray[3];
        let finalDate = `${day} ${month} ${year}`
        return finalDate
    }
    
    const startDate = fixDate(props.startDate.toString());
    const endDate = fixDate(props.endDate.toString());

   // const [linkTitleTo,setLinkTitleTo] = useState(`/account/${props.channelUID}`)
   /* useEffect(() => {
        if (props.linkTitleTo !="") {
          //  setLinkTitleTo(props.linkTitleTo)
           // alert(props.linkTitleTo)
        }
        return () => {
            //to clean up anything you did before
        }
    }, [linkTitleTo]); //`/account/${props.channelUID}`
*/

//Link to={`/account/${props.channelUID}`}
    return (
        <div className='BlogProfile-container'>
            <div className='BlogProfile-top'>
            <div className='BlogProfile-profile-info-container'>
                 <img className='BlogProfile-image' src={props.photo} alt="thumbnail image" onError={e => { e.currentTarget.src = errorImg; }}/>
                <div className='BlogProfile-profile-info'>
                    <h1 className='BlogProfile-name alg-800 font-openSans uppercase'>{props.name}</h1>
                    
                    <div>
                        <p className='BlogProfile-date '>{startDate} - {endDate}</p>
                        <div className="BlogProfile-flag-container">
                        {
                            Object.entries(props.countries).map(([key,value], index) => (
                                <div key={index} >
                                    <img src={`/Flags/${value}.png`} className='BlogProfile-flag' key={index} alt={""}/>
                                </div>
                            ))
                        }
                        </div>
                        <p className='BlogProfile-cities'>{props.cities}</p>
                    </div>
                </div>
            </div>

            <div className='BlogProfile-blog-info-container'>
                <div className='BlogProfile-blog-info-budget'>
                    <p className='font-openSans BlogProfile-budget'>{props.finalBudget || props.expectedBudget ? props.currency : ""}{ props.finalBudget ? props.finalBudget : props.expectedBudget ? props.expectedBudget : "" }</p>
                    <p className='font-openSans BlogProfile-budget-label'>{ props.finalBudget || props.expectedBudget ? "BUDGET" : ""}</p>
                </div>
                <div className='BlogProfile-blog-info-journal'>
                    <p className='font-openSans BlogProfile-journal'>{props.chapterCount}</p>
                    <p className='font-openSans BlogProfile-journal-label'>{props.chapterCount ? "LOGS" : ""}</p>
                </div>
            </div>
            </div>
            { props.credits ?
            <div className='BlogProfile-bottom'>
                    {
                        props.credits.map((credit) => (
                            <a className='BlogProfile-bottom-credits mav-800 uppercase' href={credit.link} target="_blank" onClick={() => {firebaseAnalytics.logEvent(`${credit.credit}_Opened`)}}>{credit.credit}</a>
                        ))
                    }
            </div> : <></>
            }
        </div>
    )
}
//<img className='ChapterThumbnail-image' src={chapter.featureImageURL} alt="chapter thumbnail image"/>

BlogProfile.defaultProps = {
    name: "",
    startDate:"not set yet",
    endDate:"not set yet",
    photo:"",
    countries: {},
    finalBudget:"",
    expectedBudget:"",
    currency:"₹",
    chapterCount:"",
    cities:"",
    linkTitleTo:""
}

export default BlogProfile
