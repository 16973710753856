import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
//Switch
import NavBar from './Components/NavBar/NavBar';
import DetailedBlog from './Components/Blog/DetailedBlog';
import Home from './Components/Home/Home';
import UploadBlog from './Components/UploadBlog/UploadBlog';
import SignUp from './Components/Daxil/SignUp';
import Account from './Components/Daxil/Account';
import Channel from './Components/Channel/Channel';
import SignIn from './Components/Daxil/SignIn';
import { AuthProvider } from './Components/Daxil/Auth';
import Footer from './Components/Footer/Footer';
import ChannelSettings from './Components/Daxil/ChannelSettings';
import Chapter from './Components/Blog/Chapter';
import ContactUs from './Components/Daxil/ContactUs';
import { Helmet } from 'react-helmet';
import HardBlogList from './Components/Blog/HardBlogList';
import DetailedIssues from './Components/Issues/DetailedIssues';
import Piece from './Components/Piece/Piece';

//basetag
function App() {
  return (
    <AuthProvider>
    <Router> 
    
    <div className="App">
    <Helmet>
      <title>Adventure Chase Travel Magazine</title>
      <meta name='description' content='Adventure Chase is an online travel magazine platform which allows travelers and explorers to open their own travel blog based magazine.' data-react-helmet="true"/>
      <meta name="theme-color" content="#164734" data-react-helmet="true"/>
      <meta name='keywords' content='Adventure Chase, Adventure, Where to travel in India?, places to visit in india, sites to see in india, tourism, explore, travel magazine, hiking bags, hiking, places to go, things to see, bored, what to do, backpacking in india, makemytrip, make my trip, holiday plans, holiday packages, holidays in india, india tourism, holiday destinations, holiday packages, holiday packages india, india tours, honeymoon packages india, honeymoon packages, india tour packages, Indian holidays, cheap holiday packages, india travel, travel in india, india, travel, honeymoon, packages, holidays' data-react-helmet="true"/>
      <meta name="apple-mobile-web-app-capable" content="yes" data-react-helmet="true"/>
      <meta name="mobile-web-app-capable" content="yes" data-react-helmet="true"/>
      <meta name="apple-mobile-web-app-title" content='Adventure Chase' data-react-helmet="true"/>


      
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" data-react-helmet="true"/>
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" data-react-helmet="true"/>
      <link rel="manifest" href="/site.webmanifest" data-react-helmet="true"/>
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ffb71b" data-react-helmet="true"/>

      <meta property="og:url" content="https://adventure-chase.com/" data-react-helmet="true"/>
      <meta property="og:type" content="website" data-react-helmet="true"/>
      <meta property="og:title" content="Adventure Chase" data-react-helmet="true"/>
      <meta property="og:description" content="Adventure Chase is an online travel magazine platform which allows travelers and explorers to open their own travel blog." data-react-helmet="true"/>
      <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/travelite-7ce27.appspot.com/o/acShareImage.png?alt=media&token=0beb47cc-e0a9-471c-a544-9e5d3bb2bbaf" data-react-helmet="true"/>

      <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
      <meta property="twitter:domain" content="adventure-chase.com"/>
      <meta property="twitter:url" content="https://www.adventure-chase.com/"/>
      <meta name="twitter:title" content="Adventure Chase" data-react-helmet="true"/>
      <meta name="twitter:description" content="Adventure Chase is an online travel magazine platform which allows travelers and explorers to open their own travel blog." data-react-helmet="true"/>
      <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/travelite-7ce27.appspot.com/o/acShareImage.png?alt=media&token=0beb47cc-e0a9-471c-a544-9e5d3bb2bbaf" data-react-helmet="true"/>
      <meta name="twitter:image:alt" content="Adventure Chase Tiger in the jungle logo" data-react-helmet="true" />
      

      
      <meta name="msapplication-TileColor" content="#154533"/>
    </Helmet>
      <NavBar/>
      <body>
      <Switch>
        <Route path="/" exact component={Home}/>
        <Route path="/createBlog/:id" component={UploadBlog}/>
        <Route path="/createBlog/" exact component={UploadBlog}/>
        <Route path="/channelSettings" export component={ChannelSettings}/>
        <Route path="/signIn" export component={SignIn}/>
        <Route path="/blog"  exact component={DetailedBlog}/>
        <Route path="/blog/:id"  exact component={DetailedBlog}/>
        <Route path="/issue/:id"  exact component={DetailedIssues}/>
        
        <Route path="/blog/:id/:id" component={Chapter}/>
        <Route path="/account/:id"  component={Account}/>
        <Route path="/channel/:id"  component={Channel}/>
        <Route path="/contactus"  component={ContactUs}/>
        <Route path="/triplist"  component={HardBlogList}/>
       
      </Switch>
      </body>
      <Footer/>
    </div>

    </Router>
    </AuthProvider>
  );
}

// TO RENDER THE DATA
// in return() and under ur container <div> - create {array.map((dataArray) => (
// <div key=(dataset.id)>
// <h2>{dataset.desc}</h2>
// </div>
// ))}

/*

<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
<Route path="/piece/:id"  exact component={Piece}/>

*/

export default App;
