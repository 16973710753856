import React from 'react'
import '../../Styles/Index.css'
import '../../Styles/Daxil.css'
import firebase from '../../firebase';
import {useEffect} from 'react';
import { Helmet } from 'react-helmet';
function ContactUs() {
  const firebaseAnalytics = firebase.analytics();

  useEffect(() => {
    firebaseAnalytics.logEvent("ContactUs_Visited")
    return () => {
        //to clean up anything you did before
    }
  }, []);
  return (
    <div className='ContactUs-container'>
        <Helmet>
          <title>Contact Us - Adventure Chase </title>
          <meta name='description' content="Open an online travel magazine on Adventure Chase by contacting @adventure__chase on Instagram. If you want to start your own travel blog, Adventure Chase can help."/>
          <link rel='canonical' href="/contactus"/>
        </Helmet>
        <a className='ContactUs-box' href="https://www.instagram.com/adventure__chase/" target="_blank">
            <h1 className='ContactUs-Title alg-800'>Sign Up</h1>
            <p className='ContactUs-message mav-800'>To open a Travel Magazine Channel on Adventure Chase : Message @adventure__chase on Instagram</p>
        </a>
    </div>
  )
}

export default ContactUs