import React from 'react'
import {useState, useContext, useEffect} from 'react';
import { Link } from 'react-router-dom';
import firebase from '../../firebase';
import { AuthContext } from '../Daxil/Auth';
import imageBtn from '../UploadBlog/Images/imageBtn.png'
import '../../Styles/Index.css'
import '../../Styles/Daxil.css'
import { TextField } from '@mui/material';
import { getHTMLTextFromNormalText } from '../Tools/getHtmlTextFromNormalText';
//import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CountryPicker from '../Tools/CountryPicker';
import BlogAsSmall from '../Home/BlogAsSmall';
import { Helmet } from 'react-helmet';
import imageCompression from 'browser-image-compression';
function ChannelSettings() {
    const currentUser = useContext(AuthContext)
    const [currentChannel,setCurrentChannel] = useState({});

    const [channelName,setChannelName] = useState("");
    const [country,setCountry] = useState("");
    const [channelDescription,setChannelDescription] = useState("");
    const [imageToDisplay, setImageToDisplay] = useState(null);
    const [articles, setArticles] = useState([]);
    const ref = firebase.firestore().collection("Channels");
    const ref2 = firebase.firestore().collection("Blogs");
    const storageRef = firebase.storage();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState("settings");
    const [selectedSettings, setSelectedSettings] = useState("ChannelSettings-sidebar-button-selected");
    const [selectedTrips, setSelectedTrips] = useState("");
    const firebaseAnalytics = firebase.analytics();
    useEffect(() => {
      firebaseAnalytics.logEvent("Settings_Visited")
     
        getChannel();
        
      
      //console.log("executed")
        return () => {
          //to clean up anything you did before
        }
    }, []);
    
    //---------------------------------
      // MARK - ALERT
      //---------------------------------
      const [open, setOpen] = useState(false);
      const [openError, setOpenError] = useState(false);
      const [problem, setProblem] = useState("");
      const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
      const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenError(false);
      };

    const getChannel =  () => {
        if (currentUser.currentUserData) {
        ref.doc(currentUser.currentUserData.channelUID).get().then((doc) => {
            if (doc.exists) {
                //console.log(doc.data());
                setCurrentChannel(doc.data())

                setChannelName(doc.data().channelName);
                setCountry(doc.data().country);
                setChannelDescription(doc.data().channelDescription);
                setImageToDisplay(doc.data().photoURL);
                const items = [];
                setLoading(true);
                
                doc.data().blogList.forEach((blogUID) => {
                    ref2.doc(blogUID).get().then((doc) => {
                      if (doc.data()) {
                        items.push(doc.data());
                        setArticles([...items]);
                      }
                    })
                    
                })
                
                
                setLoading(false);
            } else {
                //console.log("No such Channel!");
            }
        }).catch((error) => {
            //console.log("Error getting Channel:", error);
        });
        }
    }
    

    const submitChannel = (e) => {
      firebaseAnalytics.logEvent("Settings_Saved_Pressed")
        e.preventDefault();
      
        let timeStamp = Date(Date.now()).toString()
        
        ref.doc(currentChannel.channelUID).update({
        //----------------------------------------
          channelName: channelName,
          country: country,
          channelDescription: channelDescription,
          modifiedOn: timeStamp
        //----------------------------------------
        }).then(() => {
            if (imageFile) {
            const fileName = `${imageFile.name}-${Date.now().toString()}`
            const uploadTask = storageRef.ref(`${currentChannel.channelUID}/${fileName}`).put(imageFile);
            
            uploadTask.on(
              "state_changed",
              snapshot =>  {
                const progress = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
      
                setProgress(progress);
              },
              error => {
                //console.log(error.message)
              },
              () => {
                storageRef.ref(`${currentChannel.channelUID}`).child(fileName).getDownloadURL().then(url => {
                  setImageToDisplay(url)
                  ref.doc(currentChannel.channelUID).update({photoURL:url})
                
                });
              }
            )
            }
            //console.log("hogaya 1")
          }).then(() => {
          setOpen(true)
        }).catch(error => {
          setOpenError(true)
          setProblem(`${error.message}`)
        });
        
        //setChannelName('');
        //setCountry('');
        //setChannelDescription('');
    }

    const [imageFile, setImageFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
      maxIteration: 1,  
      useWebWorker: true
    }
    async function handleImageChangeTest(e) { 
    //const handleImageChangeTest = e => {
      if (e.target.files[0]) {
        try {
        const compressedFile = await imageCompression(e.target.files[0], options);
        setImageFile(compressedFile)
        setImageToDisplay(URL.createObjectURL(compressedFile));
        } catch(error) {
          console.log(error)
        }
      }
    }

    //const channelDes = "";
    //channelDes = ReactHtmlParser(currentChannel.channelDescription);

    const getTrips =  () => {
      setShow("trips")
      setSelectedTrips("ChannelSettings-sidebar-button-selected")
      setSelectedSettings("")
    }
    const getSettings =  () => {
      setShow("settings")
      setSelectedSettings("ChannelSettings-sidebar-button-selected")
      setSelectedTrips("")
    }
    

    return (
             
        <div className='ChannelSettings-container'>
          <Helmet>
            <title>Settings - Adventure Chase </title>
            <meta name='description' content="Open an online travel magazine on Adventure Chase by contacting @adventure__chase on Instagram. If you want to start your own travel blog, Adventure Chase can help."/>
            <link rel='canonical' href="/channelSettings"/>
          </Helmet>
            <div className='ChannelSettings-sidebar'>
              <div className='ChannelSettings-sidebar-buttons-container'>
                <button className={`ChannelSettings-sidebar-button mav-800 uppercase ${selectedSettings}`} onClick={() =>getSettings()} >Settings</button>
                <button className={`ChannelSettings-sidebar-button mav-800 uppercase ${selectedTrips}`} onClick={() =>getTrips()} >Trips</button>
              </div>
            </div>
            <div className='ChannelSettings-body'>
                <h1 className='ChannelSettings-body-title-label mav-800 uppercase'>{show == "settings" ? "Channel Settings": "Your trips"}</h1>
                {currentChannel ? 

                  show == "settings" ?
                    <form className="form" onSubmit={submitChannel}>
                        <div className="ChannelSettings-body-channel-image-container">
                                <p className="ChannelSettings-change-image-label mav-800 uppercase">Channel Image</p>
                                <div className="ChannelSettings-image-box ">
                                  <input type="file" id="channelImage" className="ChannelSettings-image-input" onChange={handleImageChangeTest} accept="image/*"/>
                                  <label for="channelImage"><img src={imageToDisplay ? imageToDisplay : imageBtn} alt="channelImage" className="ChannelSettings-image-label" /></label>          
                                </div>
                        </div>

                        <p className='ChannelSettings-field-label mav-800 uppercase'>Channel Name</p>
                        <input className='ChannelSettings-field-input mav-800 ' placeholder="channelName" defaultValue={currentChannel.channelName} onChange={(event) => setChannelName(event.target.value)}/>
                        
                        <p className='ChannelSettings-field-label mav-800 uppercase'>Country</p>
                        <div className='ChannelSettings-field-dropper'>
                        <CountryPicker onClick={(value) => setCountry(value[0][0])} defaultCountry={currentChannel.country}/>
                        </div>
                        <p className='ChannelSettings-field-label mav-800 uppercase'>Channel Description</p>
                        
                        <div className="ChannelSettings-channel-description-textarea mav-800">
                        <TextField
                          id="standard-textarea"
                          label=""
                          placeholder="summary"
                          fullWidth
                          minRows={10}
                          InputProps={{ disableUnderline: true, style:{marginLeft:'0px',padding:"10px", border:"solid", borderWidth:'1px', borderColor:'#eee', borderRadius:'10px'} }}
                          multiline
                          defaultValue={String(channelDescription).replaceAll(" <br>", "\n")}
                          onChange={(event) => setChannelDescription(getHTMLTextFromNormalText(event.target.value))}
                          variant="standard"
                        />
                        </div>
                        <p className='ChannelSettings-warn-label mav-800'>⚠︎ Ignore 	&lt;br&gt;</p>

                        <button className='ChannelSettings-submit-button mav-800 uppercase' type="submit">Save</button>
                        
                    </form>
                  : <div className='ChannelSettings-trips'>
                    {articles.map((blog) => (
                      <div key={blog.blogUID} className="flexc m-b-20">
                          <BlogAsSmall blog={blog}/>
                      </div>
                    ))}
                  </div>


                : <div>
                    <h3>Not signed in</h3>
                    <Link className="nav-Link " to='/signIn'><li>Sign In</li></Link>
                  </div>
                }
            </div>

            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} >
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' ,backgroundColor:"#164734", color:'#ffb71b'}}>
                Saved Successfully!
              </Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
              <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                Error! {problem}
              </Alert>
            </Snackbar>
                         


            
        </div>
    
    )
}

export default ChannelSettings

/*


I usually travel to lesser-known destinations. I first like to learn the language, at least till the basic level so that I can buy stuff from the market and then only I travel.

I prefer budget travelling since funds are usually tight especially when you want to travel often and to more destinations for a longer period of time. I don't like to go to a place just to touch that place and claim I have been there (although I have been guilty of it in the past).

<input className='ChannelSettings-field-input mav-800 ' placeholder="country" defaultValue={currentChannel.country} onChange={(event) => setCountry(event.target.value)}/>
*/