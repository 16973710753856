import React from 'react'
import {useState, useEffect, useContext} from 'react';
import firebase from '../../firebase';

import {Link} from 'react-router-dom';
import { AuthContext } from '../Daxil/Auth';
import '../../Styles/Index.css'
import '../../Styles/Blog.css'
import ChapterThumbnail from './ChapterThumbnail';
import BlogProfile from './BlogProfile';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import DeckIcon from '@mui/icons-material/Deck';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    VKShareButton,
    WhatsappShareButton,
  } from "react-share";
  import {
    EmailIcon,
    FacebookIcon,
    InstapaperIcon,
    LinkedinIcon,
    RedditIcon,
    TelegramIcon,
    TwitterIcon,
    VKIcon,
    WhatsappIcon,
  } from "react-share";
import { Helmet } from 'react-helmet';
function DetailedBlog({match}) {

    const currentUser = useContext(AuthContext)
    const [currentUserData, setCurrentUserData] = useState({});
    const [currentChannelData, setCurrentChannelData] = useState({});
    const [blog, setBlog] = useState({});
    const [blocks,setBlocks] = useState([{}]);
    const [countries,setCountries] = useState({});
    const [chapters, setChapters] = useState([]);
    const [channel, setChannel] = useState({});
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("")

    const ref = firebase.firestore().collection('Blogs');
    const ref2 = firebase.firestore().collection('Channels');
    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const firebaseAnalytics = firebase.analytics();
    // TRANSACTIONS -----
    //function addView() {
        const addView = () => {
            var viewRef = ref.doc(match.params.id);
            return firebase.firestore().runTransaction((transaction) => {
                return transaction.get(viewRef).then((viewsDoc) => {
                    if (!viewsDoc.exists) {
                        throw "Document does not exist!";
                    }
                    var oldViews = viewsDoc.data().views
                    if (typeof oldViews === 'undefined')  {
                        oldViews = 0
                    }
                    var newViews = oldViews + 1;
                    transaction.update(viewRef, { views: newViews });
                });
            }).catch((error) => {
                //console.log("Transaction failed: ", error);
            });
            }
        //-----------------
      
    const getBlog = async () => {
        setLoading(true);
        //console.log("...downloading blog");
        var channelUID = "";
        firebaseAnalytics.logEvent('Blogs_Visited');
        ref.doc(match.params.id).get().then((doc) => {
            if (doc.exists) {
                //console.log("BLOG EXISTS")
                setBlog(doc.data());
                channelUID = doc.data().channelUID;
                setStartDate(doc.data().startDate.toDate());
                setEndDate(doc.data().endDate.toDate());
                setCountries(doc.data().countries);
                firebaseAnalytics.logEvent(`Blog_${doc.data().title}_Visited`)
                //channelUID=(doc.data().channelUID);
                /*
                setBlog(doc.data())
                const values = [...doc.data().blocks]
                setBlocks([...values])
                const valuesCountry = doc.data().countries
                setCountries({...valuesCountry})
                
                */
            } else {
                //console.log("No such document!");
            }
        }).then(() => {
            const values = []
            ref.doc(match.params.id).collection("chapters").get().then(querySnapshot => {
              
              querySnapshot.forEach(doc => {
                values.push(doc.data())
      
              })
            }).then(() => {
      
              values.sort((element1, element2) => {
                if (element1.index > element2.index) {
                  return 1
                } else {
                  return -1
                }
              })
      
              setChapters([...values])
              setLoading(false);
            }).catch((error) => {
              //console.log("Error getting document:", error);
          });
      
        }).then(() => {
            //GET CHANNEL INFO
            
            ref2.doc(channelUID).get().then((doc) => {
                if (doc.exists) {
                    const values = doc.data();
                     setChannel({...values})
                } else {
                    //console.log("No such document!");
                }
            }).catch((error) => {
                //console.log("Error getting document:", error);
            });

        }).catch((error) => {
              //console.log("Error getting document:", error);
        });
    }
    useEffect(() => {
        getBlog();
        
        setCurrentChannelData(currentUser.currentChannelData)
        setCurrentUserData(currentUser.currentUserData)
        return () => {
            //to clean up anything you did before
        }
    }, [currentUser]);
    

    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };
    //{currentUser.currentUser ? <h2>Welcome {currentUser.currentUser.displayName}!</h2> : <h2>Welcome!</h2>}
    
    if (loading) {
        return <h1 key="loader">Loading your next adventure...</h1>
    }

    return (
        <div className='DetailedBlog-container'>

        <Helmet>
            <title>{blog.title ? `${blog.title} -` : ""} Adventure Chase </title>
            <meta name='description' content={blog.summary ? `${ReactHtmlParser(blog.summary.slice(0,170))}`: "Adventure Chase Travel Blog" }/>
            <link rel='canonical' href={`/blog/${match.params.id}`}/>
            
            <meta property="og:url" content={`https://www.adventure-chase.com/blog/${match.params.id}`}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content="Adventure Chase"/>
            <meta property="og:description" content="Adventure Chase is an online travel magazine platform which allows travelers and explorers to open their own travel blog."/>
            <meta property="og:image" content={blog ? blog.featureImageURL : "https://www.adventure-chase.com/acShareImage.png"}/>

        
            <meta name="twitter:card" content="summary_large_image"/>
            <meta property="twitter:domain" content="adventure-chase.com"/>
            <meta property="twitter:url" content={`https://www.adventure-chase.com/blog/${match.params.id}`}/>
            <meta name="twitter:title" content="Adventure Chase"/>
            <meta name="twitter:description" content="Adventure Chase is an online travel magazine platform which allows travelers and explorers to open their own travel blog."/>
            <meta name="twitter:image" content={blog.featureImageURL ? blog.featureImageURL : "https://www.adventure-chase.com/acShareImage.png"}/>

        </Helmet>
        <div className='DetailedBlog-sidebar'>
            <p className="DetailedBlog-sidebar-title mav-800 uppercase">Post your next adventure here</p>
            <Link className="DetailedBlog-sidebar-link" to="/contactus">
                <button className='DetailedBlog-sidebar-button uppercase' >Open Channel <AddIcon style={{fontSize: "1rem", fontWeight:"800"}}/></button>
            </Link>
        </div>   

        <div className='DetailedBlog-body'>

            <div className='DetailedBlog-nav-buttons'>
                {<Link to={`/`} className='DetailedBlog-home-button'><p className='DetailedBlog-home-button-text'><HomeIcon /></p></Link>}
                <p></p>
                {currentChannelData ? blog.channelUID == currentChannelData.channelUID ? <Link to={`/createBlog/${blog.blogUID}`} className='DetailedBlog-edit'><p className='DetailedBlog-edit-button alg-800'>Edit</p></Link> :<p></p>:<p></p>}
            </div>
            
            <div className='DetailedBlog-title-container'>
               
                <h2 className="uppercase alg-800 DetailedBlog-title">{blog.title}</h2>
                <Rating style={{padding:0}} name="customized-10" defaultValue={10} max={10} precision={0.5} icon={<StarIcon style={{ color: "#FFB71B" }} fontSize="inherit" />} emptyIcon={<StarIcon style={{ color: "#164734" }} fontSize="inherit" />}/>
                <Tabs style={{width:"100%", }} value={tab} onChange={handleChangeTab}  variant="scrollable" textColor="#990000"  TabIndicatorProps={{style: {backgroundColor: "#333330"}}} scrollButtons="auto" aria-label="scrollable auto tabs example">
                    <Tab style={{marginRight:"20px"}} label={<span style={{ color: '#333330',  }}>Logs</span>} />
                    <Tab style={{marginRight:"20px"}} label={<span style={{ color: '#333330',  }}>Gallery</span>} disabled/>
                    <Tab style={{marginRight:"20px"}} label={<span style={{ color: '#333330',  }}>Budget</span>} disabled/>
                    <Tab style={{marginRight:"20px"}} label={<span style={{ color: '#333330',  }}>Gear</span>} disabled/>
                </Tabs>
            </div>

            <div className='DetailedBlog-blog-profile-container'>
                    <BlogProfile channelUID={channel.channelUID} chapterCount={`${chapters.length}`} cities={blog.cities} finalBudget={blog.finalBudget} credits={blog.credits} expectedBudget={blog.expectedBudget} name={channel.channelName} startDate={startDate} endDate={endDate} photo={channel.photoURL} countries={countries}/>
            </div>

            <div className='DetailedBlog-share-container '>
               {/* <p className='DetailedBlog-share-label alg-800'>SHARE TRIP</p> */}
                <FacebookShareButton className='m-r-10' url={`https://adventure-chase.com/blog/${match.params.id}`} quote={`${blog.title} Looks amazing!`} hashtag="#AdventureChase">
                    <FacebookIcon size={32} borderRadius={10} />
                </FacebookShareButton>
                <TwitterShareButton className='m-r-10' url={`https://adventure-chase.com/blog/${match.params.id}`} title={blog.title}  hashtags={["AdventureChase","Vacation"]} >
                    <TwitterIcon size={32} borderRadius={10}   />
                </TwitterShareButton>
                <WhatsappShareButton className='m-r-10' title="Looks amazing!" url={`https://adventure-chase.com/blog/${match.params.id}`}>
                    <WhatsappIcon size={32} borderRadius={10} />
                </WhatsappShareButton>
                <EmailShareButton className='m-r-10' subject='New Adventure Chase Trip!' url={`https://adventure-chase.com/blog/${match.params.id}`}>
                    <EmailIcon size={32} borderRadius={10}></EmailIcon>
                </EmailShareButton>
                <LinkedinShareButton className='m-r-10' title={blog.title} summary="New Adventure Chase Article is out." source="Adventure-Chase.com" url={`https://adventure-chase.com/blog/${match.params.id}`}>
                    <LinkedinIcon size={32} borderRadius={10} />
                </LinkedinShareButton>
                <RedditShareButton className='m-r-10' title={blog.title} url={`https://adventure-chase.com/blog/${match.params.id}`}>
                    <RedditIcon size={32} borderRadius={10} />
                </RedditShareButton>
                <TelegramShareButton className='m-r-10' title={blog.title} url={`https://adventure-chase.com/blog/${match.params.id}`}>
                    <TelegramIcon size={32} borderRadius={10} />
                </TelegramShareButton>
                <VKShareButton className='m-r-10' title={blog.title} url={`https://adventure-chase.com/blog/${match.params.id}`} image={blog.featureImageURL ? blog.featureImageURL : "https://www.adventure-chase.com/acShareImage.png"}>
                    <VKIcon size={32} borderRadius={10} />
                </VKShareButton>
            </div>
            <p className='alg-800 DetailedBlog-chapters-label'>SUMMARY</p>
            
            <p className="DetailedBlog-summary font-para-300">{ReactHtmlParser(blog.summary)}</p>

            
           
       

            <p className='alg-800 DetailedBlog-chapters-label'>LOGS</p>
            <div className='DetailedBlog-chapters-container'>
            {chapters ?
                chapters.map((chapter, index) => (
                    <Link key={index} className="r-dec colour-bl alg-800  DetailedBlog-chapter-box" to={`/blog/${match.params.id}/${chapter.chapterUID}`}>
                    
                        <ChapterThumbnail chapter={chapter} /> 
                   
                    </Link>
                )) : <div>Failed to load...</div>
            }
            </div>




            </div>
            

        </div>
    )
}

export default DetailedBlog



//{blog.channelUID == currentUser.currentChannelData.channelUID ? <h2>Edit</h2> :<h2></h2>}










/*

{
              Object.entries(countries).map(([key,value], index) => (
                <img src={`/Flags/${value}.png`} style={{"height" : "20px"}} key={index} alt={""}/>
              ))
            }




if (typeof maybeObject != "undefined") {
    alert("This Blog Doesn't exists")


    {blog.blocks.map((block, index) => (
                <div key={index}>
                    <p>{block.text}</p>
                </div>
            ))}
}







.then(() => {
            //GET CHANNEL INFO
            ref.doc(channelUID).get().then((doc) => {
                if (doc.exists) {
                    setChannel(doc.data())
                } else {
                    console.log("No such document!");
                }
            })

          })











*/