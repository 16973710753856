import React from 'react'
import {useState, useEffect} from 'react';
import '../../Styles/Index.css'
import '../../Styles/UploadBlogStyles.css'

import Type1Block from './Type1Block';
import Type2Block from './Type2Block';
import Type3Block from './Type3Block';
import Type4Block from './Type4Block';
import Type5Block from './Type5Block';

import type1Default from '../../Assets/type1Default.png'
import type2Default from '../../Assets/type2Default.png'
import type3Default from '../../Assets/type3Default.png'
import type4Default from '../../Assets/type4Default.png'
import type5Default from '../../Assets/type5Default.png'

import Tooltip from '@mui/material/Tooltip';

function UploadBlocks(props) {

    const [hideBlock,setHideBlock] = useState(true);
    const [blockType, setBlockType] = useState("")
    const [text, setText] = useState(" ")
    const [imageFile, setImageFile] = useState(null);
    const [imageToDisplay, setImageToDisplay] = useState(null)

    useEffect(() => {
        //console.log("Show me the block props", props.block)
        if (props.block.blockType !== "" ) {
            setHideBlock(false);
            setBlockType(props.block.blockType)
            //console.log("🥳", props.block)

        } 
      }, [blockType]);

    const handleImageChange = e => {
        if (e) {
        setImageFile(e)
        setImageToDisplay(URL.createObjectURL(e));
        props.onChangeImage(e)
        } else {
            //console.log("HandleImage Change")
        }
    }
    const handleImageChange2 = e => {
        if (e) {
        //setImageFile(e)
        //setImageToDisplay(URL.createObjectURL(e));
        props.onChangeImage2(e)
        } else {
            //console.log("HandleImage2 Change")
        }
    }
    const handleImageChange3 = e => {
        if (e) {
        props.onChangeImage3(e)
        } else {
            //console.log("HandleImage3 Change")
        }
    }
    const handleImageChange4 = e => {
        if (e) {
        props.onChangeImage4(e)
        } else {
            //console.log("HandleImage4 Change")
        }
    }
    const handleImageChange5 = e => {
        if (e) {
        props.onChangeImage5(e)
        } else {
            //console.log("HandleImage5 Change")
        }
    }

    const handleImageDelete = e => {
        props.onImageDelete="deleted"
        //console.log("Upload Blocks delete triggered")
    }
    function clickHandler(){
        //console.log('Button Clicked',props)
    }
    const typeClickHandler = e => {
        e.preventDefault();
        setHideBlock(false)
        
        switch (Number(e.target.id)) {
            case 1:
                props.onClick2("type1")
                setBlockType("type1")
                break;
            case 2:
                props.onClick2("type2")
                setBlockType("type2")
                break;
            case 3:
                props.onClick2("type3")
                setBlockType("type3")
                break;
            case 4:
                props.onClick2("type4")
                setBlockType("type4")
                break;
            case 5:
                props.onClick2("type5")
                setBlockType("type5")
                break;
            default:
            console.log(`Sorry, not a valid click. Refresh the page`);
        }

    }

        if (hideBlock == false) {
            //console.log('hide block',hideBlock)
           switch (props.block.blockType) {
            case "type1":
                //console.log("type 1 block")
                return (            
                    <Type1Block id={props.myIndex} 
                        myIndex={props.myIndex}
                        text={props.block.text}
                        title={props.block.title}
                        onChange={(event) => props.onChange(event)} 
                        onClick={() => props.onClick(props.myIndex)}
                        col={props.block.col}
                        onColChange={(value) => props.onColChange(value)}
                        onTitleChange={(event) => props.onTitleChange(event)} 
                        onIncrement={(value) => props.onIncrement(value)}
                        highlighted={props.block.highlighted}
                        onHighlightChange={(value) => props.onHighlightChange(value)}
                    />
                )
                break;
            case "type2":
                return (            
                        <Type2Block id={props.myIndex} 
                        myIndex={props.myIndex}
                        text={props.block.text}
                        title={props.block.title}
                        photoUrl={props.block.photoUrl}
                        onChange={(event) => props.onChange(event)} 
                        onChangeImage={(value) => handleImageChange(value)}
                        onClick={() => props.onClick(props.myIndex)}
                        onImageDelete={props.onImageDelete}
                        size={props.block.size}
                        position={props.block.position}
                        col={props.block.col}
                        onSizeChange={(value) => props.onSizeChange(value)}
                        onPositionChange={(value) => props.onPositionChange(value)}
                        onColChange={(value) => props.onColChange(value)}
                        onTitleChange={(event) => props.onTitleChange(event)} 
                        onIncrement={(value) => props.onIncrement(value)}
                        highlighted={props.block.highlighted}
                        onHighlightChange={(value) => props.onHighlightChange(value)}
                        />
                )
                break;
            case "type3":
                return (            
                    
                    <Type3Block id={props.myIndex} 
                    myIndex={props.myIndex}
                    onClick={() => props.onClick(props.myIndex)}
    
                    title={props.block.title}
                    onTitleChange={(event) => props.onTitleChange(event)} 
                    onChangeImage={(value) => handleImageChange(value)}
                    onImageDelete={props.onImageDelete}
                    photoUrl={props.block.photoUrl}

                    title2={props.block.title2}
                    onTitleChange2={(event) => props.onTitleChange2(event)} 
                    onChangeImage2={(value) => handleImageChange2(value)}
                    onImageDelete2={props.onImageDelete2}
                    onIncrement={(value) => props.onIncrement(value)}
                    photoUrl2={props.block.photoUrl2}
                    highlighted={props.block.highlighted}
                    onHighlightChange={(value) => props.onHighlightChange(value)}
                    />
                )
                break;
            case "type4":
                return (            
                   
                    <Type4Block id={props.myIndex} 
                    myIndex={props.myIndex}
                    onClick={() => props.onClick(props.myIndex)}
                    title={props.block.title}
                    photoUrl={props.block.photoUrl}
                    onTitleChange={(event) => props.onTitleChange(event)} 
                    onChangeImage={(value) => handleImageChange(value)}
                    onImageDelete={props.onImageDelete}
                    onIncrement={(value) => props.onIncrement(value)}
                    highlighted={props.block.highlighted}
                    onHighlightChange={(value) => props.onHighlightChange(value)}
                    />
                )
                break;
            case "type5":
                return (            
                    <Type5Block id={props.myIndex} 
                    myIndex={props.myIndex}
                    onClick={() => props.onClick(props.myIndex)}
    
                    title={props.block.title}
                    onTitleChange={(event) => props.onTitleChange(event)} 
                    onChangeImage={(value) => handleImageChange(value)}
                    onImageDelete={props.onImageDelete}
                    photoUrl={props.block.photoUrl}
    
                    onChangeImage2={(value) => handleImageChange2(value)}
                    onImageDelete2={props.onImageDelete2}
                    photoUrl2={props.block.photoUrl2}
    
                    onChangeImage3={(value) => handleImageChange3(value)}
                    onImageDelete3={props.onImageDelete3}
                    photoUrl3={props.block.photoUrl3}
    
                    onChangeImage4={(value) => handleImageChange4(value)}
                    onImageDelete4={props.onImageDelete4}
                    photoUrl4={props.block.photoUrl4}
    
                    onChangeImage5={(value) => handleImageChange5(value)}
                    onImageDelete5={props.onImageDelete5}
                    photoUrl5={props.block.photoUrl5}
                    onIncrement={(value) => props.onIncrement(value)}
                    highlighted={props.block.highlighted}
                    onHighlightChange={(value) => props.onHighlightChange(value)}
                    />
                )
                break;
            default:
            console.log(`Sorry, not a valid click. Refresh the page`);
            }
           
            
         } else {
           // console.log('I have to hide the block',hideBlock)
         }
    

    

    

    return (
        <div>
         {hideBlock == true ? 
                <div className="blockCard  m-t-20 m-b-20">
                    <h3 className="UploadBlock-title mav-800">Select Block Style</h3>
                    <ul className="types">
                        <li>
                            <Tooltip title="Text">
                            <img className="UploadBlocks-defaultImage-button" id={1} onClick={e => typeClickHandler(e)} src={type1Default} alt="Type 1"/>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title="Image &amp; Text">
                            <img className="UploadBlocks-defaultImage-button" id={2} onClick={e => typeClickHandler(e)} src={type2Default} alt="Type 2"/>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title="2 Images">
                            <img className="UploadBlocks-defaultImage-button" id={3} onClick={e => typeClickHandler(e)} src={type3Default} alt="Type 3"/>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title="1 Image">
                            <img className="UploadBlocks-defaultImage-button" id={4} onClick={e => typeClickHandler(e)} src={type4Default} alt="Type 4"/>
                            </Tooltip>
                        </li>
                        <li>
                            <Tooltip title="5 Image">
                            <img className="UploadBlocks-defaultImage-button" id={5} onClick={e => typeClickHandler(e)} src={type5Default} alt="Type 5"/>
                            </Tooltip>
                        </li>
                    </ul> 

                </div>
            : 
            
            
                <h1>hello default hideblock = false {props.block.blockType}</h1>
            
            
            
            
            
            }
        
        
            
            
        </div>
    )
    
}

UploadBlocks.defaultProps = {
    text: "",
    size:"small",
    highlighted:false,
    col:"col1",
    title:"",
    title2:"",
    position:"left",
    index: 0,
    blockType:"",
    block: {},
    onClick: console.log('upload blocks onClick failed'),
    onClick2: console.log('upload blocks onClick2 failed'),
    onChange: console.log('upload blocks onChangeText failed'),
    onChangeImage: console.log('upload blocks onChangeImage failed'),
    onChangeImage2: console.log('upload blocks onChangeImage failed'),

    onSizeChange: console.log('upload blocks onSizeChange failed'),
    onPositionChange: console.log('upload blocks onPositionChange failed'),
    onColChange: console.log('upload blocks onColChange failed'),
    onTitleChange: console.log('upload blocks onTitleChange failed'),
    onImageDelete: console.log('Image Delete onImageDelete failed'),
    onTitleChange2: console.log('upload blocks onTitleChange failed'),
    onImageDelete2: console.log('Image Delete onImageDelete failed'),

    onChangeImage3: console.log('3upload blocks onChangeImage failed'),
    onImageDelete3: console.log('3Image Delete onImageDelete failed'),

    onChangeImage4: console.log('4upload blocks onChangeImage failed'),
    onImageDelete4: console.log('4Image Delete onImageDelete failed'),

    onChangeImage5: console.log('5upload blocks onChangeImage failed'),
    onImageDelete5: console.log('5Image Delete onImageDelete failed'),

    onIncrement: console.log('onIncrement failed'),
    onHighlightChange: console.log('onHighlight Failed')


}

export default UploadBlocks






//reference
//<button  onClick={() => props.onClick2("type5")} type="submit"> Type 5 </button>
//<button  onClick={clickHandler} type="submit"> Type 5 </button>
//<button id={2} onClick={e => typeClickHandler(e)} type="submit"> Type 2 </button>

/*type 1 
<div className="blockCard m-t-20 m-b-20">
                <span className="top ">
                <input className="titleInput" placeholder="Title (optional)"/>
                <MuiColumn/>
                </span>
                <span className="bottom ">
                <textarea className="type1Text" onChange={(event) => props.onChange(event.target.value)} placeholder="paragraph" defaultValue={props.block.text}></textarea>
                
                
                </span>



                <span className="test test-gray">
                <p>{props.block.text}</p>
                <button  onClick={() => props.onClick(props.myIndex)} type="submit"> - </button>
                <button  onClick={clickHandler} type="submit"> ? </button>
                </span>
                </div>

*/