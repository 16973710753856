import React from 'react'
import '../../Styles/Index.css'
import AllCountries from '../../firebaseFunctions/flagList'
import '../../Styles/CountryPicker.css'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
function CountryPicker(props) {


    const countrySelected = (e) => {
        
        let selectedCountry = [[e.target.value.split(',')[0],e.target.value.split(',')[1] ]]
        //console.log(selectedCountry)
        if (e.target.value.split(',')[0] != "Select Country") {
            props.onClick(selectedCountry);
        }
    }
    return (
        <div className="CountryPicker-container">
            <select className="CountryPicker "  onChange={(event) => {countrySelected(event)}}>
            <option className="CountryPicker-options" default>{props.defaultCountry ? props.defaultCountry : "Select Country"}</option>
            {Object.entries(AllCountries).map((item, index) => (

                <option className="CountryPicker-options" value={[item[0],item[1]]} key={index}>
                    {item[0]}
                </option>
            ))}
            </select>
            <ArrowDownwardIcon />
        </div>
    )
}

CountryPicker.defaultProps = {
    onClick: console.log('select country onclick failed'),
}

export default CountryPicker
