import React from 'react'
import MuiColumn from '../Tools/MuiColumn';
import {useState} from 'react';
import '../../Styles/Index.css'
import '../../Styles/UploadBlogStyles.css'
import imageBtn from './Images/imageBtn.png'
import { TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import imageCompression from 'browser-image-compression';
//import { getHTMLTextFromNormalText } from '../Tools/getHtmlTextFromNormalText';
//import HtmlParser from 'react-html-parser';

import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

function Type2Block(props) {
    const [imageFile, setImageFile] = useState(null);
    const [imageToDisplay, setImageToDisplay] = useState(props.photoUrl);
    const [selectedSize, setSelectedSize] = React.useState(props.size);
    const [position, setPosition] = React.useState(props.position);
    const [testCss, setTestCss] = useState('test-red');
    const [wordCount, setWordCount] = useState(() => props.text ? props.text.split(" ").length : 0);
    const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        maxIteration: 1,  
        useWebWorker: true
    }
    const handleSizeChange = (event) => {
        setSelectedSize(event.target.value);
        props.onSizeChange(event.target.value);
    };

    const handlePositionChange = (event) => {
        setPosition(event.target.value);
        props.onPositionChange(event.target.value);
    };

    async function handleImageChange(e) { 
        //console.log("HandleImage Change")
        if (e.target.files[0]) {
            try {
                const compressedFile = await imageCompression(e.target.files[0], options);
                setImageFile(compressedFile)
                setImageToDisplay(URL.createObjectURL(compressedFile));
                props.onChangeImage(compressedFile)
            } catch (error) {console.log(error)}
        } else {
            //console.log("HandleImage Change fail")
        }
    }
    const handleDeleteImage = e => {
        setImageToDisplay(null)
        props.onImageDelete();
        //.log("type 2 delete triggered")
    }
    const [highlighted, setHighlighted] = React.useState(() => props.highlighted ? props.highlighted : "no");
    const handleHighlightChange = (event) => {
        setHighlighted(event.target.value);
        props.onHighlightChange(event.target.value);
    };
    return (
        <div className="Type2-upload-container">
            <div className="Type2-upload-top-container">
                <input className="titleInput" placeholder="Title (optional)" defaultValue={props.title} onChange={(event) => props.onTitleChange(event.target.value)}/>
                <MuiColumn onColChange={(event) => props.onColChange(event)} col={props.col}/>
            </div>


            <div className="Type2-upload-body-container">
                <div className="Type2-imageContainer">
                    <div className="Type2-imagePreview">
                        <input type="file" id={props.myIndex} onChange={handleImageChange} accept="image/*"/>
                        <label for={props.myIndex} className="Type2-uploadImageLabel"><img src={imageToDisplay ? imageToDisplay : imageBtn} alt="type2" className="Type2-img"/></label>
                        
                    </div>
                    <div className="Type2-imageTools">
                        
                        <div className="m-t-5">
                            <Radio
                                checked={selectedSize === 'small'}
                                onChange={handleSizeChange}
                                value="small"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'small' }}
                                sx={{
                                    paddingTop:'0px',
                                    paddingBottom: '3px'
                                }}
                            />
                            <label className='Type2-radio-labels uppercase m-0 p-0'>Small</label>
                            <Radio
                                checked={selectedSize === 'big'}
                                onChange={handleSizeChange}
                                value="big"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'big' }}
                                sx={{
                                    color: '#990000',
                                    paddingTop:'0px',
                                    paddingBottom: '3px',
                                    marginLeft:'10px',
                                    '&.Mui-checked': {
                                    color: '#990000',
                                    },
                                }}
                            />
                            <label className='Type2-radio-labels uppercase m-l-5 p-0'>Big</label>
                        </div>
                        <div className="m-t-5">
                            <Radio
                                checked={position === 'left'}
                                onChange={handlePositionChange}
                                value="left"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'left' }}
                                sx={{
                                    color: '#164734',
                                    paddingTop:'0px',
                                    paddingBottom: '3px',
                                    '&.Mui-checked': {
                                        color: '#164734',
                                    },
                                }}
                            />
                            <label className='Type2-radio-labels uppercase m-0 p-0'>Left</label>
                            <Radio
                                checked={position === 'right'}
                                onChange={handlePositionChange}
                                value="right"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'right' }}
                                sx={{
                                    color: '#ffb71b',
                                    paddingTop:'0px',
                                    paddingBottom: '3px',
                                    marginLeft:'10px',
                                    '&.Mui-checked': {
                                    color: '#ffb71b',
                                    },
                                }}
                            />
                            <label className='Type2-radio-labels uppercase m-l-5 p-0'>Right</label>
                        </div>
                        {imageToDisplay ? <button className="Type2-deleteImage-button mav-800" onClick={(event) => handleDeleteImage(event)} >x</button> : <div/>}
                    </div>
                </div>


                <div className="Type2-textContainer m-0">
                    <TextField
                        id="standard-textarea"
                        label=""
                        placeholder="type here"
                        fullWidth
                        minRows={13}
                        InputProps={{ disableUnderline: true, style:{marginLeft:'10px',padding:"10px", border:"solid", borderWidth:'1px', borderColor:'#eee', borderRadius:'10px'} }}
                        multiline
                        defaultValue={props.text}
                        onChange={(event) => { setWordCount(event.target.value.split(" ").length);  props.onChange(event.target.value)}}
                        variant="standard"
                    />
                    
                </div>
                
            </div>

            <div className="Type2-highlighted-container m-t-5">
                            <p className='Type2-highlighted-text mav-800'>Highlighed?</p>
                            <Radio
                                checked={highlighted === "no"}
                                onChange={handleHighlightChange}
                                value="no"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'No' }}
                                sx={{
                                    paddingTop:'0px',
                                    paddingBottom: '3px'
                                }}
                            />
                            <label className='font-weight-700 uppercase m-0 p-0'>No</label>
                            <Radio
                                checked={highlighted === "yes"}
                                onChange={handleHighlightChange}
                                value="yes"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Yes' }}
                                sx={{
                                    color: '#990000',
                                    paddingTop:'0px',
                                    paddingBottom: '3px',
                                    marginLeft:'10px',
                                    '&.Mui-checked': {
                                    color: '#990000',
                                    },
                                }}
                            />
                            <label className='font-weight-700 uppercase m-l-5 p-0'>Yes</label>
            </div>
            <p className='Type2-upload-word-counter mav-800'>Word Count: {wordCount} / Recommended max 400 words</p>
            <div className="Type2-upload-tools-container">
                <button  onClick={() => props.onClick(props.myIndex)} type="submit" className='Type2-upload-tools-button delete'><DeleteOutlineIcon style={{fontWeight:"800",fontSize:"1.6rem"}}/></button>
                <button onClick={() => props.onIncrement(props.myIndex)} className='Type2-upload-tools-button'><AddIcon style={{fontWeight:"800",fontSize:"2.0rem"}}/></button>
            </div>
        </div>
    )
}

Type2Block.defaultProps = {
    myIndex:0,
    size:"small",
    position:"left",
    text: "",
    col:"col1",
    title:"",
    photoUrl: null,
    onClick: console.log('upload blocks onclick failed'),
    //onClick2: console.log('upload blocks onclick failed'),
    onChange: console.log('upload blocks onChangeText failed'),
    onChangeImage: console.log('upload blocks onChangeImage failed'),
    onSizeChange: console.log('upload blocks onSizeChange failed'),
    onPositionChange: console.log('upload blocks onPositionChange failed'),
    onColChange: console.log('upload blocks onColChange failed'),
    onTitleChange: console.log('upload blocks onTitleChange failed'),
    onImageDelete: console.log('Image Delete onImageDelete failed'),
    onIncrement: console.log('onIncrement failed'),
    onHighlightChange: console.log('onHighlight Failed')
}

export default Type2Block










/*

<textarea className="type2Text" onChange={(event) => props.onChange(event.target.value)} placeholder="paragraph" defaultValue={props.text}></textarea>

<p className="text-l" dangerouslySetInnerHTML={{ __html: getHTMLTextFromNormalText(props.text) }} />
*/