import React, { useEffect, useState } from 'react'
import firebase from "firebase";

export const AuthContext = React.createContext();

export const AuthProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [currentUserData, setCurrentUserData] = useState(null);
    const [currentChannelData, setCurrentChannelData] = useState(null);

    const ref = firebase.firestore().collection("Users");
    const ref2 = firebase.firestore().collection("Channels");
    useEffect(() => {
        
        firebase.auth().onAuthStateChanged((res) => {
            console.log("...Auth changed state");    
            if (res) {
                console.log("...there is a res");  
                ref.doc(`${res.displayName.replace(/ /g,'-')}-${res.uid}`).get().then((doc) => {
                    console.log("...looking for user data"); 
                    if (doc.exists) {
                        console.log("...found user data"); 
                        setCurrentUserData(doc.data())
                        ref2.doc(doc.data().channelUID).get().then((doc2) => {
                            if (doc2.exists) {
                                //console.log("ChannelData from AUth:",doc2.data());
                                setCurrentChannelData(doc2.data())
                    
                            } else {
                                console.log("No such Channel!");
                            }
                        }).catch((error) => {
                            console.log("Error getting Channel:", error);
                        });
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("...No such user!");
                    }
                }).catch((error) => {
                    console.log("Error getting user:", error);
                });
                
            } else {console.log("...there is no res");  }
            setCurrentUser(res)
        });
        
        
    },[]);

    return (
        <AuthContext.Provider value={{currentUser, currentUserData, currentChannelData}}>
            {children}
        </AuthContext.Provider>
    )
}


