import React from 'react'
import {useState} from 'react';
import '../../Styles/Index.css'
import '../../Styles/UploadBlogStyles.css'
import imageBtn from './Images/imageBtn.png'
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import imageCompression from 'browser-image-compression';
import Radio from '@mui/material/Radio';
function Type5Block(props) {
    const [imageToDisplay, setImageToDisplay] = useState(props.photoUrl)
    const [imageToDisplay2, setImageToDisplay2] = useState(props.photoUrl2)
    const [imageToDisplay3, setImageToDisplay3] = useState(props.photoUrl3)
    const [imageToDisplay4, setImageToDisplay4] = useState(props.photoUrl4)
    const [imageToDisplay5, setImageToDisplay5] = useState(props.photoUrl5)
    const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 1920,
        maxIteration: 1,  
        useWebWorker: true
    }
    async function handleImageChange(e) { 
    //const handleImageChange = e => {
        //console.log("HandleImage Change")
        if (e.target.files[0]) {
        try {
        const compressedFile = await imageCompression(e.target.files[0], options);
        setImageToDisplay(URL.createObjectURL(compressedFile));
        props.onChangeImage(compressedFile)
        } catch (error){console.log(error)}
        } else {
            //console.log("HandleImage Change fail")
        }
    }
    const handleDeleteImage = e => {
        setImageToDisplay(null)
        props.onImageDelete();
        //console.log("type 3 delete triggered")
    }
    async function handleImageChange2(e) { 
    //const handleImageChange2 = e => {
        //console.log("HandleImage Change")
        if (e.target.files[0]) {
        try {
        const compressedFile = await imageCompression(e.target.files[0], options);
        setImageToDisplay2(URL.createObjectURL(compressedFile));
        props.onChangeImage2(compressedFile)
        } catch (error){console.log(error)}
        } else {
            //.log("HandleImage2 Change fail")
        }
    }
    const handleDeleteImage2 = e => {
        setImageToDisplay2(null)
        props.onImageDelete2();
       // console.log("type 3 delete2 triggered")
    }
    async function handleImageChange3(e) { 
    //const handleImageChange3 = e => {
       // console.log("HandleImage Change")
        if (e.target.files[0]) {
        try {
        const compressedFile = await imageCompression(e.target.files[0], options);
        setImageToDisplay3(URL.createObjectURL(compressedFile));
        props.onChangeImage3(compressedFile)
        } catch (error){console.log(error)}
        } else {
            //console.log("HandleImage3 Change fail")
        }
    }
    const handleDeleteImage3 = e => {
        setImageToDisplay3(null)
        props.onImageDelete3();
       // console.log("type 3 delete3 triggered")
    }
    async function handleImageChange4(e) { 
    //const handleImageChange4 = e => {
        //console.log("HandleImage Change")
        if (e.target.files[0]) {
        try {
        const compressedFile = await imageCompression(e.target.files[0], options);
        setImageToDisplay4(URL.createObjectURL(compressedFile));
        props.onChangeImage4(compressedFile)
    } catch (error){console.log(error)}
        } else {
           // console.log("HandleImage4 Change fail")
        }
    }
    const handleDeleteImage4 = e => {
        setImageToDisplay4(null)
        props.onImageDelete4();
        //console.log("type 3 delete4 triggered")
    }

    async function handleImageChange5(e) { 
    //const handleImageChange5 = e => {
        //console.log("HandleImage Change")
        if (e.target.files[0]) {
        try {
        const compressedFile = await imageCompression(e.target.files[0], options);
        setImageToDisplay5(URL.createObjectURL(compressedFile));
        props.onChangeImage5(compressedFile)
        } catch (error){console.log(error)}
        } else {
            //console.log("HandleImage5 Change fail")
        }
    }
    const handleDeleteImage5 = e => {
        setImageToDisplay5(null)
        props.onImageDelete5();
        //console.log("type 3 delete5 triggered")
    }
    const [highlighted, setHighlighted] = React.useState(() => props.highlighted ? props.highlighted : "no");
    const handleHighlightChange = (event) => {
        setHighlighted(event.target.value);
        props.onHighlightChange(event.target.value);
    };

    return (
        <div className="Type5-upload-container">
            
            <div className="Type5-upload-top-container">
                <input className="titleInput" placeholder="Title (optional)" defaultValue={props.title} onChange={(event) => props.onTitleChange(event.target.value)}/>
            </div>

            <span className="Type5-body-container">
                <div className="group"> 
                    <div className="colo">
                        <div className="">
                            <div className="imageContainer">
                                    <div className="imagePreview">
                                        <input type="file" id={props.myIndex} onChange={handleImageChange} accept="image/*"/>
                                        <label for={props.myIndex} className="uploadImageLabel"><img src={imageToDisplay ? imageToDisplay : imageBtn} alt="type51" className="img"/></label>
                                        
                                    </div>
                            </div>
                        </div>
                        

                        <div className="">
                            <div className="imageContainer">
                                    <div className="imagePreview">
                                        <input type="file" id={`${props.myIndex}2`} onChange={handleImageChange2} accept="image/*"/>
                                        <label for={`${props.myIndex}2`} className="uploadImageLabel"><img src={imageToDisplay2 ? imageToDisplay2 : imageBtn} alt="type52" className="img"/></label>
                                    </div>
                            </div>
                        </div>
                    </div>
                    <div className="colo2">
                        <div className="">
                            <div className="imageContainer">
                                    <div className="imagePreview">
                                        <input type="file" id={`${props.myIndex}3`} onChange={handleImageChange3} accept="image/*"/>
                                        <label for={`${props.myIndex}3`} className="uploadImageLabel"><img src={imageToDisplay3 ? imageToDisplay3 : imageBtn} alt="type53" className="img"/></label>
                                        
                                    </div>
                            </div>
                        </div>
                        
                        <div className="">
                            <div className="imageContainer">
                                    <div className="imagePreview">
                                        <input type="file" id={`${props.myIndex}4`} onChange={handleImageChange4} accept="image/*"/>
                                        <label for={`${props.myIndex}4`} className="uploadImageLabel"><img src={imageToDisplay4 ? imageToDisplay4 : imageBtn} alt="type54" className="img"/></label>
                                        
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="side"> 
                    <div className="colo"> 
                        <div className="imageContainer">
                            <div className="imagePreview">
                                <input type="file" id={`${props.myIndex}5`} onChange={handleImageChange5} accept="image/*"/>
                                <label for={`${props.myIndex}5`} className="uploadImageLabel"><img src={imageToDisplay5 ? imageToDisplay5 : imageBtn} alt="type31" className="img"/></label>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </span>
            <div className="Type5-highlighted-container m-t-5">
                            <p className='Type5-highlighted-text mav-800'>Highlighed?</p>
                            <Radio
                                checked={highlighted === "no"}
                                onChange={handleHighlightChange}
                                value="no"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'No' }}
                                sx={{
                                    paddingTop:'0px',
                                    paddingBottom: '3px'
                                }}
                            />
                            <label className='font-weight-700 uppercase m-0 p-0'>No</label>
                            <Radio
                                checked={highlighted === "yes"}
                                onChange={handleHighlightChange}
                                value="yes"
                                name="radio-buttons"
                                inputProps={{ 'aria-label': 'Yes' }}
                                sx={{
                                    color: '#990000',
                                    paddingTop:'0px',
                                    paddingBottom: '3px',
                                    marginLeft:'10px',
                                    '&.Mui-checked': {
                                    color: '#990000',
                                    },
                                }}
                            />
                            <label className='font-weight-700 uppercase m-l-5 p-0'>Yes</label>
                </div>
            <div className="Type5-upload-tools-container">
                <button  onClick={() => props.onClick(props.myIndex)} type="submit" className='Type5-upload-tools-button delete'><DeleteOutlineIcon style={{fontWeight:"800",fontSize:"1.6rem"}}/></button>
                <button onClick={() => props.onIncrement(props.myIndex)} className='Type5-upload-tools-button'><AddIcon style={{fontWeight:"800",fontSize:"2.0rem"}}/></button>
            </div>
        </div>
    )
}

Type5Block.defaultProps = {
    myIndex:0,
    size:"small",
    title:"",
    onClick: console.log('upload blocks onclick failed'),
    onChange: console.log('upload blocks onChangeText failed'),


    onImageDelete: console.log('Image Delete onImageDelete failed'),
    onChangeImage: console.log('upload blocks onChangeImage failed'),
    photoUrl:null,

    onChangeImage2: console.log('2upload blocks onChangeImage failed'),
    onImageDelete2: console.log('2Image Delete onImageDelete failed'),
    photoUrl2:null,

    onChangeImage3: console.log('3upload blocks onChangeImage failed'),
    onImageDelete3: console.log('4Image Delete onImageDelete failed'),
    photoUrl3:null,

    onChangeImage4: console.log('4upload blocks onChangeImage failed'),
    onImageDelete4: console.log('4Image Delete onImageDelete failed'),
    photoUrl4:null,

    onChangeImage5: console.log('5upload blocks onChangeImage failed'),
    onImageDelete5: console.log('5Image Delete onImageDelete failed'),
    onIncrement: console.log('onIncrement failed'),
    photoUrl5:null,
}

export default Type5Block

