import React from 'react'
import { Link } from 'react-router-dom'
import '../../Styles/Index.css'
import '../../Styles/Footer.css'
function Footer() {
    return (
        <div className="footer-container">
            <p className="footer-logo alg-800 colour-se">adventure chase</p>
            <div className='footer-links-container'>
                <Link className="footer-link" to="/">
                    <p>Home</p>
                </Link>
                <Link className="footer-link" to="/">
                    <p>Covers</p>
                </Link>
                <Link className="footer-link" to="/contactus">
                    <p>Contact</p>
                </Link>
            </div>
        </div>
    )
}

export default Footer

/*
<Link className="footer-link" to="/triplist">
    <p>Trips</p>
</Link>
                
*/