import React from 'react'
import '../../Styles/Index.css'
import '../../Styles/Blog.css'

function Type3(props) {
    const block = props.block
    return (
        <>
        { block.highlighted === "yes" ?
        <div className='type3-highlighted'>
            <div className='type3-image-container'>
                <img className='type3-image' src={block.photoUrl} alt=""/>
                <p className='alg-800 font-para-700 type3-title'>{block.title}</p>
            </div>
            <div className='type3-image-container'>
                <img className='type3-image' src={block.photoUrl2} alt=""/>
                <p className='alg-800 font-para-700 type3-title'>{block.title2}</p>
            </div>
        </div>
        :
        <div className='type3'>
            <div className='type3-image-container'>
                <img className='type3-image' src={block.photoUrl} alt=""/>
                <p className='alg-800 font-para-700 type3-title'>{block.title}</p>
            </div>
            <div className='type3-image-container'>
                <img className='type3-image' src={block.photoUrl2} alt=""/>
                <p className='alg-800 font-para-700 type3-title'>{block.title2}</p>
            </div>
        </div>
        
        }
        </>
    )
}

Type3.defaultProps = {
    title:"",
    title2:"",
}
export default Type3
