import React from 'react'
import {useState, useEffect, useContext} from 'react';
import firebase from '../../firebase';
//import { useNavigate } from "react-router-dom";
import { useHistory } from 'react-router-dom';
//import {Link} from 'react-router-dom';
import { AuthContext } from '../Daxil/Auth';
import { v4 as uuidv4 } from 'uuid';
import CustomDatePicker from '../Tools/CustomDatePicker';
import CountryTag from '../Tools/CountryTag';
//import DropDown from '../Tools/DropDown';
//import AllCountries from '../../firebaseFunctions/flagList'
import '../../Styles/Index.css'
import '../../Styles/UploadBlogStyles.css'
import UploadChapter from './UploadChapter';
import UploadBudget from './UnloadBudget';
import imageBtn from './Images/imageBtn.png'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import { ListItem, TextField } from '@mui/material';
//import CountryAutoComplete from '../Tools/CountryAutoComplete';
import CountryPicker from '../Tools/CountryPicker';
//import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
//import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
//import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { getHTMLTextFromNormalText } from '../Tools/getHtmlTextFromNormalText';
//import { Unpublished } from '@mui/icons-material';
import { Helmet } from 'react-helmet';
import imageCompression from 'browser-image-compression';

function UploadBlog({match}) {

  const currentUser = useContext(AuthContext);
  const [currentChannel,setCurrentChannel] = useState();
  const [currentUserData, setCurrentUserData] = useState({});
  const [currentChannelData, setCurrentChannelData] = useState({channelUID:"0-0"});
  const [downloadedBlogUID,setDownloadedBlogUID] = useState(() => match.params.id ? match.params.id : "");
  const [downloadedBlog,setDownloadedBlog] = useState({});
  //upload stuff------------------------
  const [name,setName] = useState("");
  const [des,setDes] = useState("");

  let firstTimeStamp = Date.now().toString()
  //const navigate = useNavigate();
  const history = useHistory();
  //, setChapters] = useState([])
  
  const [finalStartDate, setFinalStartDate] = useState(new Date());
  const [finalEndDate, setFinalEndDate] = useState(new Date());
  const [countries, setCountries] = useState({});
  const [cities,setCities] = useState("");
  const [imagesToUpload, setImagesToUpload] = useState({});
  const ref = firebase.firestore().collection("Blogs");
  const ref2 = firebase.firestore().collection("Channels");
  const storageRef = firebase.storage()

  const channelUID = "Channel-AdventureChase-1629209677488";
  const [finalBlogUID,setFinalBlogUID] = useState("");
  const [blockThis,setBlockThis] = useState(false);
  const [publish,setPublish] = useState(true);
  const [createdOn, setCreatedOn] = useState(Date(Date.now()).toString());

  const [imageToDisplay, setImageToDisplay] = useState(null);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const chapter1UID = `Chapter-${uuidv4().split("-")[0]}`;
  const [chapters, setChapters] = useState([{chapter:"chapter1", chapterUID:chapter1UID}]);

  const [budget, setBudget] = useState({});
  const [completed, setCompleted] = useState(0);

  const [titleCompleted, setTitleCompleted] = useState(false);
  const [countriesCompleted, setCountriesCompleted] = useState(false);
  const [citiesCompleted, setCitiesCompleted] = useState(false);
  const [featureImageCompleted, setFeatureImageCompleted] = useState(false);
  const [dateCompleted, setDateCompleted] = useState(false);
  const [summaryCompleted, setSummaryCompleted] = useState(false);
  const [chapterCompleted, setChapterCompleted] = useState(false);
  const [budgetCompleted, setBudgetCompleted] = useState(false);
  const [realPerson, setRealPerson] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const firebaseAnalytics = firebase.analytics();

  const options = {
    maxSizeMB: 2,
    maxWidthOrHeight: 1920,
    maxIteration: 1,  
    useWebWorker: true
  }


  useEffect(() => {

    if (currentUser != null ) {
      
      var cuid = "uid-cuid";
      if (currentUser.currentChannelData) {
        cuid = currentUser.currentChannelData.channelUID
      }
      //console.log("😎cuurent channel uid: ",cuid)

        if (cuid.split("-")[1] === downloadedBlogUID.split("-")[1]) {
          getDownloadedBlog();
          setRealPerson(true)
          
        } else {
          //<Link to="/signin"></Link>
        }
    }
    
    setCurrentChannelData(currentUser.currentChannelData)
    setCurrentUserData(currentUser.currentUserData)
    return () => {
        //to clean up anything you did before
    }
    
  }, [downloadedBlogUID,currentUser]);

  useEffect(() => {
    //console.log("chapetsrs in use effect",chapters)
    calculateCompleted();
  }, [chapters,titleCompleted, countriesCompleted, citiesCompleted,featureImageCompleted,dateCompleted,summaryCompleted,chapterCompleted,budgetCompleted]);

  useEffect(() => {
    firebaseAnalytics.logEvent('UploadBlog_Visited');
    return () => {
        //to clean up anything you did before
    }
  }, []);
  function calculateCompleted() {
    let arr = [titleCompleted, countriesCompleted, citiesCompleted,featureImageCompleted,dateCompleted,summaryCompleted]
    setCompleted(Math.round(arr.filter(Boolean).length * 16.6))
  }

const getDownloadedBlog = async () => {
    //console.log("...downloading blog");
    
    ref.doc(match.params.id).get().then((doc) => {
        if (doc.exists) {
            //console.log("Blog to edit", doc.data());
            setDownloadedBlog(doc.data());
            setName(doc.data().title);
            setTitleCompleted(true);
            setFinalBlogUID(doc.data().blogUID);
            setImageToDisplay(doc.data().featureImageURL);
            setFeatureImageCompleted(true);
            setDes(doc.data().summary);
            setSummaryCompleted(true);
            //setStartDate(LocalDate.parse(doc.data().startDate));
            //setEndDate(LocalDate.parse(doc.data().endDate));
            setFinalStartDate(doc.data().startDate);
            setFinalEndDate(doc.data().endDate);
            setDateCompleted(true);
            //setStartDate(doc.data().startDate);
            //setEndDate(doc.data().endDate);
            setCountries(doc.data().countries);
            setCountriesCompleted(true);
            setCities(doc.data().cities);
            setCitiesCompleted(true);
            setPublish(doc.data().publish);
            setBlockThis(doc.data().block);
            setCreatedOn(doc.data().createdOn);
            if (doc.data().budget) {setBudget(doc.data().budget)};
        } else {
            //console.log("No such document!");
        }
    }).then(() => {
      const values = []
      ref.doc(match.params.id).collection("chapters").get().then(querySnapshot => {
        
        querySnapshot.forEach(doc => {
          values.push(doc.data())

        })
      }).then(() => {

        values.sort((element1, element2) => {
          if (element1.index > element2.index) {
            return 1
          } else {
            return -1
          }
        })

        setChapters([...values])
        if(!values) {
          setChapters([{chapter:"chapter1", chapterUID:chapter1UID}])
        }
        //console.log("CHAPTERS AS VALUES", values);
      }).catch((error) => {
        //console.log("Error getting document:", error);
      });

    }).catch((error) => {
       // console.log("Error getting document:", error);
    });


  
}
    //---------------------------------
      // MARK - ALERT
      //---------------------------------
      const [open, setOpen] = useState(false);
      const [openError, setOpenError] = useState(false);
      const [problem, setProblem] = useState("");
      const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
      const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenError(false);
      };
      //----------
  //--------------------------------
    // MARK:- UPLOAD
   // string number.substr(-5)
  //--------------------------------
//async function submitBlog(e) { 
const submitBlog = (e) => {
    if (completed >= 60 && currentUser) {
    e.preventDefault();
  
    //let timeStamp = `${Date.now().toString()}`;
    const uploadDate = Date(Date.now()).toString()
    const lastEditTimeStamp = Date.now().toString()
    var blogUID = `Blog-${currentUser.currentUser.uid}-${uuidv4().split("-")[0]}`;
    if (downloadedBlogUID) {
      blogUID = downloadedBlogUID
      //console.log('using downloadedBlogUID')
    } else {//console.log('using new blog uid')
    }
    setFinalBlogUID(blogUID)
    //console.log(blogUID)

    if (blogUID.split("-")[1] === currentChannelData.channelUID.split("-")[1]){

    let localFinalBudget = budget.final
    let localExpectedBudget = budget.expected
    let countryTags = Object.keys(countries);
    if (typeof localFinalBudget === "undefined") {localFinalBudget = ""}
    if (typeof localExpectedBudget === "undefined") {localExpectedBudget = ""}



      
      ref.doc(blogUID).set({
      blogUID: blogUID,
      title: name,
      channelName:currentUser.currentChannelData.channelName,
      channelUID: currentUser.currentChannelData.channelUID,
      userUID: currentUser.currentUserData.userUID,
      userName: currentUser.currentUserData.name,
      featureImageURL:imageToDisplay,
      budget:budget,
      finalBudget: localFinalBudget,
      expectedBudget: localExpectedBudget,
      summary: des,
      startDate: finalStartDate,
      endDate: finalEndDate,
      countries: countries,
      countryTags: countryTags,
      cities: cities,
      createdOn: createdOn,
      modifiedOn: uploadDate,
      lastEditTimeStamp: lastEditTimeStamp,
      publish: publish,
      block: blockThis
    })
    .then(() => {
      //console.log('using BLOG UID for image:' , blogUID)

      if (imageFile) {

      const fileName = `${imageFile.name}-${uuidv4().split("-")[0]}`
      const uploadTask = storageRef.ref(`${currentUser.currentChannelData.channelUID}/${blogUID}/${fileName}`).put(imageFile);
      

       uploadTask.on(
        "state_changed",
        snapshot =>  {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          setProgress(progress);
        },
        error => {
          alert(error.message)
        },
        () => {
           storageRef.ref(`${currentUser.currentChannelData.channelUID}/${blogUID}`).child(fileName).getDownloadURL().then(url => {
            setImageToDisplay(url)
            ref.doc(blogUID).update({featureImageURL:url})
          
          });
        }
      )
      }
      //console.log("hogaya 1")


    })
    .then(() => {
         ref2.doc(currentUser.currentChannelData.channelUID).update({blogList: firebase.firestore.FieldValue.arrayUnion(`${blogUID}`)}).then(() => {
        setOpen(true)

          window.scrollTo(0, 0)
          history.push(`/createBlog/${blogUID}`);
          getDownloadedBlog()
          //window.location.reload(false);
          
        
      })
    })
    .catch(error => {
      setOpenError(true)
      setProblem(`${error.message}`)
    });
      
    }
   
    }
    else {
      setOpenError(true)
      setProblem(`Please log in again`)
    }
   

    






}

//--------------------------------

// Publish Blog ------

const publishBlog = (e) => {
  var blogUID = finalBlogUID
  if (downloadedBlogUID) {blogUID = downloadedBlogUID} 
  setPublish(true);
  ref.doc(blogUID).update({publish: true}).then(() => {
    setOpen(true)
  }).catch(error => {
    setOpenError(true)
    setProblem(`${error.message}`)
  });
}

const unPublishBlog = (e) => {
  var blogUID = finalBlogUID
  if (downloadedBlogUID) {blogUID = downloadedBlogUID} 
  setPublish(false);
  ref.doc(blogUID).update({publish: false}).then(() => {
    setOpen(true)
  }).catch(error => {
    setOpenError(true)
    setProblem(`${error.message}`)
  });
}

  // Delete Blog----------
  const deleteBlog = (e) => {

    const confirmBox = window.confirm(
      "Do you really want to delete this WHOLE TRIP?"
    )
    if (confirmBox == true) {
      //alert("deleted")
    console.log("deleting?")
    //from here
    
    var blogUID = finalBlogUID
    if (downloadedBlogUID) {
      blogUID = downloadedBlogUID
    } else {//console.log('using finalBlogUID:')
    }


    //Delete Chapters first - 
    let chaptersToDelete = chapters
    const length = chaptersToDelete.length


   
    chaptersToDelete.map((chapter, index) => {

      

      
      ref.doc(blogUID).collection('chapters').doc(chapter.chapterUID).delete().then(() => {
        console.log("🥶 Chapter successfully deleted!");
        
        let refStorage = firebase.storage().ref(`${currentUser.currentChannelData.channelUID}/${blogUID}/${chapter.chapterUID}`);
        refStorage.listAll().then(dir => {
            dir.items.forEach(fileRef => {
              var dirRef = firebase.storage().ref(fileRef.fullPath);
              dirRef.getDownloadURL().then(function(url) {
                var imgRef = firebase.storage().refFromURL(url);
                imgRef.delete().then(function() {
                  // File deleted successfully 
                }).catch(function(error) {
                  // There has been an error      
                });
              });
            });
          }).catch(error => {
            setOpenError(true)
            setProblem(`${error.message}`)
          });


      }).catch((error) => {
        setOpenError(true)
        setProblem(`${error.message}`)
      });

      if(index === length -1) {
        
        ref.doc(blogUID).delete().then(() => {
          



          let refStorage = firebase.storage().ref(`${currentUser.currentChannelData.channelUID}/${blogUID}`);
          refStorage.listAll().then(dir => {
              dir.items.forEach(fileRef => {
                var dirRef = firebase.storage().ref(fileRef.fullPath);
                dirRef.getDownloadURL().then(function(url) {
                  var imgRef = firebase.storage().refFromURL(url);
                  imgRef.delete().then(function() {
                    // File deleted successfully 
                  }).catch(error => {
                    setOpenError(true)
                    setProblem(`${error.message}`)
                  });
                });
              });
            }).then(() => {
              let cu = currentUser.currentChannelData.channelUID.split("-")[1]
              let bu = blogUID.split("-")[1]
              if (cu === bu) {
                ref2.doc(currentUser.currentChannelData.channelUID).update({
                  blogList: firebase.firestore.FieldValue.arrayRemove(blogUID)
                }).then(()=> {
                  setOpen(true)
                }).catch(error => {
                  setOpenError(true)
                  setProblem(`${error.message}`)
                });
              }
            }).catch(error => {
              setOpenError(true)
              setProblem(`${error.message}`)
            });




        }).catch((error) => {
          setOpenError(true)
          setProblem(`${error.message}`)
      });
       }
    })
    //to here --------
   
    } else {
      console.log("Canceled Delete?")
    }
    //Delete storage sencond - 

    //Delete Blog then -

    //Delete from channel -
/*
    e.preventDefault();
    ref.doc(blogUID).delete().then(() => {
      console.log("Document successfully deleted!");
    }).catch((error) => {
        console.error("Error removing document: ", error);
    });*/

  }

  // Edit Blog-------------
  const changeBlogSummary = () => {
    var blogUID = finalBlogUID
    if (downloadedBlogUID) {
      blogUID = downloadedBlogUID
      //console.log('using downloadedBlogUID')
    } else {//console.log('using finalBlogUID:')
    }
    //console.log(blogUID)
    ref.doc(blogUID).update({summary: des});
  }

  /*Come and join me for an adventure to another former republic of the soviet union: Uzbekistan, the land of Babur and Timur. We will travel all across the hinterlands of Uzbekistan to look for soviet bus stations and beautiful babushkas. */

//--------------------------------------------------------------------------
    // MARK:- FUNCTIONS
//--------------------------------------------------------------------------
  
//----------ADD DATE---------------------------------------------------------
  
  function handleStartDateChange(value) {
    setStartDate(value)
    setFinalStartDate(value)
    if (value.getTime() > endDate.getTime()) {
      setEndDate(value)
      setFinalEndDate(value)
    } 
    setDateCompleted(true)
  }
  function handleEndDateChange(value) {
    setEndDate(value)
    setFinalEndDate(value)
    //console.log('End Date', value)
    setDateCompleted(true)
  }

//---------ADD COUNTRY-----------------------------------------------------

  function handleAddCountry(value) {
    
    //let newValue = Object.fromEntries(value)
    const newValues = {...countries}
    value.map((item) => (
      newValues[item[0]] = item[1]
    ));
    setCountries(newValues)
    if (newValues != {}) {
      setCountriesCompleted(true)
    } else {setCountriesCompleted(false)}
  }

  function handleDeleteCountry(value) {
    const values = {...countries}
    delete values[value]
    setCountries(values)
    if (values != {}) {
      setCountriesCompleted(true)
    } else {setCountriesCompleted(false)}
  }
//--------------------------------------------------------------------------
  const [imageFile, setImageFile] = useState(null);
  const [progress, setProgress] = useState(0);

  async function handleImageChangeTest(e) { 
  //const handleImageChangeTest = e => {

    if (e.target.files[0]) {

      try {
        const compressedFile = await imageCompression(e.target.files[0], options);
        setImageFile(compressedFile)
        setImageToDisplay(URL.createObjectURL(compressedFile));
        setFeatureImageCompleted(true)
      } catch (error) {
        console.log(error);
      }
      
    }
    
  }

  const handleDeleteImage = e => {
    setImageToDisplay(null)
    setImageFile(null)
    //props.onImageDelete();
    //console.log("removing image")
}


  //---------ADD CHAPTERS-----------------------------------------------------
  //let chapter1UID = `Chapter-${parseInt(Math.random() * Date.now().toString())}`;
  //const [chapters, setChapters] = useState([{chapterName:"chapter1", chapterUID:chapter1UID}]);
  const [testChapterName, setTestChapterName] = useState("");

  const handleChapterName = (value, index) => {
    const values = [...chapters]
    values[index].chapter = value
    values[index].isSaved = false
    setChapters([...values])
    //console.log("New Chapter Name" , value)

  }

  const handleAddChapter = () => {
    const values = [...chapters]
    const newChapterUID = `Chapter-${Date.now().toString()}`
    
    const value = {chapterUID:newChapterUID, chapter:"New Chapter"}
    values.push(value)
    setChapters([...values])
  }

  const handleAddChapterAtIndex = (index) => {
    const values = [...chapters]
    const newChapterUID = `Chapter-${Date.now().toString()}`
    
    const value = {chapterUID:newChapterUID, chapter:"New Chapter"}
    values.splice(index + 1, 0, value)
    setChapters([...values])

    //console.log(values)
  }


  const handleSaveChapter = (value, index) => {
    
    //console.log("New Chapter Pressed")
    var blogUID = finalBlogUID
    if (downloadedBlogUID) {
      blogUID = downloadedBlogUID
      //console.log('using downloadedBlogUID')
    } else {//console.log('using finalBlogUID:')
  }
    const values = chapters
   

    if (blogUID) {

      const newDate = Date(Date.now()).toString()
      const newLastEditTimeStamp = Date.now().toString()
      //console.log('chapter UID',value, value.chapterUID)
      
      ref.doc(blogUID).collection("chapters").doc(value.chapterUID).get().then((doc) => {
        if (doc.exists) {
          ref.doc(blogUID).collection("chapters").doc(value.chapterUID).update({
            chapter: value.chapter,
            modifiedOn: newDate,
            lastEditTimeStamp: newLastEditTimeStamp,
            isSaved: true
          }).then(() => {
            value.isSaved = true
            values.splice(index, 0, value)
            setChapters([...values])
            alert('Submitted')
          })
        } else {
          ref.doc(blogUID).collection("chapters").doc(value.chapterUID).set({
            chapter: value.chapter,
            chapterUID: value.chapterUID,
            createdOn: newDate,
            modifiedOn: newDate,
            index:index,
            isSaved: true
          }).then(() => {
            //setTestChapterName("")
            value.isSaved = true
            values.splice(index, 1, value)
            //setChapters(values)
            setChapters([...values])
            //setOpen(true)
            alert('Submitted')
          })
        }
    })
      .catch(error => {
        setOpenError(true)
        setProblem(`${error.message}`)
      });
    
      //console.log('New Date:',newDate)
      //console.log('Chapter Name:',value.chapter)

    }
  }




  function handleDeleteChapter(value,index) {
    const confirmBox = window.confirm(
      "Do you really want to delete this WHOLE CHAPTER?"
    )
    if (confirmBox === true) {
    var blogUID = finalBlogUID
    if (downloadedBlogUID) {
      blogUID = downloadedBlogUID
      //console.log('using downloadedBlogUID')
    } else {//console.log('using finalBlogUID:')
  }

    const values = chapters
    values.splice(index, 1)
    setChapters([...values])
    //console.log('newChapter', chapters)

    ref.doc(blogUID).collection("chapters").doc(value.chapterUID).delete().catch(error => {
      setOpenError(true)
      setProblem(`${error.message}`)
    });
  }
  }


  //---------RENDER CHAPTERS----------------------------------------------------- 


  const [renderBudget, setRenderBudget] = useState(false);
  const [renderChapter, setRenderChapter] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState({});
  const [selectedChapterName, setSelectedChapterName] = useState("");
  const [selectedChapterUID, setSelectedChapterUID] = useState("");
  const [selectedChapterBlocks, setSelectedChapterBlocks] = useState([]);

  const [displaySaveChapterOrderButton, setDisplaySaveChapterOrderButton] = useState("displayOff");
  //const [displayAddChapterSection, setDisplayAddChapterSection] = useState("displayOff");
  //for test ---
  
  //----

  const handleSelectChapter = (value) => {
      setSelectedChapterName(value.chapter)
      setSelectedChapterUID(value.chapterUID)
      setSelectedChapter(value)
      //console.log("selectedChap", value);
      //var values = {}
      var values = []
      if (value.blocks) {
        values[0] = value.blocks
        //values = value.blocks;
        //console.log("selectedBlocks", value.blocks);
      } else {
        //let firstTimeStamp = Date.now().toString()
        //let newBlockUID = `Block--${firstTimeStamp}`;
        //values.push({blockUID: newBlockUID, blockType: ""})
        values[0] = []
      }

      //if (values) {
      setSelectedChapterBlocks(Object.values(values[0]))//values[0])
      //console.log("handleSelectChapter:", Object.values(values[0]))
      //} else {setSelectedChapterBlocks([])}
      setRenderChapter(true)

  }

  const handleLeaveChapter = (event) => {
    setSelectedChapterName("");
    setSelectedChapterUID("");
    setRenderChapter(false);
    //console.log("EVENT: ",event);
}

  if (renderChapter == true) {
    
    var blogUID = finalBlogUID
    if (downloadedBlogUID) {
      blogUID = downloadedBlogUID
      //console.log('using downloadedBlogUID')
    } else {//console.log('using finalBlogUID:')
  }

    //console.log("selectedChapterBlocks send over to upload chapter from blogs",selectedChapterBlocks)
    return (
      <div>
      <UploadChapter onClick={(event) => handleLeaveChapter(event)} spotify={selectedChapter.spotify} featureImageURL={selectedChapter.featureImageURL} selectedChapter={selectedChapter} blocks={selectedChapterBlocks} currentChannel={currentUser.currentChannelData} chapter={selectedChapterName} chapterUID={selectedChapterUID} finalBlogUID={blogUID}/>
      </div>
    )
    

  }  
  


  const handleSelectBudget = () => {

    /*
    console.log("budget", value);
    //var values = {}
    var values = []
    if (value.blocks) {
      values[0] = value.blocks
      //values = value.blocks;
      console.log("selectedBlocks", value.blocks);
    } else {
      let firstTimeStamp = Date.now().toString()
      let newBlockUID = `Block--${firstTimeStamp}`;
      //values[0] = {blockUID: newBlockUID, blockType: ""}
      values.push({blockUID: newBlockUID, blockType: ""})
      
    }
    */
    setRenderBudget(true)

}

const handleLeaveBudget = (event) => {
  setRenderBudget(false);
  //console.log("EVENT: ",event);
}

if (renderBudget == true) { 
  var blogUID = finalBlogUID
  if (downloadedBlogUID) {
    blogUID = downloadedBlogUID
    //console.log('using downloadedBlogUID')
  } else {//console.log('using finalBlogUID:')
}
  return (
    <div>
      <UploadBudget  onClick={(event) => handleLeaveBudget(event)} currentUser={currentUser.currentChannelData.channelUID} finalBlogUID={blogUID} budget={budget}></UploadBudget>
    </div>
  )
}
  //console.log("image Before: ", imageFile)

  //---------Drag and Drop -----------------------------------------------------
  
  const saveChapterOrder = () => {

    var blogUID = finalBlogUID
    if (downloadedBlogUID) {
      blogUID = downloadedBlogUID
      //console.log('using downloadedBlogUID')
    } else {
      //console.log('using finalBlogUID:')
    }
    
    chapters.forEach( (chapter, index) => {
      ref.doc(blogUID).collection("chapters").doc(chapter.chapterUID).update({index:index}).then(() => {
        setDisplaySaveChapterOrderButton("displayOff")
        alert('Submitted')
      }).catch(error => {
        alert(error.message)
      });
    })
  }

  const fixDate = (date) => {
    var dateArray = date.toString().split(" ")
    if (date.seconds) {
      dateArray = date.toDate().toString().split(" ")
      //console.log("😶 Date:",date)
    } else {}
    let day = dateArray[2];
    let month = dateArray[1];
    let year = dateArray[3];
    let finalDate = `${day} ${month} ${year}`
    return finalDate
}


 
  //---------RENDER-------------------------------------------------------------
//https://open.spotify.com/track/2k62KlwlLa2o7gdXvopqW6?si=54f43e39b2cd4ce8
//{ currentChannelData ? downloadedBlogUID.split("-")[1] === currentChannelData.channelUID.split("-")[1] ? <>
    return (
      <div className="UploadBlog-uploadContainer">
        <Helmet>
                <title>Upload - Adventure Chase </title>
                <meta name='description' content="If you love exploring, create your own Adventure Chase Travel Magazine blog." />
                {downloadedBlogUID ? 
                <link rel='canonical' href={`/createBlog/${downloadedBlogUID}`}/> : <link rel='canonical' href={`/createBlog`}/> }
        </Helmet>
        <div className="uploadContainer">
          
          <div className="UploadBlog-title-container">
            <h1 className="alg-800 uppercase rem24">Add Trip</h1>
            
            
            {downloadedBlogUID && realPerson ? 
            <div className='UploadBlog-title-buttons'>
              <button className={`${publish ? "UploadBlog-publish-button" : "UploadBlog-unpublish-button"} UploadBlog-publish-button mav-800 m-r-10`} onClick={() => {publish ? unPublishBlog() : publishBlog()}}>{publish ? "Unpublish" : "Publish"}</button>
              <button className='UploadBlog-delete-button mav-800 m-r-10' onClick={deleteBlog}>Delete</button>
              <button className='UploadBlog-addBudget-button mav-800' onClick={handleSelectBudget}>Add Budget</button>
            </div> : <></>}
          </div>
            
            <div className="form ">
                
                <div className="upload-blog-row">
                  <label className="trip-title alg-800 uppercase lContainer">Trip Title*</label>
                  <input className="titleInput rContainer inputTitle" placeholder="" value={name} onChange={(event) => {setName(event.target.value); if (event.target.value != ""){setTitleCompleted(true)} else {setTitleCompleted(false)}}}/>
                </div>
                {/* ------------------------------------------------- DATE -------------------------------------------------  */}
                <div className="upload-blog-row m-t-10">
                  <label className="trip-date alg-800 uppercase lContainer ">Date*</label>
                  <div className="trip-date-datecontainer">
                    <div className="dateContainer ">
                      <div className="datePicker m-r-20">
                        <CustomDatePicker  startDate={startDate} endDate={endDate} selectsStart={true} onChange={(value) => handleStartDateChange(value)}/>
                      </div>
                      <div className="datePicker">
                        <CustomDatePicker className="test-pink datePicker" startDate={startDate} endDate={endDate} selectsStart={false} onChange={(value) => handleEndDateChange(value)}/>
                      </div>
                    </div>
                    {(finalStartDate) ? <p className='trip-date-label mav-800'>Trip Date : {fixDate(finalStartDate)} - {finalEndDate ? fixDate(finalEndDate) : ""}</p>: <></>}
                  </div>
                </div>
                {/* ------------------------------------------------- COUNTRIES -------------------------------------------------  */}
                <div className="upload-blog-row2 m-t-20">
                <label className="trip-countries alg-800 uppercase lContainer m-t-20">Countries Visited*</label>
                  <div className="trip-countries-values ">
                  <CountryPicker onClick={(value) => handleAddCountry(value)}/>
                  <div className="trip-countries-chips">
                    {
                      Object.entries(countries).map(([key,value]) => (
                        <CountryTag flag={value} country={key} onClick={(value) => handleDeleteCountry(value)} key={key}/>
                      ))
                    }
                  </div>
                  </div>
                </div>
                {/* ------------------------------------------------- Cities -------------------------------------------------  */}
                <div className="upload-blog-row">
                  <label className="trip-cities alg-800 uppercase  lContainer">Cities Visited*</label>
                  <input className="citiesInput rContainer" placeholder="use comma , to seperate the cities" value={cities} onChange={(event) => {setCities(event.target.value); if (event.target.value != ""){setCitiesCompleted(true)} else {setCitiesCompleted(false)}}}/>
                </div>
                {/* ------------------------------------------------- IMAGE -------------------------------------------------  */}
                <div className="upload-blog-row2 m-t-20">
                  <div className="lContainer">
                    <label className="trip-feature-image alg-800 uppercase  m-t-20 ">Feature Image*</label>
                  </div>

                  <div className="r2Container">
                    <div className="featureImageContainer">
                      <div className="imagePreview">
                          <input type="file" id="featureImage" onChange={handleImageChangeTest} accept="image/*"/>
                          <label for="featureImage" className="uploadImageLabel"><img src={imageToDisplay ? imageToDisplay : imageBtn} alt="featureImage" className="img"/></label>
                          
                      </div>
                    </div>
                  </div>

                </div>
                {/* ------------------------------------------------- SUMMARY -------------------------------------------------  */}
                <div className="upload-blog-row2 m-t-20">
                  <label className="trip-summary alg-800 uppercase   lContainer">Summary*</label>
                  {/*<textarea className="rContainer summary" placeholder="des" value={des} contenteditable onChange={(event) => setDes(event.target.value)}></textarea>*/}
                  <div className="trip-summary-container m-0">
                    <TextField
                        id="standard-textarea"
                        label=""
                        placeholder="summary"
                        fullWidth
                        minRows={13}
                        InputProps={{ disableUnderline: true, style:{padding:"10px", border:"solid", borderWidth:'1px', borderColor:'#eee', borderRadius:'10px'} }}
                        multiline
                        defaultValue={des}
                        onChange={(event) => {setDes(getHTMLTextFromNormalText(event.target.value)); if(event.target.value != "") {setSummaryCompleted(true)} else {setSummaryCompleted(false)} }}
                        variant="standard"
                    />
                </div>
                
                </div>
                {/* ------------------------------------------------- CHAPTERS -------------------------------------------------  */}
                <div className="upload-blog-row m-t-20 ">
                  <div className="upload-blog-chapters-title-container">
                    <label className="trip-add-chapters alg-800 uppercase   ">Add Chapters</label>
                    <button className={`trip-save-chapter-order-button mav-800 ${displaySaveChapterOrderButton}`} onClick={saveChapterOrder}>save order</button>
                  </div>
                </div>






                <DragDropContext onDragEnd={(event) => {
                    const srcIndex = event.source.index ;
                    const destIndex = event.destination?.index;
                    if(destIndex != null){
                    chapters.splice(destIndex,0,chapters.splice(srcIndex,1)[0]);
                    setDisplaySaveChapterOrderButton("displayOn")
                    }
                  }}>
                <Droppable droppableId='droppable-1'>

                {(provided, snapshot) => (
                <div 
                      ref={provided.innerRef}
                      style={{backgroundColor: snapshot.isDraggingOver ? 'f1f1f1':'white'}}
                      {...provided.droppableProps}
                      >

                        
                { finalBlogUID ?
                chapters.map((chapter, index) => (

                  <Draggable key={chapter.chapterUID} 
                            draggableId={'draggable-'+ chapter.chapterUID} 
                            index={index}>

                    {(provided, snapshot) => (
                                <ListItem className='trip-chapters-container  p-v-20' ref={provided.innerRef} {...provided.draggableProps} 
                                style={{...provided.draggableProps.style,boxShadow: snapshot.isDragging ? "0 0 0.4rem #f1f1f1" : "none"}}
                                >




                      <div key={index} className=" trip-chapter-container  m-t-20">
                        <div className="trip-chapter-bar flexsb shadow rad14 ">


                            <div className="trip-chapter-bar-inner m-v-20 ">
                              <div className="trip-chapter-bar-dragButton  flexc "{...provided.dragHandleProps}>
                                 <DragIndicatorIcon className=" " style={{color: '#b8b8b8'}}/>
                              </div>
                              
                              <h3 className="trip-chapter-bar-index" >{index+1}</h3>
                              
                              <div className="trip-chapter-chapterInput-container">
                                <input className="chapterInput inputChapter" placeholder="" value={chapter.chapter} onChange={(event) => handleChapterName(event.target.value, index)}/>
                              </div>


                             

                            </div>
                            
                            {
                              chapter.isSaved == true ?
                              <button className="trip-chapter-view-button m-v-auto" onClick={() => handleSelectChapter(chapter)}><ArrowRightIcon/></button> : 
                              <>
                              <button className="trip-chapter-view-button m-v-auto"><PriorityHighIcon/></button>
                              <button className="trip-chapter-save-button m-v-auto" onClick={() => handleSaveChapter(chapter, index)}>Save</button>
                              </>
                            }


                        </div>
                        { chapters.length > 1 &&
                          <button className="wt-50 ht-50 clear-button colour-red m-auto" onClick={() => handleDeleteChapter(chapter,index)}><DeleteIcon/></button>
                        }

                            <div className="flexstart m-v-20 ">
                              <button className="UploadBlog-add-chapter-button m-auto" onClick={() => handleAddChapterAtIndex(index)}><AddIcon style={{fontWeight:"800",fontSize:"1.6rem", paddingTop:"4px"}}/></button>
                            </div>
                        
                      </div>






                      

                      </ListItem>       
                    )}

                  </Draggable>

                ))
                : <p className='UploadBlog-notsavedblog-placeholder mav-800'>Click Submit to start adding Chapters</p>
                }
                      {provided.placeholder}
                </div>

                )}


                </Droppable>


                </DragDropContext>

                {finalBlogUID ? 
                <div className="flexstart m-v-20 p-h-20">
                  <button className="UploadBlog-add-chapter-button m-auto" onClick={handleAddChapter}><AddIcon style={{fontWeight:"800",fontSize:"2.6rem", paddingTop:"4px"}}/></button>
                  
                </div>
                :<></>}
            </div>


            
            
            {/* ------------------------------------------------- -- -------------------------------------------------  */}
            

            

            <div className="UploadBlog-floating-container">
              
              <div className='UploadBlog-floating-progressbar-container'>
                <div className={`UploadBlog-floating-progressbar`} style={{width:`${completed}%`, transition:'0.5s'}}>
                </div>
              </div>
              
              { match.params.id ? 
                realPerson ? 
                  <>
                  <button className="UploadBlog-floating-button mav-800" onClick={submitBlog} style={(completed >= 60) ? {color:"#ffb71b"} :{color:"#ffffff", cursor:"default"}}>submit</button>
                  </>
                :<></> 
              : <>
                <button className="UploadBlog-floating-button mav-800" onClick={submitBlog} style={(completed >= 60) ? {color:"#ffb71b"} :{color:"#ffffff", cursor:"default"}}>submit</button>
              </> 
              
              }
              </div>
            
            
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} >
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' ,backgroundColor:"#164734", color:'#ffb71b'}}>
                Saved Successfully!
              </Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
              <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                Error! {problem}
              </Alert>
            </Snackbar>
            
            

           
            
        </div>
      </div>
    )
}

// </>: <h2 className='uppercase mav-800'>Loading your next adventure...1</h2> : <h2 className='uppercase mav-800'>Loading your next adventure...2</h2> }


export default UploadBlog






/*
Reference

testList ? testList.map((item, index) => {
                        <h1>{item} has index {index} saved</h1>
                        }) : <p>No list</p>

<iframe className="rad14" src="https://open.spotify.com/embed/track/2k62KlwlLa2o7gdXvopqW6" width="300" height="80" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>


            <p className="m-t-20 col2 text-j col-dotted col-w-250 ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Est lorem ipsum dolor sit amet consectetur adipiscing elit. Nibh mauris cursus mattis molestie a iaculis. Consequat interdum varius sit amet mattis vulputate enim. Mattis pellentesque id nibh tortor id aliquet. Sed sed risus pretium quam. Eget dolor morbi non arcu risus quis varius quam quisque. Tempus urna et pharetra pharetra. Sit amet est placerat in egestas erat. 


Nec feugiat in fermentum posuere. Tortor condimentum lacinia quis vel eros. Neque sodales ut etiam sit. Viverra aliquet eget sit amet tellus cras adipiscing. Enim nunc faucibus a pellentesque sit amet porttitor eget dolor. Id venenatis a condimentum vitae sapien pellentesque habitant. Euismod quis viverra nibh cras pulvinar mattis. Eu volutpat 
Nec feugiat in fermentum posuere. Tortor condimentum lacinia quis vel eros. Neque sodales ut etiam sit. Viverra aliquet eget sit amet tellus cras adipiscing. Enim nunc faucibus a pellentesque sit amet porttitor eget dolor. Id venenatis a condimentum vitae sapien pellentesque habitant. Euismod quis viverra nibh cras pulvinar mattis. Eu volutpat </p>



<CustomDatePicker  startDate={startDate} endDate={endDate} selectsStart={true} onChange={(value) => handleStartDateChange(value)}/>
<CustomDatePicker className="test-pink datePicker" startDate={startDate} endDate={endDate} selectsStart={false} onChange={(value) => handleEndDateChange(value)}/>
            




*/