
export const getHTMLTextFromNormalText = (text) => {
    let finalText = '';
    text.replace(/(?:\r\n|\r|\n)/g, ' <br> ').split(' ').forEach((word) => {
        if (word.includes('http')) {
            finalText += `<a href=${word} target="_blank" rel="noreferrer">${word}</a> `;
        } else if (word.includes('www')) {
            finalText += `<a href=${word} target="_blank" rel="noreferrer">https://${word}</a> `;
        } else if (word.includes('@') && word.includes('.')) {
            finalText += `<a href=mailto:${word}>${word}</a> `;
        } else {
            finalText += `${word} `;
        }
    });
    return finalText;
};