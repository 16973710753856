import React, { useEffect } from 'react'
import '../../Styles/Index.css'
import '../../Styles/HardBlogList.css'
import { Link } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add';
import { Helmet } from 'react-helmet';
import tigerImage from '../../Assets/acShareImage.png'
import firebase from '../../firebase';
function HardBlogList() {
    const firebaseAnalytics = firebase.analytics();
    useEffect(() => {
        
        firebaseAnalytics.logEvent("Hard_Blog_List_Visited")
    
        return () => {
            //to clean up anything you did before
        }
    }, []);
  return (
    <div className='HardBlogList-container'>
        <Helmet>
            <title>Trips - Adventure Chase </title>
            <meta name='description' content="Check out the list of all the trips and adventures shared on Adventure Chase Travel as blogs." />
            <link rel='canonical' href="/triplist"/>
            <meta name='keywords' content='Adventure Chase, Adventure, Where to travel in India?, places to visit in india, sites to see in india, tourism, explore, travel magazine, hiking bags, hiking, places to go, things to see, bored, what to do, backpacking in india, makemytrip, make my trip, holiday plans, holiday packages, holidays in india, india tourism, holiday destinations, holiday packages, holiday packages india, india tours, honeymoon packages india, honeymoon packages, india tour packages, Indian holidays, cheap holiday packages, india travel, travel in india, india, travel, honeymoon, packages, holidays'/>
        </Helmet>

        <div className='HardBlogList-sidebar'>
            <p className="HardBlogList-sidebar-title mav-800 uppercase">Post your next adventure here</p>
            <Link className="HardBlogList-sidebar-link" to="/contactus">
                <button className='HardBlogList-sidebar-button uppercase' >Open Channel <AddIcon style={{fontSize: "1rem", fontWeight:"800"}}/></button>
            </Link>
        </div>  
        <div className='HardBlogList-body'>
            <h2 className='HardBlogList-trips-label'>TRIPS</h2>
            <div className='HardBlogList-container-list'>


            <div className='HardBlogList-container-item'>
                    <img className='HardBlogList-container-item-image' src="https://firebasestorage.googleapis.com/v0/b/travelite-7ce27.appspot.com/o/Channel-7nPf1VZFZNcKeN1qYnjR6eqSxU33%2FBlog-7nPf1VZFZNcKeN1qYnjR6eqSxU33-a176f891%2FIMG_7488.jpeg-619a1f6b?alt=media&token=c2420dbd-cd0d-4604-b0b5-66ee999f09d6" alt='A man standing on a rock outside a cave' ></img>
                    <a href="https://adventure-chase.com/blog/Blog-7nPf1VZFZNcKeN1qYnjR6eqSxU33-a176f891" >
                        
                        <p className='HardBlogList-item-title'>EXPLORING THE ELEPHANTA CAVES</p>
                        <p className='HardBlogList-item-author'>Hartaj Singh</p>
                        <div className='HardBlogList-item-places-container'>
                            <p className='HardBlogList-item-countries'>Countries: India</p>
                            <p className='HardBlogList-item-cities'>Cities: Mumbai</p>
                        </div>
                        
                        <p className='HardBlogList-item-summary font-para-300'>I have been living in Mumbai since early 2004, and yet I have never had the pleasure of visiting one of the most iconic touristic attractions in Mumbai — the Elephanta Caves. Until now that is…</p>

                    </a>
                </div>


            <div className='HardBlogList-container-item'>
                    <img className='HardBlogList-container-item-image' src="https://firebasestorage.googleapis.com/v0/b/travelite-7ce27.appspot.com/o/Channel-Zi2OEAhF3rY1KgPnjXdzGsZ1JM02%2FBlog-Zi2OEAhF3rY1KgPnjXdzGsZ1JM02-f366ce36%2FWhatsApp%20Image%202023-01-09%20at%2023.49.58%20(1).jpeg-387ad141?alt=media&token=f473d8be-7d6d-4636-adfe-4cad0a2371f1" alt='Image of Sea' ></img>
                    <a href="https://adventure-chase.com/blog/Blog-Zi2OEAhF3rY1KgPnjXdzGsZ1JM02-f366ce36" >
                        
                        <p className='HardBlogList-item-title'>GOA FOR NEW YEARS</p>
                        <p className='HardBlogList-item-author'>ADVENTURE CHASE: Abhishek Lilani</p>
                        <div className='HardBlogList-item-places-container'>
                            <p className='HardBlogList-item-countries'>Countries: India</p>
                            <p className='HardBlogList-item-cities'>Cities: Anjuna, Baga, Vagator</p>
                        </div>
                        
                        <p className='HardBlogList-item-summary font-para-300'>For New Year's Eve, Abhishek spontaneously planned a trip to Goa to surprise his friends who were attending the Sunburn Music Festival in Goa. The following 2 days were spent enjoying the nightlife of North Goa with his friends, before heading back to Mumbai in an unusual manner.</p>

                    </a>
                </div>

            <div className='HardBlogList-container-item'>
                    <img className='HardBlogList-container-item-image' src="https://firebasestorage.googleapis.com/v0/b/travelite-7ce27.appspot.com/o/Channel-Zi2OEAhF3rY1KgPnjXdzGsZ1JM02%2FBlog-Zi2OEAhF3rY1KgPnjXdzGsZ1JM02-e15a37a7%2FSnapseed%2013.jpg-6e0b05fb?alt=media&token=88a28644-73a3-4a0c-909a-960a61ad2505" alt='Tiger Safari' ></img>
                    <a href="https://adventure-chase.com/blog/Blog-Zi2OEAhF3rY1KgPnjXdzGsZ1JM02-e15a37a7" >
                        
                        <p className='HardBlogList-item-title'>TIGER SAFARI</p>
                        <p className='HardBlogList-item-author'>ADVENTURE CHASE: Samyak Jain</p>
                        <div className='HardBlogList-item-places-container'>
                            <p className='HardBlogList-item-countries'>Countries: India</p>
                            <p className='HardBlogList-item-cities'>Cities: Nagpur, Tadoba-Andhari National Park</p>
                        </div>
                        
                        <p className='HardBlogList-item-summary font-para-300'>On this trip to the Tadoba-Andhari National Park near Nagpur, Samyak Jain, Ganesh Shanmugam and friends hope to see Tigers for the first time. The article gives a detailed account of their experience with the wildlife, their budget and their stay at the Waghoba Eco Lodge.</p>

                    </a>
                </div>

                <div className='HardBlogList-container-item'>
                    <img className='HardBlogList-container-item-image' src="https://firebasestorage.googleapis.com/v0/b/travelite-7ce27.appspot.com/o/Channel-M2iOK8D6Y3VW52QJTflLsBGaoN23%2FBlog-M2iOK8D6Y3VW52QJTflLsBGaoN23-76695dcb%2FIMG_3095.jpg-575e3a13.jpg-4555aed7?alt=media&token=4e13537f-a563-4802-b8c0-721c1eb63888" alt='Trip to Tawang image' ></img>
                    <a href="https://adventure-chase.com/blog/Blog-M2iOK8D6Y3VW52QJTflLsBGaoN23-76695dcb" >
                        
                        <p className='HardBlogList-item-title'>AN EXHILARATING JOURNEY TO TAWANG</p>
                        <p className='HardBlogList-item-author'>Planet Explorer: MD Danish</p>
                        <div className='HardBlogList-item-places-container'>
                            <p className='HardBlogList-item-countries'>Countries: India</p>
                            <p className='HardBlogList-item-cities'>Cities: Guwahati, Tezpur, Bhalukpong, Dirang, Tawang, Sela Pass, Bum La Pass, Sangetsar Lake</p>
                        </div>
                        
                        <p className='HardBlogList-item-summary font-para-300'>The trip was about exploring certain places in India which are not on many people's travel lists. So Tawang was the best fit for our trip. We are planning to experience a long road journey, seeing majestic mountains covered with trees, snow and towns! Also planning to visit many monasteries and enjoy the local culture and foods.</p>

                    </a>
                </div>


                <div className='HardBlogList-container-item'>
                    <img className='HardBlogList-container-item-image' src="https://firebasestorage.googleapis.com/v0/b/travelite-7ce27.appspot.com/o/Channel-7nPf1VZFZNcKeN1qYnjR6eqSxU33%2FBlog-7nPf1VZFZNcKeN1qYnjR6eqSxU33-89e14094%2FIMG_5433.jpg-85580a31?alt=media&token=5e3c9651-587c-4913-bd07-411fb87315bd" alt='Trip to Spain feature image' ></img>
                    <a href="https://adventure-chase.com/blog/Blog-7nPf1VZFZNcKeN1qYnjR6eqSxU33-89e14094" >
                        
                        <p className='HardBlogList-item-title'>Trying out Spain!</p>
                        <p className='HardBlogList-item-author'>Hartaj Singh</p>
                        <div className='HardBlogList-item-places-container'>
                            <p className='HardBlogList-item-countries'>Countries: Spain</p>
                            <p className='HardBlogList-item-cities'>Cities: Madrid, Barcelona</p>
                        </div>
                        
                        <p className='HardBlogList-item-summary font-para-300'>Discovering Spain through the eyes of a university student with limited budget and a very few expectations while trying to survive on biscuits in a not so vegetarian friendly country. Throughout my trip I got lots of warnings by locals and previous travellers to Spain about what could go wrong there, what all can/will they steal from you but what I discovered about Spain was something very...foreign.</p>

                    </a>
                </div>


                

            </div>
        </div>
       
    </div>
  )
}

export default HardBlogList