import React from 'react'
import '../../Styles/Index.css'
import '../../Styles/Blog.css'
import errorImg from '../../Assets/error.png'
function Type5(props) {
    const block = props.block

    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

    return (

        <>
        { block.highlighted === "yes" ?
        <div className='type5-highlighted'>
            
        <div className='type5-images'>
            <div className='type5-group-container'>
                <img className='type5-image-l' src={block.photoUrl ? block.photoUrl : errorImg} alt=""/>
                <img className='type5-image-p' src={block.photoUrl2 ? block.photoUrl2 : errorImg} alt=""/>
                <img className='type5-image-p' src={block.photoUrl3 ? block.photoUrl3 : errorImg} alt=""/>
                <img className='type5-image-l' src={block.photoUrl4 ? block.photoUrl4 : errorImg} alt=""/>
            </div>
            <img className='type5-image-5' src={block.photoUrl5 ? block.photoUrl5 : errorImg} alt=""/>
        </div>
        <p className='alg-800 font-para-700 type5-title'>{block.title}</p>

        </div>
        :
        <div className='type5'>
            
        <div className='type5-images'>
            <div className='type5-group-container'>
                <img className='type5-image-l' src={block.photoUrl ? block.photoUrl : errorImg} alt=""/>
                <img className='type5-image-p' src={block.photoUrl2 ? block.photoUrl2 : errorImg} alt=""/>
                <img className='type5-image-p' src={block.photoUrl3 ? block.photoUrl3 : errorImg} alt=""/>
                <img className='type5-image-l' src={block.photoUrl4 ? block.photoUrl4 : errorImg} alt=""/>
            </div>
            <img className='type5-image-5' src={block.photoUrl5 ? block.photoUrl5 : errorImg} alt=""/>
        </div>
        <p className='alg-800 font-para-700 type5-title'>{block.title}</p>

        </div>
        }
        </>
    )
}

Type5.defaultProps = {
    title:"",
}

export default Type5
