import React from 'react'
import {useState, useEffect, useContext} from 'react';
import firebase from '../../firebase';
import {Link} from 'react-router-dom';
import { AuthContext } from '../Daxil/Auth';
import '../../Styles/Index.css'
import '../../Styles/Account.css'
import AddIcon from '@mui/icons-material/Add';

import BlogAsSmall from '../Home/BlogAsSmall';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';
function Account({match}) {
    const currentUser = useContext(AuthContext)
    const [channelUID, setChannelUID] = useState(match.params.id ? match.params.id : "");
    const [channel, setChannel] = useState({});
    const ref = firebase.firestore().collection("Channels");
    const ref2 = firebase.firestore().collection("Blogs");
    const [loading, setLoading] = useState(false);
    const [articles, setArticles] = useState([]);
    const firebaseAnalytics = firebase.analytics();

    function getChannel() {
        setLoading(true)
        console.log("attempting to get channel 😇!")
    //const getAccount = async () => {  Channel-NomadicIndian-xe2fvrvrb  Channel-AdventureChase-1629209677488
        ref.doc(channelUID).get().then((doc) => {
            if (doc.exists) {
                //console.log("Document data:", doc.data());
                console.log("channel exists 😇!")
                setChannel(doc.data())
                firebaseAnalytics.logEvent(`Account_${doc.data().channelName}_Visited`)
                const items = [];
                //setLoading(true);
                
                doc.data().blogList.forEach((blogUID) => {
                    ref2.doc(blogUID).get().then((doc) => {
                        items.push(doc.data());
                        //console.log("🤓 ITEMS: ", items)
                        setArticles([...items.reverse()]);
                    })
                })
                
                setArticles([...items]);
                setLoading(false);
            } else {console.log("No such document!")
            }
           
        }).then({



        }).catch((error) => {
            //console.log("Error getting document:", error);
        });
    }


    useEffect(() => {
        firebaseAnalytics.logEvent("Account_Visited")
        if (channelUID) {
            getChannel();
        }
        return () => {
            //to clean up anything you did before
            
        }
    }, []);


/*
    let blogsToRender;
    if(account.blogList) {
        console.log("IF SUCCESSFUL")
        
        blogsToRender =  account.blogList.map((blogUID) => {
            console.log(blogUID)
                return <div key={blogUID} className="flexc m-b-20">
                    <BlogAsSmall blog={blog}/>
                </div>  
        })
    } else {console.log("IF NOT SUCCESSFUL")}
    */
   /*
   
   <div key={blog.blogUID} className="flexc m-b-20">
                        <BlogAsSmall blog={blog}/>
    </div>

     <div key={blogUID}>
                <Link to={`/blog/${blogUID}`}><h2>{blogUID}</h2></Link>
                
        </div>
   */

        if (loading) {
            return <h1 key="loader">Loading your next adventure...</h1>
        }
    return (
        <div className='Account-container'>
            <Helmet>
                <title>{`${channel.channelName}`} - Adventure Chase </title>
                { channel.channelDescription ?
                <meta name='description' content={`${channel.channelDescription.slice(0,170)}`}/> : <meta name='description' content="Blogger's Channel Description - Adventure Chase"/>}
                <meta name="theme-color" content="#164734" />
                <link rel='canonical' href={`/account/${channelUID}`}/>
            </Helmet>
            <div className='Account-sidebar-container'>
                <p className="Account-sidebar-title mav-800 uppercase">Post your next adventure here</p>
                <Link className="Account-sidebar-link" to="/contactus">
                    <button className='Account-sidebar-button uppercase' >Open Channel <AddIcon style={{fontSize: "1rem", fontWeight:"800"}}/></button>
                </Link>
            </div>

            <div className='Account-content-container'>
                <p className='Account-channel-name alg-800 uppercase'>{channel.channelName}</p>
                <div className='Account-profile-container'>
                    <img className='Account-profile-image' src={channel.photoURL} alt={`${channel.channelName} - thumbnail image`}/>
                    <div className='Account-name-container'>
                        <p className='Account-name alg-800 uppercase'>{channel.name}</p>
                        <p className='Account-email alg-800 uppercase'>{channel.country}</p>
                        {/*<p className='Account-email mav-800'>{channel.email}</p>
                        <p className='Account-disclaimer mav-800 uppercase'>This info is not publicly visible <LockIcon style={{fontSize:"0.50rem"}}/></p>*/}
                    </div>
                </div>
                <p className='Account-channelDescription mav-800'>{ReactHtmlParser(channel.channelDescription)}</p>
                <p className='Account-trips-label uppercase'>Trips</p>
                
                <div className='Account-trips'>
                { (channel && articles.length > 0) ? articles.map((blog) => (
                    <div key={blog.blogUID} className="flexc m-b-20">
                        <BlogAsSmall blog={blog}/>
                    </div>
                )) : <></>}
                </div>
            </div>
            
            
        </div>
    )
}

export default Account

























/*
            {account.blogList.map((blogUID) => (
                <div key={blogUID}>
                    <Link to={`/blog/${blogUID}`}><h2>{blogUID}</h2></Link>
                </div>
            ))}
 */