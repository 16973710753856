import React from 'react'
import {useState, useEffect, useContext} from 'react';
import firebase from '../../firebase';
import {Link} from 'react-router-dom';
import { AuthContext } from '../Daxil/Auth';
import BlogAsSmall from './BlogAsSmall';
import AddIcon from '@mui/icons-material/Add';
import mountainBanner from '../../Assets/mountainBanner1x.png'
import featureInsta from '../../Assets/featureInsta.png'
import featureInstaDark from '../../Assets/featureInstaDark.png'
import IssueList from '../Issues/IssueList';
import { Helmet } from 'react-helmet';
import '../../Styles/Index.css'
import '../../Styles/Home.css'

function Home() {
  const currentUser = useContext(AuthContext)
  const firebaseAnalytics = firebase.analytics();
useEffect(() => {
    firebaseAnalytics.logEvent("Home_Visited")
    getArticles();
    return () => {
      //to clean up anything you did before
    }
}, []);

  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const ref2 = firebase.firestore().collection("Blogs");

  const getArticles = async () => {
    setLoading(true);
    //test--
    //const queryOrder = query(collection(db, "Blogs"), orderBy("modifiedOn","desc"))
    //
    ref2.orderBy('lastEditTimeStamp','desc').onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().publish != false) {
        items.push(doc.data());
        }
      });
      setArticles(items);
      setLoading(false);
    });
  }

  const [covers, setCovers] = useState(IssueList)

  
  if (loading) {
    return <h1 key="loader">Loading your next adventure...</h1>
  }

  //{currentUser.currentUser ? <h2>Welcome {currentUser.currentUser.displayName}!</h2> : <h2>Welcome!</h2>} 
    return (
        <div>
          <Helmet>
          <title>Adventure Chase Trips</title>
          <meta name='description' content='Adventure Chase is an online travel magazine platform which allows travelers and explorers to open their own travel blog based magazine. Travel Journals home page.'/>
          <meta name="theme-color" content="#164734" />
          <link rel='canonical' href='/'/>
          </Helmet>

            <div className='Home-theme-design-container'>
              <img className='Home-theme-design-image' src={mountainBanner} alt="mountain background - adventure chase"/>
            </div>
            

            <div className='Home-main'>
              <div className='Home-sidebar-container'>
                <p className="Home-sidebar-title mav-800 uppercase">Post your next adventure here</p>
                <Link className="Home-sidebar-link" to="/contactus">
                  <button className='Home-sidebar-button uppercase' >Open Channel <AddIcon style={{fontSize: "1rem", fontWeight:"800"}}/></button>
                </Link>
                <a className='Home-sidebar-feature' href='https://www.instagram.com/adventure__chase/' target="_blank" onClick={() => {firebaseAnalytics.logEvent("featureAd_Clicked")}}>
                  <img className='Home-sidebar-feature-img' src={featureInsta} alt="@adventure__chase instagram"/>
                </a>
              </div>
              <div className='Home-content-container'>
              <h2 className='Home-trips-label Home-trips-label-1 m-t-0'>covers</h2>
            <div className='Home-covers'>
              {
                covers.map((cover, index) => (
                  <Link className="Home-cover-container" to={`/issue/${cover.id}`} key={index}>
                    <div className='Home-cover'>
                      <img className='Home-cover-img' src={cover.cover} key={index} alt={`cover ${cover.date}`}></img>
                    </div>
                    <p className='Home-cover-label mav-800 uppercase'>{cover.date}</p>
                  </Link>
                ))
              }
            </div>
                <h1 className='Home-trips-label'>Adventure chase trips</h1>
                <div className='Home-content-container-trips'>
                {articles.map((blog) => (
                    <div key={blog.blogUID} className="flexc m-b-20">
                        <BlogAsSmall blog={blog}/>
                    </div>
                ))}
                </div>
                
              </div>
            </div>
            
            
            
        </div>
    )
}
//<Link className="BlogShowCase-blog-container r-dec" to={`/blog/${props.blog.blogUID}`}>
export default Home









/*

<div className='Home-BlogAsThumbnail-container'>
              {articles.map((blog) => (
                  <Link key={blog.blogUID} className=" BlogAsThumbnail flexc " to={`/blog/${blog.blogUID}`}>
                      <BlogAsThumbnail blog={blog}/>
                  </Link>
              ))}
            </div>

            
<Link to={`/blog/${blog.blogUID}`}><h2>{blog.blogUID}</h2></Link>
                    <Link to={`/channel/${blog.channelUID}`}><p>{blog.channelUID}</p></Link>



EXAMPLE CODE



  function getArticles() {
    setLoading(true);
    ref2.onSnapshot((querySnapshot) => {
      const items = [];
      querySnapshot.forEach((doc) => {
        items.push(doc.data());
      });
      setArticles(items);
      setLoading(false);
    });
    // or u can use - 1 time data download compared to real-time
        ref.get().then((item) => {
        const items = item.docs.map((doc) => doc.data());
        setSchools(items);
        setLoading(false);
        })
    }
*/






