import React from 'react'
import { googleProvider } from './authMethods'
import socialMediaAuth from './socialMediaAuth'
import {useState, useContext, useEffect} from 'react';
import { AuthContext } from './Auth';
import firebase from '../../firebase';
import '../../Styles/Index.css';
import '../../Styles/Daxil.css';
import {SiGmail} from 'react-icons/si';
import tigerInJungle from '../../Assets/tigerInJungle.png';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Helmet } from 'react-helmet';
//import { Redirect } from 'react-router';

function SignIn() {

    const [userData,setUsedData] = useState("");

    const currentUser = useContext(AuthContext)
    const ref = firebase.firestore().collection("Users");
    const ref2 = firebase.firestore().collection("Channels");
    const firebaseAnalytics = firebase.analytics();
    
    //console.log(currentUser)
     //---------------------------------
      // MARK - ALERT
      //---------------------------------
      const [open, setOpen] = useState(false);
      const [openError, setOpenError] = useState(false);
      const [problem, setProblem] = useState("");
      const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };
      const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenError(false);
      };

      useEffect(() => {
        firebaseAnalytics.logEvent('SignIn_Visited');
        return () => {
            //to clean up anything you did before
        }
    }, []);

    const handleGoogleSignIn = async (provider) => {
      firebaseAnalytics.logEvent('SignIn_Attempted_Google');
       const res = await socialMediaAuth(provider);
       setUsedData(res);
       
       const user = ref.doc(`${res.displayName.replace(/ /g,'-')}-${res.uid}`)
       const timeStamp = Date(Date.now()).toString()
       user.get().then(docSnapshot => {
        if (docSnapshot.exists) {
          // do something
          //console.log("Updating Signed In User")
            user.update({
                photoURL: res.photoURL,
                lastSignIn: timeStamp
            }).catch(error => {
                //console.log(error.message)
              })
        } else {
            //console.log("Creating Signed In User")
            user.set({ 
                name:res.displayName, 
                userUID: `${res.displayName.replace(/ /g,'-')}-${res.uid}`,
                channelUID: `Channel-${res.uid}`, 
                photoURL: res.photoURL,
                email: res.email,
                joinedOn: timeStamp,
                lastSignIn: timeStamp
            }).then((
                ref2.doc(`Channel-${res.uid}`).set({
                    //----------------------------------------
                      channelUID: `Channel-${res.uid}`,
                      channelName: res.displayName,
                      name: res.displayName,
                      email: res.email,
                      country: "Earth",
                      channelDescription: "Hey Adventure Chasers!",
                      createdOn: timeStamp,
                      modifiedOn: timeStamp,
                      photoURL: res.photoURL,
                    //----------------------------------------
                    }).catch(error => {
                      //console.log(error.message)
                    })
            )).then((setOpen(true))).catch(error => {
              setOpenError(true)
              setProblem(`${error.message}`)
            });
        }
      });
       
       
       
    }

    const handleSignOut  = () => {
      firebaseAnalytics.logEvent('SignOut_Done');
        firebase.auth().signOut().then(function() {
            // Sign-out successful.

            alert("Sign Outed")
            setOpen(true)
            window.location.reload(false);
          }).catch(function(error) {
            setOpenError(true)
            setProblem(`${error.message}`)
          });
    }
    //console.log(currentUser.currentUser)
    return (
      
        <div className='SignIn-container'>
            <Helmet>
                <title>Sign In - Adventure Chase </title>
                <meta name='description' content="If you love exploring, create your own Adventure Chase Travel Magazine blog." />
                <link rel='canonical' href={`/signin`}/>
            </Helmet>
            
            <div className='SignIn-sidebar-container'>
              <p className='SignIn-sidebar-label mav-800 uppercase'>Sign In</p>
              <p className='SignIn-sidebar-label2 mav-800 uppercase'>Post your next adventure here</p>

              {currentUser.currentUser ? 
                <div className='SignIn-sidebar-loggedIn-container'>
                  
                  <img className='SignIn-sidebar-profile-image' referrerpolicy="no-referrer" src={currentUser.currentUser.photoURL}/>
                  <p className='SignIn-sidebar-profile-name'>{currentUser.currentUser.displayName}</p>
                  <div className='SignIn-sidebar-button-div'>
                    <button className='SignIn-sidebar-logout-button mav-800 uppercase' onClick={handleSignOut}>Sign Out</button>
                  </div>
                </div>
                : <div className='SignIn-sidebar-loggedOut-container'>
                    <div className='SignIn-sidebar-button-div'>
                      <button className='SignIn-sidebar-login-button mav-800 uppercase' onClick={() => handleGoogleSignIn(googleProvider)}>Sign In with Gmail &nbsp;  <SiGmail style={{color:"#ff0000",padding:'0px'}}/></button>
                      <p className='SignIn-motivation-label mav-800'>Stop Dreaming, Start Chasing</p>
                    </div>
                    
                </div>
              } 

            </div>
            <div className='SignIn-background-image-container'>
              <img className='SignIn-background-image' alt='tiger in jungle' src={tigerInJungle}/>
            </div>
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} >
              <Alert onClose={handleClose} severity="success" sx={{ width: '100%' ,backgroundColor:"#164734", color:'#ffb71b'}}>
                Done!
              </Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={6000} onClose={handleCloseError}>
              <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
                Error! {problem}
              </Alert>
            </Snackbar>
        </div>
    )
}

export default SignIn


/*

{currentUser.currentUser ? 
                <div>
                <h3>{currentUser.currentUser.displayName}</h3>
                <p>{currentUser.currentUser.email}</p>
                <p>{currentUser.currentUser.uid}</p>
                <button onClick={handleSignOut}>Sign Out</button>
                </div>
            : <div>
                <h3>Not signed in</h3>
                <button onClick={() => handleGoogleSignIn(googleProvider)}>Sign In with Google</button>
              </div>
}  

*/
