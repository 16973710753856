import React from 'react'
import IssueList from './IssueList'
import firebase from '../../firebase';
import {useState, useEffect, useContext} from 'react';
import '../../Styles/Index.css'
import '../../Styles/DetailedIssues.css'
import {Link} from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import mountainBanner from '../../Assets/mountainBanner1x.png'
import { Helmet } from 'react-helmet';
import { IosShareRounded } from '@mui/icons-material';
function DetailedIssues({match}) {
    //match.params.id
    const [issue, setIssue] = useState({});
    const [articles, setArticles] = useState([]);
    const firebaseAnalytics = firebase.analytics();
    useEffect(() => {
        
        IssueList.map((issue) => {
            if (issue.id === match.params.id) {
                setIssue(issue)
                setArticles(issue.articles)
                firebaseAnalytics.logEvent(`${issue.id}_Issue_Viewed`)
            }
        })
        return () => {
            //to clean up anything you did before
        }
    }, [match]);
  return (
    <div className='DetailedIssues'>
        <Helmet>
          <meta name="theme-color" content="#164734" />

          <title>{issue.date ? `${issue.date} -` : ""} Adventure Chase Issue</title>
            <meta name='description' content={issue.desc ? `${ReactHtmlParser(issue.desc.slice(0,170))}`: "Adventure Chase Travel Cover" }/>
            <link rel='canonical' href={`/issue/${issue.id}`}/>
            
            <meta property="og:url" content={`https://www.adventure-chase.com/issue/${issue.id}`}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content="Adventure Chase Cover"/>
            <meta property="og:description" content="Adventure Chase is an online travel magazine platform which allows travelers and explorers to open their own travel blog."/>
            <meta property="og:image" content={issue.photo ? issue.photo : "https://www.adventure-chase.com/acShareImage.png"}/>

        
            <meta name="twitter:card" content="summary_large_image"/>
            <meta property="twitter:domain" content="adventure-chase.com"/>
            <meta property="twitter:url" content={`https://www.adventure-chase.com/issue/${issue.id}`}/>
            <meta name="twitter:title" content="Adventure Chase Cover"/>
            <meta name="twitter:description" content="Adventure Chase is an online travel magazine platform which allows travelers and explorers to open their own travel blog."/>
            <meta name="twitter:image" content={issue.photo ? issue.photo : "https://www.adventure-chase.com/acShareImage.png"}/>
        </Helmet>
        <div className='DetailedIssues-theme-design-container'>
            {issue.theme ? <img className='DetailedIssues-theme-design-image' src={issue.theme} alt="mountain background - adventure chase"/> : <img className='DetailedIssues-theme-design-image' src={mountainBanner} alt="mountain background - adventure chase"/>}
        </div>
        <div className='DetailedIssues-main'>
        <div className='DetailedIssues-left'>
            <div className='DetailedIssues-cover'>
                <img className='DetailedIssues-cover-img' alt="cover" src={issue.cover}/>
            </div>
            
        </div>
        <div className='DetailedIssues-right'>
            <p className='DetailedIssues-date mav-800 uppercase'>{issue.date}</p>
            <Link className='DetailedIssues-mag mav-800 uppercase' to={`/${issue.magLink}`}>{issue.mag}</Link>
            <div className='DetailedIssues-navButtons'>
                {issue.previous ?  <Link className="DetailedIssues-navButtons-back mav-800 uppercase" to={`/issue/${issue.previous}`} onClick={() => {firebaseAnalytics.logEvent("Previous_Issue_Viewed")}}>Previous</Link> : <></>}
                {issue.next ?  <Link className="DetailedIssues-navButtons-next mav-800 uppercase" to={`/issue/${issue.next}`} onClick={() => {firebaseAnalytics.logEvent("Next_Issue_Viewed")}}>Next</Link> : <></>}
            </div>
            <div className="DetailedIssues-articles">
            {

                articles.map((article, index) => (
                    <Link key={index} className="DetailedIssues-article" to={`/${article.link}`}>
                        <img className='DetailedIssues-article-img' alt="Image" src={article.photo}/>
                    </Link>
                ))
            }
            </div>
            <p className='DetailedIssues-desc-header mav-800 uppercase'>THIS MONTH'S ISSUE</p>
            <p className='DetailedIssues-desc font-para-300'>{ReactHtmlParser(issue.desc)}</p>
        </div>
        </div>
        <a className='DetailedIssues-special-container' href='#'>
            { issue.special ? <img className='DetailedIssues-special-img' src={issue.special}/> : <></>}
        </a>
        
    </div>
  )
}

export default DetailedIssues