

import acShareImage from '../../Assets/acShareImage.png'
//Oct 2022
import coverOct22 from '../../Assets/Oct2022/coverOct22.jpg' 
import tawangCover from '../../Assets/Oct2022/tawang.jpg' 
import bumlaCover from '../../Assets/Oct2022/bumla.jpg' 
import cricketCover from '../../Assets/Oct2022/cricket.jpg' 
import dirangCover from '../../Assets/Oct2022/dirang.jpg' 

//Nov 2022
import coverNov22 from '../../Assets/Nov2022/coverNov22.jpg'
import coverNov22_2 from '../../Assets/Nov2022/coverNov22_2.jpg'
import safariTheme from '../../Assets/safariTheme.png'  

//Dec 2022
import coverDec22 from '../../Assets/Dec2022/coverDec22.png'
import seaTheme from '../../Assets/seaTheme.png'  

//Jan 2023
import coverJan23 from '../../Assets/Jan2023/coverJan23.png'
const IssueList = [
    
        {   
            id:"oct2022",
            next:"nov2022",
            date:"october 2022", 
            cover:coverOct22,
            mag:"adventure chase",
            magLink:"account/Channel-Zi2OEAhF3rY1KgPnjXdzGsZ1JM02",
            articles:[{photo:tawangCover,link:"blog/Blog-M2iOK8D6Y3VW52QJTflLsBGaoN23-76695dcb"},{photo:dirangCover,link:"blog/Blog-M2iOK8D6Y3VW52QJTflLsBGaoN23-76695dcb/Chapter-1665860949709"},{photo:bumlaCover,link:"blog/Blog-M2iOK8D6Y3VW52QJTflLsBGaoN23-76695dcb/Chapter-1665863109775"},{photo:cricketCover,link:"blog/Blog-7nPf1VZFZNcKeN1qYnjR6eqSxU33-0cfd6df6"}],
            desc:"For our first cover, we follow MD Danish of Planet Explorer through his journey to far east India - Tawang. The journey to Tawang is rough and requires some knowledge of permits which would be needed when you head into Arunachal Pradesh. Danish gives a very detailed account of his experience, the kind of people he met, the food he had and the permits he took. Some chapters to look out for are the ones where he went to Dirang and then later, to Bumla Pass and Sungestar lake. <br> <br> Hartaj Singh, the founder of Adventure Chase, went to watch the IPL match between Chennai Super Kings and Sunrisers Hyderabad with his sister and his friend. Hartaj explains why you should, at least once, experience a cricket match. He also describes the spectator’s experience of the match. "
        },
        {
            id:"nov2022",
            previous:"oct2022",
            next:"dec2022",
            date:"november 2022",
            theme:safariTheme,
            cover:coverNov22,
            special:"https://firebasestorage.googleapis.com/v0/b/travelite-7ce27.appspot.com/o/Channel-Zi2OEAhF3rY1KgPnjXdzGsZ1JM02%2FBlog-Zi2OEAhF3rY1KgPnjXdzGsZ1JM02-e15a37a7%2FChapter-1668347414185%2F1-1668493596805-0.1159039864134137?alt=media&token=1676a3bd-14a6-4063-869a-27a8a9c8c4be",
            mag:"adventure chase",
            magLink:"account/Channel-Zi2OEAhF3rY1KgPnjXdzGsZ1JM02",
            articles:[{photo:coverNov22,link:"blog/Blog-Zi2OEAhF3rY1KgPnjXdzGsZ1JM02-e15a37a7"},{photo:coverNov22_2,link:"blog/Blog-Zi2OEAhF3rY1KgPnjXdzGsZ1JM02-e15a37a7/Chapter-1668347414185"}],
            desc:"For the November 2022 issue, Samyak Jain wrote about his trip to the Tadoba-Andhari National Park for the famous Tiger Safari. He talks about his stay at the Waghoba Eco Lodge, booked through Pugdundee Safaris. After going on 3 safaris, he was finally able to spot tigers, along with other wildlife such as the Sambar Deer, Spotted dears, wild boars and the Indian Gaur. There are also plenty of Langurs and Peacocks in the national park. The cost of the whole trip came out to be about ₹33800 per person. <br><br> More articles for this issue are on its way. "
        },
        {
            id:"dec2022",
            previous:"nov2022",
            next:"jan2023",
            date:"december 2022",
            theme:seaTheme,
            cover:coverDec22,
            mag:"adventure chase",
            magLink:"/account/Channel-7nPf1VZFZNcKeN1qYnjR6eqSxU33",
            articles:[{photo:coverDec22,link:"blog/Blog-7nPf1VZFZNcKeN1qYnjR6eqSxU33-a176f891"}],
            desc:"For the December 2022 issue, Hartaj Singh wrote about his trip to the Elephanta Caves, Mumbai for which cost him less than ₹1000. He talks about his day trip journey, and explains the history behind the caves. His trip involved five different modes of transportation and a steep hike to caves located on a secluded tropical island."
        },
        {
            id:"jan2023",
            previous:"dec2022",
            date:"january 2022",
            theme:seaTheme,
            cover:coverJan23,
            mag:"adventure chase",
            magLink:"/account/Channel-Zi2OEAhF3rY1KgPnjXdzGsZ1JM02",
            articles:[{photo:coverJan23,link:"blog/Blog-Zi2OEAhF3rY1KgPnjXdzGsZ1JM02-f366ce36"}],
            desc:"For the January 2023 issue, Abhishek Lilani wrote about his spontaneous trip to Goa to surprise his friends. Abhishek had to finish all his work on time if he had to catch his flight on time. The New Year's eve was spent drinking and partying in North Goa - a perfect way to unwind before heading back to Mumbai for his early morning meetings. Will he reach Mumbai on time? And at what cost?"
        }
    
    
    
]

export default IssueList
