import React from 'react'
import {useState, useEffect, useContext} from 'react';
import '../../Styles/Index.css'
import '../../Styles/Blog.css'
import { getHTMLTextFromNormalText } from '../Tools/getHtmlTextFromNormalText';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

function Type1(props) {

    const block = props.block
    const para = ReactHtmlParser(block.text)

    return (
        <>
        { block.highlighted === "yes" ?
            <div className='type1-highlighted'>
                <p className='alg-800 font-para-700 type1-title'>{block.title}</p>
                <p className={`${block.col} font-para-300 para`}>{para}</p>
            </div>
        :
            <div className='type1'>
                <p className='alg-800 font-para-700 type1-title'>{block.title}</p>
                <p className={`${block.col} font-para-300 para`}>{para}</p>
            </div>
        }
        </>
    )
}

Type1.defaultProps = {
    title:"",
    text:"",
}

export default Type1
